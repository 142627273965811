
import { UserModel, UserModule, UserSocial } from "@/store/modules/user";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  name: "OnlinePresence",
})
export default class extends Vue {
  @Prop({ required: true }) public item!: UserModel;
  @Prop({ required: true }) public isAdmin!: boolean;
  isScrapping = false;
  scrapped = false;
  showError = false;
  confirmedEnabled = false;
  currentState: any = {};

  mounted() {
    this.setCurrentState();
    this.filterSocials();
  }

  beforeUnmount() {
    this.item.socials.forEach((val) => {
      val.link = this.currentState[val.type];
    });
  }

  filterSocials() {
    if (this.item.roles.some((role) => role.name === "promoter")) {
      this.item.socials = this.item.socials.filter((social) =>
        [
          "Website",
          "Facebook",
          "Instagram",
          "Resident advisor",
          "Twitter",
        ].includes(social.type)
      );
    } else {
      this.item.socials = this.item.socials.filter(
        (social) => social.type !== "Website"
      );
    }
  }

  selectSocial(item: any, alternative: any = null): void {
    if (!item.selected) {
      this.confirmedEnabled = true;
    } else {
      this.confirmedEnabled = false;
    }
    if (!alternative && !item.selected) {
      item.alternatives.forEach((alternative: any) => {
        alternative.selected = false;
      });
      return;
    }
    if (alternative && !alternative!.selected) {
      item.alternatives.forEach((alternative: any) => {
        alternative.selected = false;
      });
      item.selected = false;
      alternative.selected = true;
      this.confirmedEnabled = true;
    }
  }

  checkInputs(): void {
    for (let index = 0; index < this.item.socials.length; index++) {
      const element = this.item.socials[index];
      this.confirmedEnabled = false;
      if (this.currentState[element.type] !== element.link) {
        this.confirmedEnabled = true;
        break;
      }
    }
  }

  async confirmScrapSelection(item: UserModel) {
    item.socials.forEach((social: any) => {
      const alternative = social.alternatives?.find(
        (alternative: any) => alternative.selected
      );
      if (alternative) {
        social.link = alternative.link;
      }
      if (this.scrapped && !alternative && !social.selected) {
        social.link = null;
      }
    });
    this.scrapped = false;
    await UserModule.updateUserSocials(item);
    this.setCurrentState();
  }

  private setCurrentState(): void {
    this.item.socials.forEach((val) => {
      this.currentState[val.type] = val.link;
    });
  }

  async startScrapping(item: UserModel): Promise<void> {
    if (!item.primeArtistName) {
      this.showError = true;
      return;
    }
    this.showError = false;
    this.isScrapping = !this.isScrapping;
    const scrappedData: any = await UserModule.scrapSocials(item);
    item.socials.forEach((social: any, index: number) => {
      social.alternatives = [];
      scrappedData.data[index].forEach((val: any) => {
        social.alternatives.push({
          selected: false,
          type: social.type,
          link: val.link,
        });
      });
      social.selected = !!social.link;
      if (!social.link && scrappedData.data[index][0]) {
        social.link = scrappedData.data[index][0].link;
        social.alternatives.splice(0, 1);
      }
    });
    this.isScrapping = false;
    this.scrapped = true;
  }
}
