
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { IPromoter } from "@/components/playlist/table/index.vue";

@Options({
  name: "MultiPromoterEditModal",
  directives: {
    focus: {
      mounted(el: HTMLElement) {
        el.focus();
      },
    },
  },
})
export default class PlaylistTable extends Vue {
  @Prop({ required: true, default: false }) private show!: boolean;
  @Prop({ required: true }) private playlist!: {
    promotionCompanies: IPromoter[];
  };

  isSubmitting = false;

  form = {
    promotionCompanies: this.playlist.promotionCompanies.length
      ? this.playlist.promotionCompanies.map((promoter: IPromoter) => ({
          id: promoter.id,
          name: promoter.name,
        }))
      : [{ id: null, name: "" }],
  };

  addPromoterField() {
    this.form.promotionCompanies.push({ id: null, name: "" });
  }

  handleSubmit() {
    this.$emit("onSubmit", this.form.promotionCompanies);
    this.isSubmitting = true;
  }

  closeModal(evt: Event): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.$emit("onClose");
  }
}
