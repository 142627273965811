<template>
  <div class="group">
    <ConfirmModal
      title="Delete member"
      description="Are you sure you want to delete this member?"
      successButtonText="Delete"
      :isOpen="showDeleteConfirmationModal"
      @onSuccess="handleDeleteMembership"
      @onClose="handleCloseDeleteConfirmationModal"
    />
    <AddMemberModal
      :show="showAddMemberModal"
      @onClose="handleCloseAddMemberModal"
      @onSubmit="handleAddMember"
    />
    <InviteMemberModal
      :show="Boolean(inviteMember)"
      :memberName="inviteMember ? inviteMember.name : ''"
      @onClose="handleCloseInviteMemberModal"
      @onSubmit="handleInviteMember"
    />
    <div class="group__header">
      <h1>Group info</h1>
    </div>
    <div class="group__divider" />
    <div class="group__info">
      <div>
        <div class="group__title">
          <span
            >Group name ({{
              group && group.discogsId ? "discogs" : "manual"
            }})</span
          >
          <h2>{{ group ? group.name : "" }}</h2>
        </div>
        <div class="group__id">
          <h3><span>ID:</span> {{ group ? group.asliceId : "" }}</h3>
        </div>
      </div>
      <div class="statuses">
        <span class="statuses__title">Status Key:</span>
        <div class="statuses__list">
          <div class="statuses__list__item statuses__list__item_yellow">
            Pending
          </div>
          <div class="statuses__list__item statuses__list__item_green">
            Approved
          </div>
          <div class="statuses__list__item statuses__list__item_red">
            Unreachable
          </div>
        </div>
      </div>
    </div>
    <div v-if="group" class="group__table">
      <div class="group__table__header row">
        <div class="col-1">Acct Status</div>
        <div class="col-3">artist name / alias</div>
        <div class="col-3">Email</div>
        <div class="col-3">GROUP Membership STATUS</div>
        <div class="col-2" />
      </div>
      <div class="group__table__body">
        <div
          v-for="membership in group.groupMemberships"
          :key="membership.id"
          class="row"
        >
          <div class="col-1">
            <div
              :class="[
                'group__table__status',
                getMembershipClass(membership.status),
              ]"
            />
          </div>
          <div class="col-3">{{ membership.member.name }}</div>
          <div class="col-3">
            <div v-if="getMemberEmail(membership)">
              {{ getMemberEmail(membership) }}
            </div>
            <button
              v-else-if="!membership.invited && !membership.user"
              class="button-primary-outline"
              @click="handleInviteButtonClick(membership.member)"
            >
              invite
            </button>
          </div>
          <div class="col-3">
            <div v-if="membership.isAdmin" class="group__table__admin-label">
              Group Admin
            </div>
          </div>
          <div class="col-2 group__table__actions-column">
            <button
              class="button-primary-outline"
              @click="handleDeleteButtonClick(membership.id)"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="group__add-member" @click="handleOpenAddMemberModal">
      <img src="@/assets/plus-circle-icon.svg" alt="" />
      <span>Add Member</span>
    </div>
    <button
      v-if="group && group.status === 'pending'"
      class="primary-button"
      @click="handleSetApprovedStatus"
    >
      Approve
    </button>
  </div>
</template>

<script>
import swal from "sweetalert2";
import AddMemberModal from "./add-member-modal.vue";
import { GroupsModule } from "@/store/modules/groups";
import InviteMemberModal from "./invite-member-modal.vue";
import ConfirmModal from "@/components/confirmPopup/index.vue";

const STATUS_CLASSES = {
  pending: "group__table__status_yellow",
  approved: "group__table__status_green",
  unreachable: "group__table__status_red",
};

export default {
  name: "GroupInfo",
  components: {
    ConfirmModal,
    AddMemberModal,
    InviteMemberModal,
  },
  data() {
    return {
      groupId: null,
      membershipId: null,
      inviteMember: null,
      showAddMemberModal: false,
      showDeleteConfirmationModal: false,
    };
  },
  async mounted() {
    const { id } = this.$route.params;
    if (id) {
      swal.showLoading();
      this.groupId = Number(id);
      await GroupsModule.fetchGroup(id);
      swal.close();
    }
  },
  methods: {
    async handleInviteMember({ email }) {
      swal.showLoading();
      await GroupsModule.inviteMemberToGroup({
        id: this.groupId,
        data: {
          email,
          performerId: this.inviteMember.id,
        },
      });
      await GroupsModule.fetchGroup(this.groupId);
      this.handleCloseInviteMemberModal();
      swal.close();
    },
    handleInviteButtonClick(member) {
      this.inviteMember = member;
    },
    handleCloseInviteMemberModal() {
      this.inviteMember = null;
    },
    getMemberEmail(membership) {
      if (membership.member.user) {
        return membership.member.user.email;
      }

      return membership.email || "";
    },
    async handleDeleteMembership() {
      swal.showLoading();
      await GroupsModule.removeMemberFromGroup({
        id: this.groupId,
        membershipId: this.membershipId,
      });
      await GroupsModule.fetchGroup(this.groupId);
      this.handleCloseDeleteConfirmationModal();
      swal.close();
    },
    handleDeleteButtonClick(membershipId) {
      this.membershipId = membershipId;
      this.showDeleteConfirmationModal = true;
    },
    handleCloseDeleteConfirmationModal() {
      this.membershipId = null;
      this.showDeleteConfirmationModal = false;
    },
    async handleAddMember(data) {
      swal.showLoading();
      await GroupsModule.addMemberToGroup({
        data,
        id: this.groupId,
      });
      await GroupsModule.fetchGroup(this.groupId);
      this.handleCloseAddMemberModal();
      swal.close();
    },
    handleOpenAddMemberModal() {
      this.showAddMemberModal = true;
    },
    handleCloseAddMemberModal() {
      this.showAddMemberModal = false;
    },
    getMembershipClass(status) {
      return STATUS_CLASSES[status];
    },
    async handleSetApprovedStatus() {
      swal.showLoading();
      await GroupsModule.changeGroupStatus({
        id: this.groupId,
        status: "approved",
      });
      await GroupsModule.fetchGroup(this.groupId);
      swal.close();
    },
  },
  computed: {
    group() {
      return GroupsModule.group;
    },
  },
};
</script>

<style lang="scss" scoped>
.group {
  min-height: 100vh;
  background: #f2f1ef;
  &__header {
    height: 70px;
    display: flex;
    padding: 0px 23px;
    align-items: center;
    background: #36c9be;
    border-bottom: 1px solid #aaa3b1;

    h1 {
      font-size: 36px;
      font-weight: 500;
      color: #ffffff;
    }
  }
  &__divider {
    height: 30px;
    background: #00978e;
  }
  &__info {
    display: flex;
    align-items: center;
    padding: 20px 54px 20px 23px;
    justify-content: space-between;
  }
  &__title {
    span {
      font-size: 9px;
      color: #aaa3b1;
      font-weight: 500;
      line-height: 10px;
      letter-spacing: 0.15em;
      text-transform: uppercase;
    }
    h2 {
      font-size: 24px;
      font-weight: 400;
      color: #36c9be;
      margin-bottom: 4px;
    }
  }
  &__id {
    h3 {
      font-size: 9px;
      font-weight: 500;
      color: #31bcb2;
      text-transform: uppercase;

      span {
        color: #aaa3b1;
      }
    }
  }
  &__table {
    margin: 0 9px;

    &__header {
      height: 30px;
      font-size: 9px;
      color: #ffffff;
      font-weight: 500;
      align-items: center;
      background: #00978e;
      letter-spacing: 0.15em;
      text-transform: uppercase;
    }

    &__body {
      .row {
        height: 40px;
        font-size: 12px;
        align-items: center;
        border-bottom: 1px solid #75707b;
      }
    }

    &__status {
      width: 10px;
      height: 10px;
      margin-left: 29px;
      border-radius: 50%;

      &_yellow {
        background: #fcb416;
      }

      &_red {
        background: #e32d26;
      }

      &_green {
        background: #29c9be;
      }
    }

    &__admin-label {
      width: 84px;
      height: 24px;
      display: flex;
      font-size: 10px;
      color: #75707b;
      background: #fff;
      border-radius: 2px;
      align-items: center;
      margin-left: 30px;
      justify-content: center;
    }
    &__actions-column {
      text-align: right;
      padding-right: 30px;
    }
  }
  &__add-member {
    height: 40px;
    display: flex;
    padding: 0 20px;
    cursor: pointer;
    background: #fff;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    span {
      font-size: 12px;
    }
  }
  .primary-button {
    margin-top: 32px;
    margin-left: 44px;
  }
  .button-primary-outline {
    height: 28px;
  }
}
</style>
