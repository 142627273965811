import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from "vuex-module-decorators";
import swal from "sweetalert2";
import store from "@/store";
import {
  fetchTrackRequest,
  fetchTracksRequest,
  updateTrackRequest,
} from "@/api/tracks";
import {
  fetchUser,
  getUserChangeLogRequest,
  fetchUserStripeData,
} from "@/api/user";

import { updatePerformerRequest } from "@/api/playlist";

export interface ITracksState {
  list: any[];
  page: number;
  pages: number;
  performerChangeLog: any[];
  stripeData: any;
  user: any;
  track: any;
}

@Module({ dynamic: true, store, name: "tracks", namespaced: true })
class Tracks extends VuexModule implements ITracksState {
  public list: any[] = [];
  public page = 1;
  public pages = 0;
  public stripeData = null;
  public performerChangeLog: any[] = [];
  public user = null;
  public track: any = null;

  @Mutation
  private SET_LIST(list: any) {
    this.list = list;
  }

  @Mutation
  private SET_TRACK(track: any) {
    this.track = track;
  }

  @Mutation
  private SET_PAGES(pages: number) {
    this.pages = pages;
  }

  @Mutation
  private SET_CHANGE_LOG(log: any[]) {
    this.performerChangeLog = log;
  }

  @Mutation
  private SET_STRIPE_DATA(data: any) {
    this.stripeData = data;
  }

  @Mutation
  private SET_USER(data: any) {
    this.user = data;
  }

  @Mutation
  private UPDATED_TRACK(data: any) {
    const trackIndex = this.list.findIndex(({ id }) => id === data.id);
    if (trackIndex + 1) {
      (this.list[trackIndex] as any) = data;
    }
  }

  @Mutation
  private UPDATE_ARTIST_IN_TRACK({
    trackId,
    data,
  }: {
    trackId: number;
    data: any;
  }) {
    const { id, name, url, type } = data;

    this.list = this.list.map((item) => {
      if (item.id === trackId) {
        return {
          ...item,
          [type]: item[type].map((artist: any) =>
            artist.id === id ? { ...artist, name, url } : artist
          ),
        };
      }

      return item;
    });
  }

  @Action
  public async fetchTracks({
    page,
    limit,
    query,
    withoutHistory,
  }: {
    page: number;
    limit: number;
    query: string | null;
    withoutHistory: boolean | null;
  }) {
    try {
      swal.showLoading();
      const { data } = await fetchTracksRequest({
        page,
        take: limit,
        query,
        withoutHistory,
      });
      if (data) {
        this.SET_PAGES(Math.ceil(data.count / limit));
        this.SET_LIST(data.results);
      }
      return data;
    } catch (e) {
      console.log(e);
    } finally {
      swal.close();
    }
  }

  @Action
  public async getUserChangeLog(payload: any) {
    try {
      const { data } = await getUserChangeLogRequest(payload);
      this.SET_CHANGE_LOG(data);
    } catch (e) {
      console.log(e);
    }
  }
  @Action
  public async getUserStripeData(userId: number) {
    try {
      const { data } = await fetchUserStripeData(userId);
      this.SET_STRIPE_DATA(data);
    } catch (e) {
      console.log(e);
    }
  }
  @Action
  public async getUser(userId: number) {
    try {
      const { data } = await fetchUser(userId);
      this.SET_USER(data);
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  public async updateTrackData({
    id,
    name,
    link,
    versionId,
  }: {
    id: number;
    name: string;
    link: string;
    versionId: string;
  }) {
    try {
      const { data } = await updateTrackRequest(id, { name, link, versionId });
      this.UPDATED_TRACK(data);
      return data;
    } catch (e: any) {
      swal.fire({
        icon: "error",
        title: e?.error,
        text: e?.message,
      });
    }
  }

  @Action
  public async updatePerformer({
    id,
    name,
    url,
    type,
    trackId,
  }: {
    id: number;
    name: string;
    url: string;
    type: string;
    trackId: number;
  }) {
    try {
      await updatePerformerRequest(id, { name, url });
      this.UPDATE_ARTIST_IN_TRACK({
        trackId,
        data: { id, name, url, type },
      });
    } catch (e: any) {
      swal.fire({
        icon: "error",
        title: e?.error,
        text: e?.message,
      });
      return;
    }
  }

  @Action
  public async fetchTrack({
    trackId,
    params,
  }: {
    trackId: number;
    params: any;
  }) {
    try {
      const response = await fetchTrackRequest(trackId, params);
      if (response && response.data) {
        this.SET_TRACK(response.data);
      }
      return response;
    } catch (e: any) {
      console.error(e);
      return;
    }
  }

  @Action
  public async setTrack(track: any) {
    this.SET_TRACK(track);
  }
}

export const TracksModule = getModule(Tracks);
