
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import get from "lodash/get";

@Options({
  name: "OnlinePresenceTab",
})
export default class ProfileTab extends Vue {
  @Prop({ required: true }) private user!: any;

  data() {
    return {
      get,
    };
  }

  getSocialLink(type: string): string {
    const social = this.user.socials.find((item: any) => item.type === type);
    return social ? social.link : "";
  }
}
