
import { Options, Vue } from "vue-class-component";
import swal from "sweetalert2";
import get from "lodash/get";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import { sendUserEmail } from "@/api/user";
import { TracksModule } from "@/store/modules/tracks";
import EmailModal from "@/components/send-email-modal/index.vue";
import AsyncFilter from "@/components/async-filter/index.vue";
import UserInfo from "./userInfo.vue";
import ArtistModal from "@/components/playlist/artistModal/index.vue";
import { TrackModel } from "@/store/modules/playlist";

interface Artist {
  id: number;
  name: string;
  url: string | null;
  user: any;
  trackId: number;
  type: string;
}

@Options({
  name: "Tracks",
  components: {
    UserInfo,
    EmailModal,
    VPagination,
    AsyncFilter,
    ArtistModal,
  },
})
export default class Tracks extends Vue {
  page = 1;
  take = 15;
  filter = "";
  takeArr = [15, 30, 50];
  modalIsOpen = false;
  pageToGO: number = this.page;
  currentRowId: number | null = null;
  currentEditRow: null | {
    id: number;
    link: string;
    name: string;
    versionId: string;
  } = null;
  isArtistModalOpen = false;
  currentArtist: Artist | null = null;

  data() {
    return {
      get,
    };
  }

  handleInputFocus(event: MouseEvent) {
    event.stopPropagation();
  }

  handleOpenPlaylistPage(id: number) {
    this.$router.push({ name: "Playlist", query: { trackId: id } });
  }

  isRegistered(track: TrackModel) {
    const [performer] = track.performers;
    if (performer) {
      return (
        (performer.isGroup && performer.groupMemberships.length) ||
        performer.user
      );
    }

    return false;
  }

  handleUpdateArtist(data: { name: string; url: string }) {
    if (this.currentArtist) {
      TracksModule.updatePerformer({
        id: this.currentArtist.id,
        trackId: this.currentArtist.trackId,
        type: this.currentArtist.type,
        ...data,
      });
    }
    this.handleCloseArtistModal();
  }

  handleOpenEditArtistPopup(event: Event, artist: Artist) {
    event.stopPropagation();
    this.isArtistModalOpen = true;
    this.currentArtist = artist;
  }

  handleCloseArtistModal() {
    this.isArtistModalOpen = false;
    this.currentArtist = null;
  }

  handleOpenArtistLink(event: MouseEvent, url: string) {
    event.stopPropagation();
    window.open(url, "_blank");
  }

  handleModalClose() {
    this.modalIsOpen = false;
  }

  async handleSubmitEmail({
    email,
    subject,
    message,
  }: {
    email: string;
    subject: string;
    message: string;
  }) {
    try {
      swal.showLoading();
      const request = {
        subject,
        to: email,
        html: message,
      };
      const response = await sendUserEmail(request);
      if (response && response.data) {
        swal.fire({
          icon: "success",
          text: response.data.message,
        });
        this.handleModalClose();
      }
    } catch (e) {
      swal.close();
    }
  }

  onInviteClick() {
    this.modalIsOpen = true;
  }

  updateHandler(): void {
    TracksModule.fetchTracks(this.queryParams);
  }
  created(): void {
    TracksModule.fetchTracks(this.queryParams);
  }

  async handleFilterChange(filter: string) {
    this.page = 1;
    this.filter = filter;
    TracksModule.fetchTracks(this.queryParams);
  }

  changeTake(count: number): void {
    this.page = 1;
    this.take = count;
    TracksModule.fetchTracks(this.queryParams);
  }

  checkMaxPage(): void {
    if (this.pageToGO && this.pageToGO > this.pages) {
      this.pageToGO = this.pages;
    }
  }

  goToPage(): void {
    this.page = Number(this.pageToGO);
    this.updateHandler();
  }

  async handleExpandRow(event: MouseEvent, item: any) {
    event.stopPropagation();
    this.currentEditRow = null;
    this.currentRowId = this.currentRowId !== item.id ? item.id : null;
    if (this.currentRowId) {
      await TracksModule.getUser(item.performers[0].user.id);
      await TracksModule.getUserChangeLog(item.performers[0].user.id);
      await TracksModule.getUserStripeData(item.performers[0].user.id);
    }
  }

  handleEditRow(event: MouseEvent, item: any) {
    event.stopPropagation();
    this.currentRowId = null;
    if (this.currentEditRow && this.currentEditRow.id === item.id) {
      this.currentEditRow = null;
    } else {
      this.currentEditRow = {
        id: item.id,
        link: item.link,
        name: item.name,
        versionId: item.versionId,
      };
    }
  }

  async handleSaveTrack(event: MouseEvent) {
    event.stopPropagation();
    if (this.currentEditRow) {
      await TracksModule.updateTrackData(this.currentEditRow);
    }
    this.currentEditRow = null;
  }

  get currentUser(): any {
    return TracksModule.user;
  }

  get pages(): number {
    return TracksModule.pages;
  }

  get list(): any[] {
    return TracksModule.list;
  }

  get changelog(): any[] {
    return TracksModule.performerChangeLog;
  }

  get stripeData(): any {
    return TracksModule.stripeData;
  }

  get queryParams(): {
    page: number;
    limit: number;
    withoutHistory: boolean | null;
    query: string | null;
  } {
    return {
      page: this.page,
      limit: this.take,
      withoutHistory: true,
      query: this.filter ? this.filter : null,
    };
  }
}
