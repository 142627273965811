import swal from "sweetalert2";
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from "vuex-module-decorators";
import store from "@/store";
import { fetchPlaylists } from "@/api/playlist";
import { downloadFile } from "@/utils/files";
import {
  getPayoutsListRequest,
  getPayoutsListReportRequest,
  getPaymentsReportRequest,
  getPayoutsListReportArtistRequest,
} from "@/api/reports";

export interface IReportsState {
  list: any[];
}

@Module({ dynamic: true, store, name: "reports", namespaced: true })
class Reports extends VuexModule implements IReportsState {
  public list = [];
  public pages = 0;

  @Mutation
  private SET_LIST(list: any) {
    this.list = list;
  }

  @Mutation
  private SET_PAGES(pages: number) {
    this.pages = pages;
  }

  @Action
  public async getPaymentsReport(params: any) {
    try {
      swal.showLoading();
      const { data } = await fetchPlaylists(params);
      if (data) {
        this.SET_PAGES(Math.ceil(data.count / params.take));
        this.SET_LIST(data.results);
      }
      return data;
    } catch (e) {
      console.log(e);
    } finally {
      swal.close();
    }
  }

  @Action
  public async downloadPaymentsReport({
    fromDate,
    toDate,
    format,
  }: {
    fromDate: string;
    toDate: string;
    format: string;
  }) {
    try {
      const { data } = await getPaymentsReportRequest(fromDate, toDate, format);
      const blob = new Blob([data], { type: data.type });
      downloadFile(blob, `payments-${fromDate}-${toDate}.${format}`);
    } catch (e) {
      swal.fire({
        icon: "error",
        title: "Not Found",
        text: "No data for provided date range",
      });
      return;
    }
  }

  @Action
  public async downloadPaymentsReportArtist({
    fromDate,
    toDate,
    format,
  }: {
    fromDate: string;
    toDate: string;
    format: string;
  }) {
    try {
      const { data } = await getPayoutsListReportArtistRequest(
        fromDate,
        toDate,
        format
      );
      const blob = new Blob([data], { type: data.type });
      downloadFile(
        blob,
        `payouts-summary-report-${fromDate}-${toDate}.${format}`
      );
    } catch (e) {
      swal.fire({
        icon: "error",
        title: "Not Found",
        text: "No data for provided date range",
      });
      return;
    }
  }

  @Action
  public async getPayoutsList(params: any) {
    try {
      swal.showLoading();
      const { data } = await getPayoutsListRequest(params);
      if (data) {
        this.SET_LIST(data);
      }
      swal.close();
      return data;
    } catch (e: any) {
      swal.fire({
        icon: "error",
        title: e?.error,
        text: e?.message,
      });
    } finally {
      swal.close();
    }
  }

  @Action
  public async downloadPayoutsReport({
    fromDate,
    toDate,
    format,
  }: {
    fromDate: string;
    toDate: string;
    format: string;
  }) {
    try {
      const { data } = await getPayoutsListReportRequest(
        fromDate,
        toDate,
        format
      );
      const blob = new Blob([data], { type: data.type });
      downloadFile(blob, `payouts-${fromDate}-${toDate}.${format}`);
    } catch (e: any) {
      console.log(e);
      swal.fire({
        icon: "error",
        title: "Not Found",
        text: "No data for provided date range",
      });
      return;
    }
  }

  @Action
  public async downloadPayoutsReportArtist({
    fromDate,
    toDate,
    format,
  }: {
    fromDate: string;
    toDate: string;
    format: string;
  }) {
    try {
      const { data } = await getPayoutsListReportArtistRequest(
        fromDate,
        toDate,
        format
      );
      const blob = new Blob([data], { type: data.type });
      downloadFile(blob, `payouts-summary-${fromDate}-${toDate}.${format}`);
    } catch (e: any) {
      console.log(e);
      swal.fire({
        icon: "error",
        title: "Not Found",
        text: "No data for provided date range",
      });
      return;
    }
  }

  @Action
  public clearList() {
    this.SET_LIST([]);
  }
}

export const ReportsModule = getModule(Reports);
