
import { Prop } from "vue-property-decorator";
import { Options, Vue } from "vue-class-component";
import ChangeLog from "@/components/changeLog/index.vue";

@Options({
  name: "ChangeLogTab",
  components: {
    ChangeLog,
  },
})
export default class ChangeLogTab extends Vue {
  @Prop({ required: true }) private changelog!: any[];
}
