import { createApp } from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import store from "./store";
import router from "./router";
import Notifications from "@kyvg/vue3-notification";
import "./permission";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import * as Sentry from "@sentry/vue";
import VueSocketIOExt from "vue-socket.io-extended";
import { io } from "socket.io-client";

import "@/styles/index.scss";

const app = createApp(App);

const socket = io(process.env.VUE_APP_BASE_API, {
  autoConnect: false,
});
app.use(VueSocketIOExt, socket, { store });

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT || "local",
    release: process.env.VUE_APP_GIT_HASH,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: +(process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE ?? 0.1),
    // Session Replay
    replaysSessionSampleRate: +(
      process.env.VUE_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE ?? 0.0
    ),
    replaysOnErrorSampleRate: +(
      process.env.VUE_APP_SENTRY_REPLAYS_ERROR_SAMPLE_RATE ?? 1.0
    ),
    sendDefaultPii: true,
    ignoreErrors: ["ChunkLoadError", "Loading chunk", "Loading CSS chunk"],
    beforeSend(event, hint) {
      console.debug("Sentry beforeSend", event, hint);

      if (event.exception) {
        Sentry.showReportDialog({ eventId: event.event_id });
      }

      return event;
    },
  });
}

app.use(store).use(router).use(Notifications).mount("#app");
