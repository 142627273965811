
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { StripeTab, ProfileTab, ChangeLogTab, OnlinePresenceTab } from "./tabs";

enum MENU_ITEMS {
  Stripe,
  Profile,
  OnlinePresence,
  UsersChangelog,
}

@Options({
  name: "UserInfo",
  components: {
    StripeTab,
    ProfileTab,
    ChangeLogTab,
    OnlinePresenceTab,
  },
})
export default class UserInfo extends Vue {
  @Prop({ required: true }) private user!: any;
  @Prop({ required: true }) private changelog!: any[];
  @Prop({ required: true }) private stripeData!: any[];

  data() {
    return {
      MENU_ITEMS,
    };
  }

  activeItem: MENU_ITEMS = MENU_ITEMS.Profile;

  handleChooseMenuItem(item: MENU_ITEMS): void {
    this.activeItem = item;
  }
}
