
import { Options, Vue } from "vue-class-component";

import { BuildModel, BuildsModule } from "@/store/modules/builds";

import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import PlaylistTable from "@/components/playlist/buildsTable/index.vue";
import { uploadNewBuild } from "@/api/builds";
import swal from "sweetalert2";

@Options({ name: "builds", components: { VPagination, PlaylistTable } })
export default class Playlist extends Vue {
  page = 1;

  created(): void {
    BuildsModule.fetchBuilds();
  }
  startUploading(): void {
    document.getElementById("file-upload-input")?.click();
  }
  async uploadingFileValidation(e: any): Promise<void> {
    let file = e.target.files[0];
    if (!file) {
      swal.fire({
        icon: "error",
        text: "File error",
      });
      return;
    }
    const fileExt = file.name.split(".").pop();
    const fileTypes: string[] = ["dmg", "exe"];
    if (!fileTypes.includes(fileExt)) {
      swal.fire({
        icon: "error",
        text: "Error file type",
      });
      file = null;
      return;
    }
    const fd = new FormData();
    fd.append("file", file, file.name);
    fd.append("platform", fileExt == "dmg" ? "mac" : "pc");
    BuildsModule.uploadNewBuild(fd);
  }
  get listBuilds(): BuildModel[] {
    return BuildsModule.builds;
  }
}
