import qs from "qs";
import { AxiosResponse } from "axios";

import request from "@/utils/request";

export const getPaymentsReportRequest = (
  fromDate: string,
  toDate: string,
  format: string
): Promise<AxiosResponse<any>> => {
  return request.get(
    `/api/playlists/report/for-admin?fromDate=${fromDate}&toDate=${toDate}&format=${format}`,
    {
      responseType: "blob",
    }
  );
};

export const getPayoutsListReportRequest = (
  fromDate: string,
  toDate: string,
  format: string
): Promise<AxiosResponse<any>> => {
  return request.get(
    `/api/accounting/payouts/report?fromDate=${fromDate}&toDate=${toDate}&format=${format}`,
    {
      responseType: "blob",
    }
  );
};
export const getPayoutsListReportArtistRequest = (
  fromDate: string,
  toDate: string,
  format: string
): Promise<AxiosResponse<any>> => {
  return request.get(
    `/api/accounting/payouts/summary-report?fromDate=${fromDate}&toDate=${toDate}&format=${format}`,
    {
      responseType: "blob",
    }
  );
};

export const getPayoutsListRequest = (params: {
  fromDate: string | null;
  toDate: string | null;
}): Promise<AxiosResponse<any>> => {
  const queryParam = (
    params ? `?${qs.stringify(params, { skipNulls: true })}` : ""
  )
    .split("%3A")
    .join(":");
  return request.get(`/api/accounting/payouts${queryParam}`);
};
