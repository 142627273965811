
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  name: "AppModal",
})
export default class extends Vue {
  @Prop({ default: false }) private show!: boolean;
  @Prop() private title!: string;

  closeModal(): void {
    this.$emit("onClose");
  }
}
