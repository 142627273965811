import request from "@/utils/request";
import { AxiosResponse } from "axios";

export const exportNewsletter = (data: any): Promise<AxiosResponse<any>> =>
  request.post("/api/messages/newsletter/send", data);

export const listNewsletterTemplates = (): Promise<AxiosResponse<any>> =>
  request.get("/api/messages/newsletter/templates");

export const createNewsletterTemplate = (
  data: any
): Promise<AxiosResponse<any>> =>
  request.post("/api/messages/newsletter/templates", data);

export const readNewsletterTemplate = (
  id: string
): Promise<AxiosResponse<any>> =>
  request.get(`/api/messages/newsletter/templates/${id}`);

export const updateNewsletterTemplate = (
  id: string,
  data: any
): Promise<AxiosResponse<any>> =>
  request.put(`/api/messages/newsletter/templates/${id}`, data);

export const deleteNewsletterTemplate = (
  id: string
): Promise<AxiosResponse<any>> =>
  request.delete(`/api/messages/newsletter/templates/${id}`);

export const listMailingLists = (): Promise<AxiosResponse<any>> =>
  request.get("/api/messages/newsletter/available-mailing-lists");

export const listAdminMail = (params: any): Promise<AxiosResponse<any>> =>
  request.get("/api/messages/admin-mail", { params });
