
import datepicker from "vue3-datepicker";
import { Watch } from "vue-property-decorator";
import { Options, Vue } from "vue-class-component";
import { ReportsModule } from "@/store/modules/reports";
import { PeriodModule } from "@/store/modules/period.module";
import { LeftAdditionalSidebarModule } from "@/store/modules/left-additional-sidebar";
import moment from "moment";

@Options({
  name: "Payouts",
  components: { datepicker },
})
export default class Tracks extends Vue {
  // COMPUTED
  get list() {
    return ReportsModule.list;
  }

  get fromDateAs() {
    return PeriodModule.fromDate;
  }

  get toDateAs() {
    return PeriodModule.toDate;
  }

  get format() {
    return PeriodModule.format;
  }

  get initiateLoading() {
    return LeftAdditionalSidebarModule.initiateLoading;
  }

  get initiateLoadingArtist() {
    return LeftAdditionalSidebarModule.initiateLoadingArtist;
  }

  // HOOKS
  mounted() {
    this.getDefaultList();
  }

  beforeUnmount() {
    ReportsModule.clearList();
  }

  // METHODS

  getDefaultList() {
    ReportsModule.getPayoutsList({});
  }

  handleDownloadReport() {
    const { fromDateAs, toDateAs, format } = this;
    const formated = "YYYY-MM-DDTHH:mm:ss.SSS";
    const fromDate = moment(fromDateAs).startOf("month").format(formated);
    const toDate = moment(toDateAs).endOf("month").format(formated);
    ReportsModule.downloadPayoutsReport({ fromDate, toDate, format });
  }

  handleDownloadReportArtist() {
    const { fromDateAs, toDateAs, format } = this;
    const formated = "YYYY-MM-DDTHH:mm:ss.SSS";
    const fromDate = moment(fromDateAs).startOf("month").format(formated);
    const toDate = moment(toDateAs).endOf("month").format(formated);

    ReportsModule.downloadPayoutsReportArtist({ fromDate, toDate, format });
  }

  dateChange() {
    const { fromDateAs, toDateAs, format } = this;
    const formated = "YYYY-MM-DDTHH:mm:ss.SSS";
    const fromDate = moment(fromDateAs).startOf("month").format(formated);
    const toDate = moment(toDateAs).endOf("month").format(formated);
    ReportsModule.getPayoutsList({ fromDate, toDate, format });
  }

  // WATCH
  @Watch("fromDateAs")
  onChangeLoadStateFrom(value: boolean) {
    if (value) {
      this.dateChange();
    }
  }
  @Watch("toDateAs")
  onChangeLoadStateTo(value: boolean) {
    if (value) {
      this.dateChange();
    }
  }
  @Watch("initiateLoading")
  onChangeLoadState(value: boolean) {
    if (value) {
      this.handleDownloadReport();
    }
  }
  @Watch("initiateLoadingArtist")
  onChangeLoadStateArtist(value: boolean) {
    if (value) {
      this.handleDownloadReportArtist();
    }
  }
}
