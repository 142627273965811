
import Header from "@/components/header/index.vue";
import Sidebar from "@/components/sidebar/index.vue";
import LeftAdditionalSidebar from "@/components/left-additional-sidebar/index.vue";
// import { PermissionModule } from "@/store/modules/permission";
import { Options, Vue } from "vue-class-component";

@Options({
  components: { Header, Sidebar, LeftAdditionalSidebar },
})
export default class Layout extends Vue {
  created(): void {
    // PermissionModule.GenerateRoutes([]);
  }
}
