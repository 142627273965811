import { AxiosResponse } from "axios";
import request from "@/utils/request";

export const removeMemberFromGroupRequest = (
  id: number,
  payload: { membershipId: number }
): Promise<AxiosResponse<any>> => {
  return request.delete(`/api/performers/${id}/group-membership`, {
    data: payload,
  });
};

export const checkCanBeGroupApprovedRequest = (
  id: number
): Promise<AxiosResponse<any>> => {
  return request.get(`/api/performers/${id}/can-be-approved`);
};

export const fetchGroupRequest = (id: number): Promise<AxiosResponse<any>> => {
  return request.get(`/api/performers/${id}`);
};

export const changeGroupStatusRequest = (
  id: number,
  status: string
): Promise<AxiosResponse<any>> => {
  return request.post(`/api/performers/${id}/change-status`, { status });
};

export const addMemberToGroupRequest = (
  id: number,
  data: {
    email: string;
    performerName: string;
  }
): Promise<AxiosResponse<any>> => {
  return request.post(`/api/performers/${id}/group-membership`, data);
};

export const inviteMemberToGroupRequest = (
  id: number,
  data: {
    email: string;
    performerId: number;
  }
): Promise<AxiosResponse<any>> => {
  return request.post(`/api/performers/${id}/send-invitation`, data);
};
