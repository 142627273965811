import qs from "qs";
import { AxiosResponse } from "axios";
import request from "@/utils/request";

export const getAccountingBalancesRequest = (
  params: any
): Promise<AxiosResponse<any>> => {
  const queryParam = params
    ? `?${qs.stringify(params, { skipNulls: true })}`
    : "";
  return request.get(`/api/accounting/balances/${queryParam}`);
};

export const getPayoutsDates = (
  userId: number
): Promise<AxiosResponse<any>> => {
  return request.get(`/api/accounting/payouts/dates`, {
    params: {
      userId,
    },
  });
};

export const markAsPaidRequest = (data: any): Promise<AxiosResponse<any>> => {
  return request.post("/api/accounting/balances/mark-as-paid", data);
};

export const makePayout = (payload: {
  userId: number;
  date: string;
}): Promise<AxiosResponse<any>> => {
  return request.post("/api/accounting/make-payouts", {
    ...payload,
  });
};
