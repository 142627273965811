
import { UserModel, UserModule } from "@/store/modules/user";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import dayjs from "dayjs";
import VueMultiselect from "vue-multiselect";
import { getAccessToken, sendUserEmail } from "@/api/user";
import swal from "sweetalert2";
import { REVIEW_STATUSES } from "@/utils/constants";
import EmailModal from "@/components/send-email-modal/index.vue";
import Switcher from "@/components/switcher/index.vue";
import ChangeLog from "@/components/changeLog/index.vue";
import { GroupsModule } from "@/store/modules/groups";
import ConfirmModal from "@/components/confirmPopup/index.vue";
import AppModal from "@/components/app-modal/index.vue";
import Activity from "@/components/activity/Activity.vue";
import OnlinePresence from "@/components/playlist/producersTable/components/online-presence/index.vue";
import UserEmailLog from "@/components/user-email-log/index.vue";

@Options({
  name: "producersTable",
  components: {
    Activity,
    AppModal,
    Switcher,
    ChangeLog,
    EmailModal,
    ConfirmModal,
    OnlinePresence,
    VueMultiselect,
    UserEmailLog,
  },
})
export default class PlaylistTable extends Vue {
  @Prop({ required: true }) public list!: UserModel[];
  @Prop({ required: true }) private reviewStatus!: REVIEW_STATUSES | null;
  members: any[] = [];
  emailTo = "";
  modalShown = false;
  currentEmail = "";
  processing = false;
  data() {
    return {
      REVIEW_STATUSES,
      avatarUrl: process.env.VUE_APP_S3_URL,
    };
  }
  sort = {
    field: "",
    direction: "",
  };
  DJPaymentMethod = "";
  ProducerPaymentMethod = "";
  addingAlias = "";
  allowAdding = false;
  addingGroup = "";
  allowAddingGroup = false;
  country = "";
  isDeleteModalOpen = false;
  deletedUserId: null | number = null;
  allCountries: any[] = [
    "Afghanistan",
    "\u00c5land Islands",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia, Plurinational State of",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Congo, the Democratic Republic of the",
    "Cook Islands",
    "Costa Rica",
    "C\u00f4te d'Ivoire",
    "Croatia",
    "Cuba",
    "Cura\u00e7ao",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands (Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Holy See (Vatican City State)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran, Islamic Republic of",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, Democratic People's Republic of",
    "Korea, Republic of",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Macedonia, the Former Yugoslav Republic of",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia, Federated States of",
    "Moldova, Republic of",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "R\u00e9union",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Saint Barth\u00e9lemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan, Province of China",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "United States Minor Outlying Islands",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela, Bolivarian Republic of",
    "Viet Nam",
    "Virgin Islands, British",
    "Virgin Islands, U.S.",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];
  showDisplayedNameModal = false;
  currentPerformer = {
    id: "",
    name: "",
    userId: "",
    displayedName: "",
  };
  fetchingToken = false;

  async handleSaveDisplayedName() {
    await UserModule.updateUserPerformer({
      id: this.currentPerformer.id,
      userId: this.currentPerformer.userId,
      data: { displayedName: this.currentPerformer.displayedName },
    });
    this.handleCloseDisplayedNameModal();
  }

  handleOpenDisplayedName(user: any) {
    const performer = user.performers.find(({ isPrimary }: any) => isPrimary);
    this.currentPerformer = {
      userId: user.id,
      id: performer.id,
      name: performer.name,
      displayedName: performer.displayedName || "",
    };
    this.showDisplayedNameModal = true;
  }

  handleCloseDisplayedNameModal() {
    this.showDisplayedNameModal = false;
    this.currentPerformer = { id: "", userId: "", name: "", displayedName: "" };
  }

  handleOpenGroup(id: number) {
    this.$router.push({ name: "Group", params: { id } });
  }

  handleToggleUploadedTracks(value: boolean, userId: number) {
    UserModule.updateUser({ userId, data: { hideUploadedTracks: value } });
  }

  handleToggleEmailFreq(value: boolean, userId: number) {
    UserModule.updateUser({ userId, data: { isFestival: value } });
  }

  canGroupApproved(memberships: any[], groupName: string): boolean {
    const group = memberships.find(({ group }) => group.name === groupName);
    return group ? !!group.canApprove : false;
  }

  handleOpenEmailModal(email: string) {
    this.currentEmail = email;
    this.modalShown = true;
  }

  getCurrentFieldDirection(field: string): string {
    if (field === this.sort.field) {
      if (this.sort.direction === "ASC") {
        return "down";
      }
      if (this.sort.direction === "DESC") {
        return "up";
      }
      return "";
    }

    return "";
  }

  getCurrentDirection(): string {
    if (this.sort.direction === "ASC") {
      return "DESC";
    }
    if (this.sort.direction === "DESC") {
      return "";
    }

    return "ASC";
  }

  handleSortChange(field: string) {
    if (field === this.sort.field) {
      this.sort.direction = this.getCurrentDirection();
    } else {
      this.sort.field = field;
      this.sort.direction = "ASC";
    }
    this.$emit("onSortChange", this.sort);
  }

  async sendEmail({
    email,
    subject,
    message,
  }: {
    email: string;
    subject: string;
    message: string;
  }) {
    try {
      swal.showLoading();
      const request = {
        subject,
        to: email,
        html: message,
      };
      const response = await sendUserEmail(request);
      if (response && response.data) {
        swal.fire({
          icon: "success",
          text: "Your email has been sent.",
        });
        this.closeEmailModal();
      }
    } catch (e) {
      swal.close();
      console.log(e, "e");
    }
  }

  addAlias(user: UserModel): void {
    if (!this.allowAdding) {
      this.allowAdding = true;
    } else {
      if (this.addingAlias === null || this.addingAlias === "") {
        swal.fire({
          icon: "error",
          text: "Please type any alias name.",
        });
        return;
      }
      const trimmedElement = this.addingAlias.trim();
      if (user.mappedPerformers.length > 0) {
        if (user.mappedPerformers.some((x: any) => x == trimmedElement)) {
          swal.fire({
            icon: "error",
            text: `You already have ${trimmedElement} in alias list.`,
          });
          return;
        }
      }
      user.mappedPerformers.push(trimmedElement as any);
      user.performers.push({
        id: null,
        name: trimmedElement,
        status: "pending",
      });
      this.addingAlias = "";
      this.allowAdding = false;
      UserModule.updateUserData(user);
    }
  }
  addVenue(user: UserModel): void {
    if (!this.allowAdding) {
      this.allowAdding = true;
    } else {
      if (this.addingAlias === null || this.addingAlias === "") {
        swal.fire({
          icon: "error",
          text: `Please type any venue name.`,
        });
        return;
      }
      const trimmedElement = this.addingAlias.trim();
      if (user.mappedVenues.length > 0) {
        if (user.mappedVenues.some((x: any) => x == trimmedElement)) {
          swal.fire({
            icon: "error",
            text: `You already have ${trimmedElement} in venue list.`,
          });
          return;
        }
      }
      user.mappedVenues.push(trimmedElement as any);
      user.venues.push({
        id: null,
        name: trimmedElement,
        status: "Pending",
      });
      this.addingAlias = "";
      this.allowAdding = false;
      UserModule.updateUserData(user);
    }
  }
  closeEmailModal(): void {
    this.modalShown = false;
    this.currentEmail = "";
  }
  removeFromGroup(idUser: number, groupId: number, groupName: string): void {
    const foundUser = this.list.find((x) => x.id == idUser);
    if (foundUser) {
      foundUser.groupMemberships = foundUser.groupMemberships.filter(
        (membership: any) => membership.groupId !== groupId
      );
      foundUser.mappedGroups = foundUser.mappedGroups.filter(
        (name) => name !== groupName
      );
    }
  }
  removeAliastem(user: UserModel, aliasName: string): void {
    user.mappedPerformers.splice(
      user.mappedPerformers.findIndex((a) => a == aliasName),
      1
    );
    UserModule.updateUserData(user);
  }
  removeVenue(user: UserModel, venueName: string): void {
    user.mappedVenues.splice(
      user.mappedVenues.findIndex((a) => a == venueName),
      1
    );
    UserModule.updateUserData(user);
  }
  async removeMemberFromGroup(userId: number, membership: any) {
    if (membership) {
      await GroupsModule.removeMemberFromGroup({
        id: membership.groupId,
        membershipId: membership.id,
      });
      this.removeFromGroup(userId, membership.groupId, membership.group.name);
    }
  }
  googleSearchArtist(userName: string): void {
    window.open(`https://google.com/search?q=${userName} music`, "_blank");
  }
  verifyUser(user: UserModel): void {
    this.$emit("onVerifyUser", user.id);
  }

  async loginAsUser(user: UserModel): Promise<void> {
    this.fetchingToken = true;
    swal.showLoading();
    let token;
    try {
      const { data } = await getAccessToken(user.id);
      token = data.accessToken;
    } catch (e) {
      await swal.fire({
        icon: "error",
        text: `Failed to get access token for User #${user.id}`,
      });
      return;
    } finally {
      swal.close();
      this.fetchingToken = false;
    }

    window.open(
      `${process.env.VUE_APP_WEB_URL}/login-as-user?token=${token}&userId=${user.id}`,
      "_blank"
    );
  }

  handleShowDeleteUserModal(user: UserModel): void {
    this.isDeleteModalOpen = true;
    this.deletedUserId = user.id;
  }

  handleCloseDeleteUserModal(): void {
    this.isDeleteModalOpen = false;
    this.deletedUserId = null;
  }

  deleteUser(): void {
    this.$emit("onDeleteUser", this.deletedUserId);
    this.handleCloseDeleteUserModal();
  }

  handleHoldUser(id: number): void {
    this.$emit("onHoldUser", id);
  }

  handleReviewUser(id: number): void {
    this.$emit("onReviewUser", id);
  }

  changeStyle(role: string): string {
    let result = "";
    if (role.length <= 3) {
      result = role.toUpperCase();
    } else {
      const firstLetter = role[0].toUpperCase();
      result = role.replace(role[0], firstLetter);
    }
    return result;
  }

  formatCreatedDate(created: string): string {
    return (created = dayjs(created).format("YYYY/MM/DD"));
  }

  async handleOpenUsers(user: UserModel): Promise<void> {
    await UserModule.getUser({ id: user.id, status: !user.isOpen });

    if (user.isOpen && UserModule.isAdmin) {
      const Response = await UserModule.PaymentMethod(user.id);
      if (Response) {
        if (Response.payments !== null) {
          if (Response.payments.details.paymentType === "card") {
            this.DJPaymentMethod = "Stripe (CC)";
          } else if (Response.payments.details.paymentType === "sepa_debit") {
            this.DJPaymentMethod = "Stripe (SEPA)";
          } else if (
            Response.payments.details.paymentType === "Invoiced payments"
          ) {
            this.DJPaymentMethod = "Stripe (iDEAL)";
          } else {
            this.DJPaymentMethod = "";
          }
        } else {
          this.DJPaymentMethod = "";
        }
        if (Response.payouts !== null) {
          if (Response.payouts.provider === "PayPal") {
            this.ProducerPaymentMethod = `PayPal (${user.paypalEmail})`;
          } else if (Response.payouts.provider === "Wise") {
            this.ProducerPaymentMethod = "Wise";
          } else {
            this.ProducerPaymentMethod = "";
          }
        } else {
          this.ProducerPaymentMethod = "";
        }
      } else {
        this.ProducerPaymentMethod = "";
        this.DJPaymentMethod = "";
      }

      await UserModule.getUserStripeData(user.id);
      await user.groupMemberships.forEach(({ groupId, group: { status } }) => {
        if (status === "pending") {
          UserModule.checkCanBeGroupApproved({ userId: user.id, groupId });
        }
      });
    }
  }
  activateMenu(id: number, menuName: string): void {
    UserModule.activateMenu({ id, menuTab: menuName });
  }
  updateUserData(user: UserModel): void {
    if (this.allowAdding && this.addingAlias) {
      user.mappedPerformers.push(
        this.addingAlias ? this.addingAlias.trim() : ""
      );
    }
    this.allowAdding = false;
    this.addingAlias = "";
    if (this.allowAddingGroup && this.addingGroup) {
      user.mappedGroups.push(this.addingGroup ? this.addingGroup.trim() : null);
    }
    this.allowAddingGroup = false;
    this.addingGroup = "";
    UserModule.updateUserData(user);
  }

  async updateUserSocials(user: UserModel) {
    await UserModule.updateUserSocials(user);
  }

  getVerificationInfo(id: number): void {
    UserModule.getUserStripeData(id);
  }

  handleGrabDataFromDiscogs(user: UserModel) {
    const discogs = user.socials.find((val) => val.type === "Discogs")?.link;
    if (!discogs) {
      swal.fire({
        icon: "error",
        text: "Discogs link is not provided, please go to the Online Presence tab and add it.",
      });
      return;
    }
    UserModule.grabDataFromDiscogs(user.id);
  }

  approveAlias(item: any, alias: any): void {
    const payload = {
      userId: item.id,
      id: item.performers.find((el: any) => el.name === alias).id,
      status:
        item.performers.find((el: any) => el.name === alias).status ===
        "approved"
          ? "pending"
          : "approved",
      name: item.performers.find((el: any) => el.name === alias).name,
      user: item,
    };
    if (this.processing === true) {
      return;
    }
    this.processing = true;
    setTimeout(() => {
      this.processing = false;
      UserModule.setApproveAlias(payload);
    }, 1200);
  }

  approveVenue(item: any, venue: any): void {
    const payload = {
      userId: item.id,
      id: item.venues.find((el: any) => el.name === venue).id,
      status:
        item.venues.find((el: any) => el.name === venue).status === "Approved"
          ? "Pending"
          : "Approved",
      name: item.venues.find((el: any) => el.name === venue).name,
      user: item,
    };
    if (this.processing) {
      return;
    }
    this.processing = true;
    setTimeout(() => {
      this.processing = false;
      UserModule.setApproveVenue(payload);
    }, 1200);
  }

  setApproveGroup(payload: any): void {
    UserModule.setApproveGroup(payload);
  }

  getStatusClass(status: string): string {
    const statusClasses: Map<string, string> = new Map([
      ["pending", "playlist-table__status_pending"],
      ["approved", "playlist-table__status_approved"],
    ]);

    return statusClasses.get(status.toLowerCase()) || "";
  }

  get isAdmin(): boolean {
    return UserModule.isAdmin;
  }

  isPromoter(item: UserModel): boolean {
    return item.roles.some((r) => r.name === "promoter");
  }
}
