
import { Options, Vue } from "vue-class-component";
import SidebarElement from "./sidebarElement/index.vue";
import { LeftAdditionalSidebarModule } from "@/store/modules/left-additional-sidebar";
import { UserModule } from "@/store/modules/user";

@Options({
  name: "Sidebar",
  components: {
    SidebarElement,
  },
})
export default class Sidebar extends Vue {
  // DATA
  dashboardLinks = [
    {
      path: "/dashboard/playlist",
      icon: "playlist",
      roles: ["admin"],
    },
    {
      path: "/dashboard/users",
      icon: "producers",
      roles: ["admin", "intern"],
    },
    {
      path: "/dashboard/builds",
      icon: "builds",
      roles: ["admin"],
    },
    {
      path: "/dashboard/tracks",
      icon: "tracks",
      roles: ["admin"],
    },
    {
      path: "/dashboard/payments",
      icon: "payments",
      roles: ["admin"],
    },
    {
      path: "/dashboard/payouts",
      icon: "payouts",
      roles: ["admin"],
    },
    {
      path: "/dashboard/manual-payouts",
      icon: "dollar",
      roles: ["admin"],
    },
    {
      path: "/dashboard/newsletter",
      icon: "email",
      roles: ["admin"],
    },
    {
      path: "/dashboard/email-log",
      icon: "emails",
      roles: ["admin"],
    },
  ];

  mounted() {
    const container = this.$refs["aside"] as Element;
    container.querySelectorAll(".sidebar-elem__link").forEach((elem) => {
      elem.addEventListener("click", () => {
        const { top } = elem.getBoundingClientRect();
        LeftAdditionalSidebarModule.SET_BUTTON_POSITION(top);
      });
    });
    try {
      const elem = container.querySelector(".router-link-active") as Element;
      const { top } = elem.getBoundingClientRect();
      LeftAdditionalSidebarModule.SET_BUTTON_POSITION(top);
    } catch (e) {
      console.log("add vue-email-editor");
    }
  }
  get userRoles(): string[] {
    return UserModule.roles;
  }
  get visibleDashboardLinks() {
    return this.dashboardLinks.filter((link) =>
      link.roles.some((role) => this.userRoles.includes(role))
    );
  }
}
