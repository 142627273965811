import router from "./router";
import { UserModule } from "./store/modules/user";
import { PermissionModule } from "@/store/modules/permission";
import swal from "sweetalert2";

router.beforeEach(async (to, from, next) => {
  if (UserModule.token) {
    if (to.path === "/signin") {
      next("/");
    } else {
      // Check whether the user has obtained his permission roles
      if (UserModule.roles.length === 0) {
        swal.showLoading();
        try {
          // Note: roles must be a object array! such as: ['admin'] or ['developer', 'editor']
          await UserModule.GetUserInfo();
          const roles = UserModule.roles;
          // Generate accessible routes map based on role
          PermissionModule.GenerateRoutes(roles);
          // Dynamically add accessible routes
          PermissionModule.dynamicRoutes.forEach((route) => {
            router.addRoute(route);
          });
          // Hack: ensure addRoutes is complete
          // Set the replace: true, so the navigation will not leave a history record
          next({ ...to, replace: true });
        } catch (err) {
          // Remove token and redirect to login page
          UserModule.ResetToken();
          await swal.fire({
            icon: "error",
            text: err.message || "Failed to prepare navigation routes",
          });
          next("/signin");
        } finally {
          swal.close();
        }
      } else {
        next();
      }
    }
  } else {
    // Has no token

    if (to.meta && to.meta.noAuth) {
      // In the free login whitelist, go directly
      next();
    } else {
      // Other pages that do not have permission to access are redirected to the login page.
      next("/signin");
    }
  }
});
