
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";

import {
  PerformerModel,
  PlaylistModel,
  PlaylistModule,
  TrackModel,
} from "@/store/modules/playlist";
import { TracksModule } from "@/store/modules/tracks";

import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import AsyncFilter from "@/components/async-filter/index.vue";
import PlaylistTable from "@/components/playlist/table/index.vue";
import { PeriodModule } from "@/store/modules/period.module";
import { LeftAdditionalSidebarModule } from "@/store/modules/left-additional-sidebar";
import moment from "moment";

@Options({
  name: "playlist",
  components: {
    VPagination,
    AsyncFilter,
    PlaylistTable,
  },
})
export default class Playlist extends Vue {
  page = 1;
  column = "";
  filter = "";
  direction = "";
  playlistStatus: null | boolean = false;
  pageToGO: number = this.page;
  trackId: number | null = null;
  takeArr = [15, 30, 50];
  changeTake(numb: number): void {
    PlaylistModule.changeTake(numb);
    PlaylistModule.fetchPlaylists(this.queryParams);
  }
  updateHandler(): void {
    PlaylistModule.fetchPlaylists(this.queryParams);
  }
  created(): void {
    this.fetchPageData();
  }

  async fetchPageData() {
    const { trackId } = this.$route.query;
    this.trackId = trackId ? Number(trackId) : null;
    this.playlistStatus = trackId ? null : false;
    if (this.trackId) {
      await TracksModule.fetchTrack({
        trackId: this.trackId,
        params: {
          fromApprovedPlaylists: false,
          skipOwnPlays: false,
        },
      });
    }
    PlaylistModule.fetchPlaylists(this.queryParams);
  }

  handleStatusChange() {
    this.page = 1;
    PlaylistModule.fetchPlaylists(this.queryParams);
  }

  handleSortChange(sort: { field: string; direction: string }): void {
    this.column = sort.field;
    this.direction = sort.direction;
    PlaylistModule.fetchPlaylists(this.queryParams);
  }

  goToPage(): void {
    this.pageToGO = Number(this.pageToGO);
    this.page = this.pageToGO;
    this.updateHandler();
  }
  checkMaxPage(): void {
    if (this.pageToGO && this.pageToGO > this.pages) {
      this.pageToGO = this.pages;
    }
  }
  handleFilterChange(filter: string) {
    this.page = 1;
    this.filter = filter;
    PlaylistModule.fetchPlaylists(this.queryParams);
  }
  handleOffTrack() {
    this.$router.push({ name: "Playlist" });
  }

  async handleDownloadReport(): Promise<void> {
    const { fromDateAs, toDateAs, format } = this;
    const formated = "YYYY-MM-DDTHH:mm:ss.SSS";
    const fromDate = moment(fromDateAs).startOf("month").format(formated);
    const toDate = moment(toDateAs).endOf("month").format(formated);
    await PlaylistModule.downloadReport({
      fromDate,
      toDate,
      format,
      async: true,
    });
  }

  async handleEnhancedDownloadReport(): Promise<void> {
    const { fromDateAs, toDateAs } = this;
    const formated = "YYYY-MM-DDTHH:mm:ss.SSS";
    const fromDate = moment(fromDateAs).startOf("month").format(formated);
    const toDate = moment(toDateAs).endOf("month").format(formated);
    await PlaylistModule.downloadEnhancedReport({
      fromDate,
      toDate,
      format: "xlsx",
      zip: true,
      async: true,
    });
  }

  // WATCH
  @Watch("initiateLoading")
  async onChangeLoadState(value: boolean): Promise<void> {
    if (value) {
      await this.handleDownloadReport();
    }
  }

  @Watch("initiateLoadingEnhenced")
  onChangeLoadStateEnhanced(value: boolean) {
    if (value) {
      this.handleEnhancedDownloadReport();
    }
  }

  @Watch("$route")
  handleRouteChange() {
    TracksModule.setTrack(null);
    this.fetchPageData();
  }

  get pages(): number {
    return PlaylistModule.pages;
  }

  get pageMultiselect(): number {
    return PlaylistModule.pageMultiselect;
  }

  get list(): PlaylistModel[] {
    return PlaylistModule.playlists;
  }

  get searchTracks(): TrackModel[] {
    return PlaylistModule.searchedTracks;
  }

  get searchTracksPages(): number {
    return PlaylistModule.searchedTracksPages;
  }

  get take(): number {
    return PlaylistModule.take;
  }

  get queryParams(): {
    page: number;
    query: string | null;
    trackId: number | null;
    status: null | boolean;
    orderColumn: string | null;
    orderDirection: string | null;
  } {
    return {
      page: this.page,
      query: this.filter ? this.filter : null,
      trackId: this.trackId || null,
      status: this.playlistStatus,
      orderColumn: this.direction !== "" ? this.column : null,
      orderDirection: this.direction !== "" ? this.direction : null,
    };
  }

  get track() {
    return TracksModule.track;
  }

  get fromDateAs() {
    return PeriodModule.fromDate;
  }

  get toDateAs() {
    return PeriodModule.toDate;
  }

  get format() {
    return PeriodModule.format;
  }

  get initiateLoading() {
    return LeftAdditionalSidebarModule.initiateLoading;
  }

  get initiateLoadingEnhenced() {
    return LeftAdditionalSidebarModule.initiateLoadingEnhenced;
  }
}
