
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Options({
  name: "InviteMemberModalForm",
})
export default class InviteMemberModalForm extends Vue {
  @Prop({ required: true, default: false }) private show!: boolean;
  @Prop({ required: true, default: "" }) private memberName!: string;

  form = {
    email: "",
    performerName: "",
  };

  handleSubmit() {
    this.$emit("onSubmit", this.form);
  }

  closeModal(evt: Event): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.$emit("onClose");
  }

  @Watch("show")
  handleShowChange(value: boolean) {
    if (value) {
      this.form.performerName = this.memberName;
    }
  }
}
