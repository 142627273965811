
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  name: "AsyncFilter",
})
export default class AsyncFilter extends Vue {
  @Prop({ required: false, default: "Search..." }) private placeholder!: string;

  filter = "";
  typingTimer = 0;
  doneTypingInterval = 700;

  async handleFilterChange() {
    clearTimeout(this.typingTimer);
    this.typingTimer = window.setTimeout(() => {
      this.$emit("onFilterChange", this.filter);
    }, this.doneTypingInterval);
  }
}
