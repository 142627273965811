import { IDefaultQueryParams } from "./types";
import { AxiosResponse } from "axios";
import qs from "qs";
import request from "@/utils/request";

export const socialsScrapping = (
  socials: any,
  primeArtistName: string
): Promise<AxiosResponse<any>> => {
  return request.post(`/api/scrapper`, {
    socials: socials,
    primeArtistName: primeArtistName,
  });
};
