
import { BuildModel } from "@/store/modules/builds";
import { Prop } from "vue-property-decorator";
import { Options, Vue } from "vue-class-component";
import dayjs from "dayjs";

export default class PlaylistTable extends Vue {
  @Prop({ required: true }) private listBuilds!: BuildModel[];

  amazonUrl = process.env.VUE_APP_S3_URL;

  formatCreatedDate(created: string): string {
    return (created = dayjs(created).format("YYYY/MM/DD"));
  }
}
