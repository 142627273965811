<template>
  <div class="newsletter-editor">
    <div class="newsletter-editor__header">
      <h1>Newsletter Template Editor</h1>
      <div class="newsletter-editor__select select">
        <p class="select__title" @click="isOptionsVisible = !isOptionsVisible">
          {{ selected }}
        </p>
        <div v-if="isOptionsVisible" class="select__options">
          <p
            class="select__option"
            v-for="option in options"
            :key="option.list"
            @click="selectOption(option)"
          >
            <span class="select__text">{{ option.list }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="newsletter-editor__container">
      <div class="newsletter-editor__bar bar row">
        <div class="col">
          <div class="row align-items-center">
            <label class="newsletter-editor__label col-auto" for="name">
              Name
            </label>
            <input
              v-model="name"
              name="name"
              class="newsletter-editor__name col"
              type="text"
            />
          </div>
        </div>
        <div class="col">
          <div class="row align-items-center">
            <label class="newsletter-editor__label col-auto" for="subject">
              Subject
            </label>
            <input
              v-model="subject"
              name="subject"
              class="newsletter-editor__subject col"
              type="text"
            />
          </div>
        </div>
        <div class="col-auto">
          <div class="row align-items-center">
            <button
              :disabled="!canSaveDesign"
              class="button-default bar__button col-1"
              @click="saveDesign(false)"
            >
              Save Design
            </button>
            <button
              :disabled="!canSaveDesign"
              class="button-default bar__button col-1"
              @click="saveDesign(true)"
            >
              Save as New
            </button>
            <button
              :disabled="!canSend"
              class="button-default bar__button col-1"
              @click="exportHtml"
            >
              Send
            </button>
          </div>
        </div>
      </div>

      <EmailEditor
        ref="emailEditor"
        v-on:load="handleEditorLoaded"
        v-on:ready="editorReady"
      />
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import EmailEditor from "vue-email-editor/src/components/EmailEditor.vue";
import { Watch } from "vue-property-decorator";
import swal from "sweetalert2";
import {
  createNewsletterTemplate,
  exportNewsletter,
  readNewsletterTemplate,
  updateNewsletterTemplate,
  listMailingLists,
} from "@/api/messages";

@Options({
  name: "NewsletterEditor",
  components: { EmailEditor },
})
export default class NewsletterEditor extends Vue {
  name = "";
  subject = "";
  listHint = "Select user group email";
  selected = this.listHint;
  design = "";
  isOptionsVisible = false;
  options = [
    { list: "all_users" },
    { list: "all_djs" },
    { list: "active_djs" },
    { list: "inactive_djs" },
    { list: "cherry_djs" },
    { list: "all_producers" },
    { list: "non_registered_users" },
    { list: "registered_users" },
    { list: "active_producers" },
    { list: "inactive_producers" },
    { list: "cherry_producers" },
    { list: "paid_producers" },
  ];
  editorLoaded = false;

  async created() {
    await this.fetchMailingLists();
    await this.fetchPageData();
  }

  async fetchMailingLists() {
    swal.showLoading();
    try {
      const { data } = await listMailingLists();
      this.options = data.map((item) => ({ list: item }));
    } catch (e) {
      await swal.fire({
        icon: "error",
        text: `Failed to fetch mailing lists`,
      });
    } finally {
      swal.close();
    }
  }

  @Watch("$route.params.id")
  handleRouteChange() {
    this.fetchPageData();
  }

  mounted() {
    document.addEventListener("click", this.hideOptions.bind(this), true);
  }

  beforeDestroy() {
    document.removeEventListener("click", this.hideOptions, false);
  }

  get canSaveDesign() {
    return !!this.name;
  }

  get canSend() {
    return !!this.subject && this.selected !== this.listHint;
  }

  async fetchPageData() {
    const { id } = this.$route.params;

    if (!id || id === "new") {
      return;
    }

    swal.showLoading();
    try {
      const { data } = await readNewsletterTemplate(id);
      this.name = data.name;
      this.design = data.content;

      if (this.editorLoaded) {
        this.$refs.emailEditor.editor.loadDesign(data.content);
      }
    } catch (e) {
      await swal.fire({
        icon: "error",
        text: `Failed to fetch newsletter template #${id}`,
      });
    }
    swal.close();
  }

  handleEditorLoaded() {
    this.editorLoaded = true;

    if (this.design) {
      this.$refs.emailEditor.editor.loadDesign(this.design);
    }
  }

  editorReady() {
    console.debug("editorReady");
  }
  async saveDesign(forceNew = false) {
    const design = await new Promise((resolve) => {
      this.$refs.emailEditor.editor.saveDesign((design) => {
        console.log("saveDesign", design);
        resolve(design);
      });
    });
    this.design = design;

    swal.showLoading();
    try {
      const { id } = this.$route.params;

      if (id === "new" || forceNew) {
        const { data } = await createNewsletterTemplate({
          name: this.name,
          content: design,
        });
        await swal.fire({
          icon: "success",
          text: "The new newsletter design has been saved.",
        });
        await this.$router.replace({
          name: "NewsletterEditor",
          params: { id: data.id },
        });
        return;
      } else {
        await updateNewsletterTemplate(id, {
          name: this.name,
          content: design,
        });
      }
    } catch (e) {
      await swal.fire({
        icon: "error",
        text: "Failed to save newsletter template design",
      });
      return;
    } finally {
      swal.close();
    }

    await swal.fire({
      icon: "success",
      text: "The newsletter design has been saved.",
    });
  }
  async exportHtml() {
    const html = await new Promise((resolve) => {
      this.$refs.emailEditor.editor.exportHtml((data) => {
        resolve(data.html);
      });
    });
    const payload = {
      list: this.selected,
      subject: this.subject,
      html,
    };
    swal.showLoading();
    try {
      await exportNewsletter(payload);
    } catch (e) {
      await swal.fire({
        icon: "success",
        text: `The newsletter has been sent to "${payload.list}" users list`,
      });
    } finally {
      swal.close();
    }
  }
  selectOption(option) {
    this.selected = option.list;
    this.isOptionsVisible = false;
  }
  hideOptions() {
    this.isOptionsVisible = false;
  }
}
</script>

<style lang="scss" scoped>
.newsletter-editor {
  min-height: 100%;
  &__header {
    height: 70px;
    display: flex;
    padding: 0px 23px;
    align-items: center;
    background: #36c9be;
    justify-content: space-between;
    h1 {
      font-size: 36px;
      font-weight: 500;
      color: #ffffff;
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #f2f1ef;
    height: calc(100% - 70px);
  }
  &__label {
    font-weight: 700;
    font-size: 9px;
    line-height: 12px;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    color: #ffffff;
    margin-left: 20px;
    margin-right: 10px;
  }
  &__subject,
  &__name {
    //min-width: 1000px;
    padding: 3px 5px;
    border: 1px solid #75707b;
  }
  &__bar {
    background: #16978e;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    .bar {
      &__button {
        border: 1px solid #ffffff;
        padding: 2px 10px;
        margin: 0 20px;
        min-width: 100px;
      }
    }
  }
  &__select {
    position: relative;
    text-align: left;
    min-width: 300px;
    height: 24px;
    color: #fff;
    border: none;
    outline: none;
    font-size: 10px;
    line-height: 24px;
    background: #16978e;
    padding: 0 26px 0 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../../assets/icon-expand.svg");
    background-repeat: no-repeat;
    background-position: center right 10px;
    background-size: 8px;
    cursor: pointer;
    .select {
      &__options {
        position: absolute;
        top: 24px;
        left: 0;
        width: 100%;
        background: #16978e;
        border: 1px solid #75707b;
        box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.5);
      }
      &__option {
        margin-bottom: 0;
        cursor: pointer;
        &:hover {
          background-color: #3498db;
        }
      }
      &__text {
        margin-left: 5px;
      }
    }
  }
}
</style>
