import qs from "qs";
import { AxiosResponse } from "axios";

import request from "@/utils/request";
import { IDefaultQueryParams, ITrackQueryParams } from "./types";

export const fetchTracksRequest = (
  params: IDefaultQueryParams & ITrackQueryParams
): Promise<AxiosResponse<any>> => {
  const queryParam = params
    ? `?${qs.stringify(params, { skipNulls: true })}`
    : "";
  return request.get(`/api/tracks/${queryParam}`);
};

export const fetchTrackRequest = (
  id: number,
  params: any
): Promise<AxiosResponse<any>> => {
  return request.get(`/api/tracks/${id}`, { params });
};

export const updateTrackRequest = (
  id: number,
  payload: any
): Promise<AxiosResponse<any>> => {
  return request.put(`/api/tracks/${id}`, payload);
};
