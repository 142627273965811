import { RouteRecordRaw } from "vue-router";

import { book } from "../book";

import Auth from "@/views/auth/index.vue";

const authRouter: RouteRecordRaw = {
  path: book.auth.index,
  component: Auth,
  name: "Auth",
  meta: {
    noAuth: true,
  },
  children: [
    {
      path: book.auth.signin,
      component: () =>
        import(
          /* webpackChunkName: "signin" */ "@/views/auth/signin/index.vue"
        ),
      name: "Signin",
    },
  ],
};

export default authRouter;
