import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import authRouter from "./modules/auth";
import dashboardRouter from "./modules/dashboard";
import NotFound from "@/views/notfound/index.vue";

export const constantRoutes = [
  authRouter,
  {
    path: "/",
    redirect: "/dashboard",
  },
  { path: "/notfound", component: NotFound },
];
export const asyncRoutes: Array<RouteRecordRaw> = [
  dashboardRouter,
  { path: "/:pathMatch(.*)*", redirect: "/notfound" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: constantRoutes,
});

export default router;
