import { UserModel } from "@/store/modules/user";
import Cookies from "js-cookie";

// User
const tokenKey = "token";
const userKey = "user";

export const getToken = (): string | undefined => Cookies.get(tokenKey);
export const setToken = (token: string): string | undefined =>
  Cookies.set(tokenKey, token, { expires: 10 });
export const removeToken = (): void => Cookies.remove(tokenKey);

export const getUser = (): UserModel | null => {
  const user = Cookies.get(userKey);
  if (user) {
    return JSON.parse(user);
  }
  return null;
};
export const setUser = (user: UserModel): string | undefined =>
  Cookies.set(userKey, JSON.stringify(user), { expires: 10 });
export const removeUser = (): void => Cookies.remove(userKey);

export const getReportCookie = (uuid: string): string | undefined =>
  Cookies.get(`report-${uuid}`);
export const setReportCookie = (uuid: string): string | undefined =>
  Cookies.set(`report-${uuid}`, uuid, { expires: 1 });
export const removeReportCookie = (uuid: string): void =>
  Cookies.remove(`report-${uuid}`);
