import {
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store";
import moment from "moment";

interface IPeriod {
  fromDate: string;
  toDate: string;
  format: string;
  dateFormat: string;
}
const DATE_FORMAT = "YYYY-MM";
const DATE_FORMAT_SSS = "YYYY-MM-DDTHH:mm:ss.SSS";
// TODO: refactor with date-fns ()
@Module({ dynamic: true, store, name: "Period", namespaced: true })
class Period extends VuexModule implements IPeriod {
  public dateFormat = DATE_FORMAT;
  public dateFormatSSS = DATE_FORMAT_SSS;
  public fromDate = moment(new Date()).format(this.dateFormat);
  public toDate = moment(new Date()).format(this.dateFormat);
  public fromDateApi = moment(new Date()).format(this.dateFormatSSS);
  public toDateApi = moment(new Date()).format(this.dateFormatSSS);
  public format = "csv";

  @Mutation
  SET_FROM_DATE(date: Date) {
    this.fromDate = moment(date).format(this.dateFormat);
  }

  @Mutation
  SET_TO_DATE(date: Date) {
    this.toDate = moment(date).format(this.dateFormat);
  }

  @Mutation
  SET_FILE_FORMAT(fileFormat: string) {
    this.format = fileFormat;
  }

  @Mutation
  SET_DATE_FORMAT(dateFormat: string) {
    this.dateFormat = dateFormat;
  }
}

export const PeriodModule = getModule(Period);
