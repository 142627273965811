
import { IEmailInfo } from "@/views/email-log/index.vue";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  name: "EmailInfoModal",
})
export default class PlaylistTable extends Vue {
  @Prop({ required: true, default: false }) private show!: boolean;
  @Prop({ required: true }) private email!: IEmailInfo;

  closeModal(evt: Event): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.$emit("onClose");
  }
}
