
import { Options, Vue } from "vue-class-component";
import { REVIEW_STATUSES, ROLE } from "@/utils/constants";

import {
  UserCSVExportModel,
  UserModel,
  UserModule,
} from "@/store/modules/user";

import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import PlaylistTable from "@/components/playlist/producersTable/index.vue";
import { ExportToCsv } from "export-to-csv";
import { fetchUsers } from "@/api/user";
import AsyncFilter from "@/components/async-filter/index.vue";
import InviteArtistModal from "@/components/invite-user-modal/index.vue";
import { Watch } from "vue-property-decorator";

@Options({
  name: "playlist",
  components: {
    VPagination,
    PlaylistTable,
    AsyncFilter,
    InviteArtistModal,
  },
})
export default class Playlist extends Vue {
  page = 1;
  filter = "";
  column = "";
  direction = "";
  isInviteArtistModalShow = false;
  reviewStatus: REVIEW_STATUSES | null = null;
  role: ROLE | null = null;
  pageToGO: number = this.page;
  takeArr = [15, 30, 50];
  user: UserModel | null = null;

  data() {
    return {
      REVIEW_STATUSES,
      ROLE,
    };
  }

  changeTake(numb: number): void {
    UserModule.changeTake(numb);
    this.page = 1;
    UserModule.fetchUsers(this.queryParams);
  }
  updateHandler(): void {
    UserModule.fetchUsers(this.queryParams);
  }
  async created(): Promise<void> {
    await this.fetchPageData();
  }

  async fetchPageData(): Promise<void> {
    const { userId } = this.$route.query;

    if (userId) {
      await UserModule.fetchUser(Number(userId));
      this.user = UserModule.users[0];
    } else {
      if (UserModule.isAdmin) {
        await UserModule.fetchUsers(this.queryParams);
      }
    }
  }

  goToPage(): void {
    this.pageToGO = Number(this.pageToGO);
    this.page = this.pageToGO;
    this.updateHandler();
  }
  checkMaxPage(): void {
    if (this.pageToGO && this.pageToGO > this.pages) {
      this.pageToGO = this.pages;
    }
  }
  async handleFilterChange(filter: string) {
    this.page = 1;
    this.filter = filter;
    await UserModule.fetchUsers(this.queryParams);
  }
  handleSortChange(sort: { field: string; direction: string }): void {
    this.column = sort.field;
    this.direction = sort.direction;
    UserModule.fetchUsers(this.queryParams);
  }

  handleReviewStatusChange() {
    UserModule.fetchUsers(this.queryParams);
  }

  handleRoleChange() {
    UserModule.fetchUsers(this.queryParams);
  }

  async handleVerifyUser(id: number) {
    await UserModule.verifyUser(id);
    await UserModule.fetchUsers(this.queryParams);
  }

  async handleDeleteUser(id: number) {
    await UserModule.deleteUser({ id, data: { completeRemoval: true } });
    await UserModule.fetchUsers(this.queryParams);
  }

  async handleHoldUser(id: number) {
    await UserModule.updateUserReviewStatus({
      id,
      status: REVIEW_STATUSES.Hold,
    });
    await UserModule.fetchUsers(this.queryParams);
  }

  async handleReviewUser(id: number) {
    await UserModule.updateUserReviewStatus({
      id,
      status: REVIEW_STATUSES.Verified,
    });
    await UserModule.fetchUsers(this.queryParams);
  }

  async handleInviteUser(data: { email: string; role: string }) {
    this.isInviteArtistModalShow = false;
    await UserModule.inviteVipUserRequest(data);
    await UserModule.fetchUsers(this.queryParams);
  }

  handleOffUser(): void {
    this.$router.push({ name: "Users" });
  }

  get pages(): number {
    return UserModule.pages;
  }

  get listUsers(): UserModel[] {
    return UserModule.users;
  }

  get take(): number {
    return UserModule.take;
  }

  get isAdmin(): boolean {
    return UserModule.isAdmin;
  }

  get queryParams(): {
    page: number;
    query: string | null;
    orderColumn: string | null;
    reviewStatus: string | null;
    role: string | null;
    orderDirection: string | null;
  } {
    return {
      page: this.page,
      reviewStatus: this.reviewStatus,
      role: this.role,
      query: this.filter ? this.filter : null,
      orderColumn: this.direction !== "" ? this.column : null,
      orderDirection: this.direction !== "" ? this.direction : null,
    };
  }

  async exportUsrsCSV(): Promise<void> {
    const data = await fetchUsers({
      page: 1,
      take: 99999,
    });
    let exportingModel: UserCSVExportModel[] = [];
    if (data.data && data.data.results && data.data.results.length > 0) {
      exportingModel = data.data.results.map((user: UserModel) => ({
        id: user.id,
        asliceId: user.asliceId,
        fullname: user.fullname ? user.fullname : "",
        email: user.email,
        dj: user.roles.some((role) => role.name === "dj") ? "yes" : "no",
        producer: user.roles.some((role) => role.name === "producer")
          ? "yes"
          : "no",
        status: user.registrationComplete ? "completed" : "unfinished",
        primeArtistName: user.primeArtistName || "",
        created: user.created,
      }));
      exportingModel.sort((x) => x.id);
    }
    const options = {
      filename: "AsliceUsers",
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(exportingModel);
  }

  @Watch("$route")
  async handleRouteChange(): Promise<void> {
    this.user = null;
    await this.fetchPageData();
  }
}
