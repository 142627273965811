
import { UserModule } from "@/store/modules/user";
import { Options, Vue } from "vue-class-component";

interface FormErrors {
  [key: string]: string | any;
}

@Options({ name: "Signin" })
export default class Signin extends Vue {
  email = "";
  pwd = "";
  formErrors: FormErrors = {
    email: "",
    pwd: "",
  };

  async SignIn(): Promise<void> {
    try {
      if (this.validateForm()) {
        if (await UserModule.Login({ email: this.email, password: this.pwd })) {
          await this.$router.push("/");
        }
      }
    } catch (e) {
      this.formErrors.pwd = "Invalid email or password";
    }
  }

  get token(): string {
    return UserModule.token;
  }

  validateForm(): boolean {
    this.resetForm();
    if (this.email && this.pwd) return true;
    if (!this.email) {
      this.formErrors.email = "Field is required.";
    }
    if (!this.pwd) {
      this.formErrors.pwd = "Field is required.";
    }
    return false;
  }

  private resetForm() {
    Object.keys(this.formErrors).forEach((key) => (this.formErrors[key] = ""));
  }
}
