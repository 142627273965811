import { AxiosResponse } from "axios";
import request from "@/utils/request";

import qs from "qs";
import { IDefaultQueryParams, IQueryParams, ITrackQueryParams } from "./types";

export const fetchPlaylists = (
  params: IDefaultQueryParams
): Promise<AxiosResponse<any>> => {
  const queryParam = params
    ? `?${qs.stringify(params, { skipNulls: true })}`
    : "";
  return request.get(`/api/playlists/${queryParam.split("%3A").join(":")}`);
};

export const fetchPlaylistTracks = (
  id: number
): Promise<AxiosResponse<any>> => {
  return request.get(`/api/playlists/${id}`);
};

export const fetchPerformers = (
  params: IDefaultQueryParams
): Promise<AxiosResponse<any>> => {
  const queryParam = params
    ? `?${qs.stringify(params, { skipNulls: true })}`
    : "";
  return request.get(`/api/performers/${queryParam}`);
};

export const createPerformer = (payload: {
  name: string;
}): Promise<AxiosResponse<any>> => {
  return request.post("/api/performers", payload);
};

export const createNewTrack = (payload: {
  name: string;
  link?: string;
  performers: string[];
  remixers?: string[];
}): Promise<AxiosResponse<any>> => {
  return request.post("/api/tracks", payload);
};

export const updatePerformerRequest = (
  id: number,
  data: any
): Promise<AxiosResponse<any>> => {
  return request.put(`/api/performers/${id}`, { ...data });
};

export const fetchTracks = (
  params: IDefaultQueryParams & ITrackQueryParams
): Promise<AxiosResponse<any>> => {
  const queryParam = params
    ? `?${qs.stringify(params, { skipNulls: true })}`
    : "";
  return request.get(`/api/tracks/${queryParam}`);
};

// export const deletePerformersFromTrack = (
//   id: number,
//   payload: { performers: number[] }
// ): Promise<AxiosResponse<any>> => {
//   return request.delete(`/api/tracks/${id}/performer`, { data: payload });
// };

export const addPerformers2Track = (
  id: number,
  payload: { performers: number[] }
): Promise<AxiosResponse<any>> => {
  return request.post(`/api/tracks/${id}/performer`, payload);
};

export const createTrackAlias = (
  id: number,
  payload: {
    trackForAliasId: number;
    playlistId: number;
    deleteTrackForAlias: boolean;
    versionId: string;
  }
): Promise<AxiosResponse<any>> => {
  return request.post(`/api/tracks/${id}/create-alias`, payload);
};
export const updateTrackInPlaylistNewF = (
  id: number,
  payload: {
    rawTrackId: number;
    replaceWithId: number;
    versionId: string;
    hidden: boolean;
  }
): Promise<AxiosResponse<any>> => {
  return request.put(`/api/playlists/${id}/track`, payload);
};

export const updateTrackInPlaylist = (
  id: number,
  payload: {
    trackId: number;
    replaceWithId: number;
    versionId: string;
    hidden: boolean;
  }
): Promise<AxiosResponse<any>> => {
  return request.put(`/api/playlists/${id}/track`, payload);
};

export const approvePlaylist = (
  id: number,
  data: { versionId: string }
): Promise<AxiosResponse<any>> => {
  return request.post(`/api/playlists/${id}/approve`, data);
};
export const approveTrack = (
  id: number,
  data: { versionId: string }
): Promise<AxiosResponse<any>> => {
  return request.post(`/api/tracks/${id}/status`, data);
};

export const checkPlaylistToApprovingStatus = (
  id: number
): Promise<AxiosResponse<any>> => {
  return request.get(`/api/playlists/${id}/can-be-approved`);
};

export const trackUnmatching = (
  id: number,
  data: { versionId: string }
): Promise<AxiosResponse<any>> => {
  return request.post(`/api/tracks/${id}/unmatchable`, data);
};

export const getPlaylistStatisticsRequest = (
  id: number
): Promise<AxiosResponse<any>> => {
  return request.get(`/api/playlists/${id}/statistics`);
};

export const updatePlaylistRequest = (
  id: number,
  data: any
): Promise<AxiosResponse<any>> => {
  return request.put(`/api/playlists/${id}`, data);
};

export const updateVenueRequest = (
  id: number,
  data: any
): Promise<AxiosResponse<any>> => {
  return request.put(`/api/venues/${id}`, data);
};

export const reportForAdmin = (
  fromDate: string,
  toDate: string,
  format: string,
  async: boolean
): Promise<AxiosResponse<any>> => {
  return request.get(
    `/api/playlists/report/statistics?fromDate=${fromDate}&toDate=${toDate}&format=${format}&async=${async}`,
    {
      responseType: async ? "json" : "blob",
    }
  );
};
export const reportEnhancedForAdmin = (
  fromDate: string,
  toDate: string,
  format: string,
  zip: boolean,
  async: boolean
): Promise<AxiosResponse<any>> => {
  return request.get(
    `/api/playlists/report/enhanced-statistics?fromDate=${fromDate}&toDate=${toDate}&format=${format}&zip=${zip}&async=${async}`,
    {
      responseType: async ? "json" : "blob",
    }
  );
};
