export enum REVIEW_STATUSES {
  Hold = "Hold",
  Pending = "Pending",
  Modified = "Modified",
  Verified = "Verified",
  Possible = "Possible",
}
export enum ROLE {
  DJ = "dj",
  Producer = "producer",
  Promoter = "promoter",
}
