export const book = {
  auth: {
    index: "/signin",
    signin: "",
  },
  dashboard: {
    index: "/dashboard",
    playlist: "/dashboard/playlist",
    users: "/dashboard/users",
    builds: "/dashboard/builds",
    tracks: "/dashboard/tracks",
    payments: "/dashboard/payments",
    payouts: "/dashboard/payouts",
    manualPayouts: "/dashboard/manual-payouts",
    accounting: "/dashboard/accounting",
    messages: "/dashboard/messages",
    preferences: "/dashboard/preferences",
    group: "/dashboard/groups/:id",
    newsletter: "/dashboard/newsletter",
    newsletterEditor: "/dashboard/newsletter/:id",
    emailLog: "/dashboard/email-log",
  },
};
