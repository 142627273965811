<template>
  <div v-if="prev !== null || newest !== null" class="change-log__names">
    <div v-for="item in prev" :key="item" class="artist-badge">
      <span>{{ item }}</span>
    </div>
    <div v-if="newest.length !== 0">
      <img src="@/assets/right-arrow.svg" alt="" />
    </div>
    <div
      v-for="item in newest"
      :key="item"
      :class="[
        'artist-badge',
        {
          'artist-badge_new': getDiffBetweenLists(prev, newest).includes(item),
        },
      ]"
    >
      <span>{{ item }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChangeLogObjectList",
  props: {
    newList: {
      type: Array,
      required: true,
    },
    prevList: {
      type: Array,
      required: true,
    },
    field: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      prev: this.prevList
        ? this.prevList.map((item) => (this.field ? item[this.field] : item))
        : [],
      newest: this.newList
        ? this.newList.map((item) => (this.field ? item[this.field] : item))
        : [],
    };
  },
  methods: {
    getDiffBetweenLists(list1, list2) {
      return list1
        .filter((x) => !list2.includes(x))
        .concat(list2.filter((x) => !list1.includes(x)));
    },
  },
};
</script>
