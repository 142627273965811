<template>
  <div class="app-modal">
    <div class="app-modal__container">
      <img
        v-if="closeIcon"
        class="app-modal__close"
        src="@/assets/close-groups-modal.svg"
        @click="handleCloseModal"
      />
      <div class="app-modal__body">
        <slot v-if="customContent" name="body" />
        <div>
          <h2 v-if="title" v-html="title" class="app-modal__title" />
          <p
            v-if="description"
            v-html="description"
            class="app-modal__description"
          />
        </div>
      </div>
      <slot v-if="customFooter" name="footer" />
      <div v-else class="app-modal__footer">
        <button @click="handleCloseModal">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InviteModal",
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    description: {
      type: String,
      required: false,
      default: "",
    },
    closeIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    customContent: {
      type: Boolean,
      required: false,
      default: false,
    },
    customFooter: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    handleCloseModal() {
      this.$emit("onClose");
    },
  },
};
</script>
<style lang="scss">
.app-modal {
  top: 0;
  left: 0;
  z-index: 6;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  font-family: "Roboto Mono", monospace !important;
  font-size: 14px;
  &__body {
    padding: 30px;
  }
  &__close {
    top: 20px;
    right: 20px;
    cursor: pointer;
    position: absolute;
  }
  &__title {
    font-size: 32px;
    color: #00978e;
    line-height: 42px;
  }
  &__select {
    padding: 2px 10px;
    border: 1px solid #979997;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    height: 30px;
    width: 100% !important;
    border-radius: 4px !important;
    border: 1px solid #aaa3b1 !important;
    font-size: inherit;
    option {
      padding: 6px 28px 6px 0;
    }
  }
  &__select:focus {
    outline: none !important;
  }
  &__input {
    margin: 20px 0px;
    label {
      font-size: 9px;
      color: #aaa3b1;
      font-weight: bold;
      margin-bottom: 0px;
      letter-spacing: 0.15em;
      text-transform: uppercase;
    }
    input {
      width: 100%;
      height: 30px;
      display: block;
      color: #442671;
      padding: 0px;
      border: none;
      border-bottom: 1px solid #aaa3b1;
    }
    input:focus {
      outline: none !important;
    }
  }
  &__container {
    width: 100%;
    max-width: 425px;
    background: #fff;
    border-radius: 4px;
    position: relative;
    box-shadow: 15px 10px 30px 5px rgb(0 0 0 / 25%);
  }
  &__footer {
    height: 70px;
    display: flex;
    padding: 0 20px;
    align-items: center;
    background: #f2f1ef;
    justify-content: space-between;
  }
}
</style>
