
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Switcher from "@/components/switcher/index.vue";

@Options({
  name: "ArtistModalForm",
  components: {
    Switcher,
  },
})
export default class PlaylistTable extends Vue {
  @Prop({ required: true, default: false }) private show!: boolean;
  @Prop({ required: true }) private artist!: {
    name: string;
    url: string;
    status: string;
  };
  @Prop({ required: false, default: false }) private withUnmatchable!: boolean;

  form = {
    unmatchable: this.artist.status === "charity",
    name: this.artist.name,
    url: this.artist.url,
  };

  handleSubmit() {
    this.$emit("onSubmit", this.form);
  }

  closeModal(evt: Event): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.$emit("onClose");
  }
}
