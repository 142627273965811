
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  name: "ConfirmModal",
})
export default class extends Vue {
  @Prop({ default: false }) private isOpen!: boolean;
  @Prop({ default: "Confirm this?" }) private title!: string;
  @Prop({ default: "" }) private description!: string;
  @Prop({ default: "Submit" }) private successButtonText!: string;
  @Prop({ default: "Close" }) private closeButtonText!: string;

  onSuccess(): void {
    this.$emit("onSuccess");
  }

  closePopup(): void {
    this.$emit("onClose");
  }
}
