
import { Options, Vue } from "vue-class-component";
import EmailInfoModal from "@/components/email-info-modal/index.vue";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { EmailLogModule } from "@/store/modules/email-log";
import get from "lodash/get";
import AsyncFilter from "@/components/async-filter/index.vue";

export interface IEmailInfo {
  id: number;
  email?: string;
  subject?: string;
  html?: string;
  type?: string;
  created?: string;
}

@Options({
  name: "EmailLog",
  components: { AsyncFilter, VPagination, EmailInfoModal },
})
export default class EmailLog extends Vue {
  isEmailInfoModalShow = false;
  selectedEmail: IEmailInfo | null = null;
  page = 1;
  take = 15;
  takeArr = [15, 30, 50];
  pageToGO: number = this.page;
  filter = "";

  data() {
    return {
      get,
    };
  }

  openEmailInfoModal(item: IEmailInfo) {
    this.selectedEmail = item;
    this.isEmailInfoModalShow = true;
  }

  closeEmailInfoModal() {
    this.isEmailInfoModalShow = false;
    this.selectedEmail = null;
  }

  created(): void {
    EmailLogModule.fetchAdminMail(this.queryParams);
  }

  updateHandler(): void {
    EmailLogModule.fetchAdminMail(this.queryParams);
  }

  async handleFilterChange(filter: string) {
    this.page = 1;
    this.filter = filter;
    EmailLogModule.fetchAdminMail(this.queryParams);
  }

  changeTake(count: number): void {
    this.page = 1;
    this.take = count;
    EmailLogModule.fetchAdminMail(this.queryParams);
  }

  checkMaxPage(): void {
    if (this.pageToGO && this.pageToGO > this.pages) {
      this.pageToGO = this.pages;
    }
  }

  goToPage(): void {
    this.page = Number(this.pageToGO);
    this.updateHandler();
  }

  get pages(): number {
    return EmailLogModule.pages;
  }

  get list(): any[] {
    return EmailLogModule.list;
  }

  get queryParams(): {
    page: number;
    limit: number;
    query: string | null;
    type: string;
    email: string | null;
  } {
    return {
      page: this.page,
      limit: this.take,
      query: this.filter ? this.filter : null,
      type: EmailLogModule.type,
      email: null,
    };
  }
}
