import { AxiosResponse } from "axios";
import request from "@/utils/request";

export const updatePerformerRequest = (
  id: string,
  data: any
): Promise<AxiosResponse<any>> => {
  return request.put(`/api/performers/${id}`, data);
};
export const inviteProducer = (payload: {
  email: string;
  producerName: string;
  producerId: number;
  djName: string;
  trackTitle: string;
}): Promise<AxiosResponse<any>> => {
  return request.post(`/api/users/send-invitation`, payload);
};
