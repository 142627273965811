import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sidebar-elem" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: _ctx.path,
      class: "sidebar-elem__link",
      onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
      exact: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          src: _ctx.getImg(_ctx.icon),
          class: "sidebar-elem__icon"
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}