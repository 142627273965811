import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from "vuex-module-decorators";
import swal from "sweetalert2";
import store from "@/store";
import { listAdminMail } from "@/api/messages";

export interface IEmailLogState {
  list: any[];
  page: number;
  pages: number;
  type: string;
}

@Module({ dynamic: true, store, name: "email-log", namespaced: true })
class EmailLog extends VuexModule implements IEmailLogState {
  public list: any[] = [];
  public page = 1;
  public pages = 0;
  public type = "AdminMail";

  @Mutation
  private SET_LIST(list: any) {
    this.list = list;
  }

  @Mutation
  private SET_PAGES(pages: number) {
    this.pages = pages;
  }

  @Action
  public async fetchAdminMail({
    page,
    limit,
    query,
    type,
    email,
  }: {
    page: number;
    limit: number;
    query: string | null;
    type: string;
    email: string | null;
  }) {
    try {
      swal.showLoading();
      const { data } = await listAdminMail({
        page,
        take: limit,
        query,
        type,
        email,
      });
      if (data) {
        this.SET_PAGES(Math.ceil(data.count / limit));
        this.SET_LIST(data.results);
      }
      return data;
    } catch (e) {
      console.log(e);
    } finally {
      swal.close();
    }
  }
}

export const EmailLogModule = getModule(EmailLog);
