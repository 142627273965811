import { book } from "../book";
import Layout from "@/layout/index.vue";

const dashboardRouter: any = {
  path: book.dashboard.index,
  component: Layout,
  name: "DashboardLayout",
  meta: {
    roles: ["admin", "intern"],
  },
  children: [
    {
      path: book.dashboard.index,
      redirect: book.dashboard.playlist,
      name: "Dashboard",
      icon: "dashboard",
      meta: {
        roles: ["admin"],
      },
    },
    {
      path: book.dashboard.index,
      redirect: book.dashboard.users,
      name: "Dashboard",
      icon: "dashboard",
      meta: {
        roles: ["intern"],
      },
    },
    {
      path: book.dashboard.playlist,
      component: () =>
        import(/* webpackChunkName: "playlist" */ "@/views/playlist/index.vue"),
      name: "Playlist",
      icon: "playlist",
      meta: {
        roles: ["admin"],
      },
    },
    {
      path: book.dashboard.users,
      component: () =>
        import(/* webpackChunkName: "playlist" */ "@/views/users/index.vue"),
      name: "Users",
      icon: "producers",
      meta: {
        roles: ["admin", "intern"],
      },
    },
    {
      path: book.dashboard.builds,
      component: () =>
        import(/* webpackChunkName: "playlist" */ "@/views/builds/index.vue"),
      name: "Builds",
      icon: "builds",
      meta: {
        roles: ["admin"],
      },
    },
    {
      path: book.dashboard.tracks,
      component: () =>
        import(/* webpackChunkName: "tracks" */ "@/views/tracks/index.vue"),
      name: "Tracks",
      icon: "tracks",
      meta: {
        roles: ["admin"],
      },
    },
    {
      path: book.dashboard.payments,
      component: () =>
        import(/* webpackChunkName: "payments" */ "@/views/payments/index.vue"),
      name: "Payments",
      icon: "payments",
      meta: {
        roles: ["admin"],
      },
    },
    {
      path: book.dashboard.payouts,
      component: () =>
        import(/* webpackChunkName: "payouts" */ "@/views/payouts/index.vue"),
      name: "Payouts",
      icon: "payouts",
      meta: {
        roles: ["admin"],
      },
    },
    {
      path: book.dashboard.manualPayouts,
      component: () =>
        import(
          /* webpackChunkName: "manual-payouts" */ "@/views/manual-payouts/index.vue"
        ),
      name: "ManualPayouts",
      icon: "payouts",
      meta: {
        roles: ["admin"],
      },
    },
    {
      path: book.dashboard.newsletter,
      component: () =>
        import(
          /* webpackChunkName: "manual-payouts" */ "@/views/newsletter/index.vue"
        ),
      name: "Newsletter",
      icon: "email",
      meta: {
        roles: ["admin"],
      },
    },
    {
      path: book.dashboard.newsletterEditor,
      component: () =>
        import(
          /* webpackChunkName: "manual-payouts" */ "@/views/newsletter/email-editor.vue"
        ),
      name: "NewsletterEditor",
      meta: {
        roles: ["admin"],
      },
    },
    {
      path: book.dashboard.emailLog,
      component: () =>
        import(
          /* webpackChunkName: "manual-payouts" */ "@/views/email-log/index.vue"
        ),
      name: "EmailLog",
      icon: "emails",
      meta: {
        roles: ["admin"],
      },
    },
    {
      path: book.dashboard.accounting,
      redirect: book.dashboard.playlist,
      name: "Accounting",
      icon: "accounting",
      meta: {
        roles: ["admin"],
      },
    },
    {
      path: book.dashboard.messages,
      redirect: book.dashboard.playlist,
      name: "Messages",
      icon: "messages",
      meta: {
        roles: ["admin"],
      },
    },
    {
      path: book.dashboard.preferences,
      redirect: book.dashboard.playlist,
      name: "Preferences",
      icon: "preferences",
      meta: {
        roles: ["admin"],
      },
    },
    {
      path: book.dashboard.group,
      component: () =>
        import(/* webpackChunkName: "group" */ "@/views/group/index.vue"),
      name: "Group",
      meta: {
        roles: ["admin"],
      },
    },
  ],
};

export default dashboardRouter;
