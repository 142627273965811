
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { PlaylistModule } from "@/store/modules/playlist";
import { ManualPayoutsModule } from "@/store/modules/manual-payouts";
import VPagination from "@hennge/vue3-pagination";
import moment from "moment";

@Options({
  components: { VPagination },
})
export default class Activity extends Vue {
  @Prop() readonly userId!: number;

  playlistsPage = 1;
  playlistsTake = 10;
  playlists = [];
  playlistsCount = 0;

  payouts = [];

  get numPlaylistsPages() {
    return Math.ceil(this.playlistsCount / this.playlistsTake) || 1;
  }

  async created() {
    await this.fetchPlaylists();

    const payouts = await ManualPayoutsModule.GetPayoutsDates(this.userId);

    if (payouts) {
      this.payouts = payouts.map((payout: { paidOutDate: string }) => {
        const date = moment(payout.paidOutDate);
        const quarter = date.quarter();
        const year = date.year();
        return `Q${quarter} / ${year}`;
      });
    }
  }

  async fetchPlaylists() {
    const playlistsResult = await PlaylistModule.GetPlaylistsAsIntern({
      userId: this.userId,
      page: this.playlistsPage,
      take: this.playlistsTake,
    });

    if (playlistsResult) {
      this.playlists = playlistsResult.results;
      this.playlistsCount = playlistsResult.count;
    }
  }
}
