
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({ name: "sidebarElement" })
export default class Sidebar extends Vue {
  @Prop({ required: true }) private path!: string;
  @Prop() private icon!: string;

  getImg(icon: string): any {
    return require(`@/assets/sidebar/${icon}.svg`);
  }
}
