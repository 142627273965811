import {
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store";

interface ILeftAdditionalSidebar {
  showButton: boolean;
  showContent: boolean;
  contentReadyToShow: boolean;
  initiateLoading: boolean;
  initiateLoadingArtist: boolean;
  initiateLoadingEnhenced: boolean;
  showCooldown: number;
  buttonPosition: number;
}

@Module({
  dynamic: true,
  store,
  name: "LeftAdditionalSidebar",
  namespaced: true,
})
class LeftAdditionalSidebar
  extends VuexModule
  implements ILeftAdditionalSidebar
{
  public showButton = false;
  public showButtonEnhanced = true;
  public showContent = false;
  public contentReadyToShow = false;
  public initiateLoading = false;
  public initiateLoadingArtist = false;
  public initiateLoadingEnhenced = false;
  public showCooldown = 300;
  public buttonPosition = 18;

  @Mutation
  public SET_SHOW_BUTTON(value: boolean) {
    if (!value) {
      this.showContent = false;
    }
    this.showButton = value;
  }

  @Mutation
  public SET_SHOW_BUTTON_ENHANCED(value: boolean) {
    this.showButtonEnhanced = value;
  }

  @Mutation
  public SET_SHOW_CONTENT(value: boolean) {
    this.showContent = value;
  }

  @Mutation
  public SET_CONTENT_READY_TO_SHOW(value: boolean) {
    this.contentReadyToShow = value;
  }

  @Mutation
  public SET_BUTTON_POSITION(value: number) {
    this.buttonPosition = value + 18 - 60;
  }

  @Mutation
  public SET_INITIATE_LOADING_ARTIST(value: boolean) {
    this.initiateLoadingArtist = value;
  }

  @Mutation
  public SET_INITIATE_LOADING(value: boolean) {
    this.initiateLoading = value;
  }

  @Mutation
  public SET_INITIATE_LOADING_ENHENCED(value: boolean) {
    this.initiateLoadingEnhenced = value;
  }
}

export const LeftAdditionalSidebarModule = getModule(LeftAdditionalSidebar);
