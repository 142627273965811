
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  name: "AddMemberModalForm",
})
export default class AddMemberModal extends Vue {
  @Prop({ required: true, default: false }) private show!: boolean;

  form = {
    email: "",
    performerName: "",
  };

  handleSubmit() {
    this.$emit("onSubmit", this.form);
  }

  closeModal(evt: Event): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.$emit("onClose");
  }
}
