
import moment from "moment";
import debounce from "lodash/debounce";
import datepicker from "vue3-datepicker";
import { Watch } from "vue-property-decorator";
import { Options, Vue } from "vue-class-component";
import { ManualPayoutsModule } from "@/store/modules/manual-payouts";

@Options({
  name: "Payouts",
  components: { datepicker },
})
export default class Tracks extends Vue {
  date: Date | null = moment().toDate();
  threshold: number | null = 0;
  balanceOwnerStatus = "Registered";
  sort = {
    field: "",
    direction: "",
  };

  data() {
    return {
      moment,
    };
  }

  mounted() {
    ManualPayoutsModule.getAccountingBalances(this.queryParams);
  }

  beforeUnmount() {
    ManualPayoutsModule.clearList();
  }

  @Watch("date")
  handleDateChange() {
    ManualPayoutsModule.getAccountingBalances(this.queryParams);
  }

  getTotalAmount(amount: string, oneTimeCharge: string | null) {
    const charge = oneTimeCharge ? Number(oneTimeCharge) : 0;
    const totalAmount = Number(amount) - charge;

    return Number.isInteger(totalAmount) ? totalAmount : totalAmount.toFixed(2);
  }

  handleThresholdChange = debounce(() => {
    ManualPayoutsModule.getAccountingBalances(this.queryParams);
  }, 1000);

  async balanceOwnerStatusChanged() {
    await ManualPayoutsModule.getAccountingBalances(this.queryParams);
  }
  async makePayouts(item: any) {
    console.log(item);
    await ManualPayoutsModule.makePayouts({ data: item });
    await ManualPayoutsModule.getAccountingBalances(this.queryParams);
  }
  handleMarkAsPaid(item: any) {
    ManualPayoutsModule.markAsPaid({
      data: {
        currency: item.currency,
        date: item.date,
        totalAmount: item.totalAmount,
        userArtistName: item.userArtistName,
        userId: item.userId,
        userPaypal: item.userPaypal,
      },
      id: item.id,
    });
  }

  getCurrentDirection(): string {
    if (this.sort.direction === "ASC") {
      return "DESC";
    }
    if (this.sort.direction === "DESC") {
      return "";
    }

    return "ASC";
  }

  handleSortChange(field: string) {
    if (field === this.sort.field) {
      this.sort.direction = this.getCurrentDirection();
    } else {
      this.sort.field = field;
      this.sort.direction = "ASC";
    }
    ManualPayoutsModule.getAccountingBalances(this.queryParams);
  }

  handleOpenUsersPage(id: number): void {
    if (!id) {
      return;
    }

    const location = this.$router.resolve({
      name: "Users",
      query: { userId: id },
    });
    window.open(location.href, "_blank");
  }

  getCurrentFieldDirection(field: string): string {
    if (field === this.sort.field) {
      if (this.sort.direction === "ASC") {
        return "down";
      }
      if (this.sort.direction === "DESC") {
        return "up";
      }
      return "";
    }

    return "";
  }

  get list() {
    return ManualPayoutsModule.list;
  }

  get isFetching() {
    return ManualPayoutsModule.isFetching;
  }

  get listCount() {
    return this.list.length;
  }

  get queryParams() {
    const originalDate = moment(this.date)
      .endOf("day")
      .format("YYYY-MM-DDTHH:mm:ss.SSS");
    const format = "YYYY-MM-DDTHH:mm:ss.SSS";
    const formattedDate = moment(originalDate).format(format);
    return {
      balanceOwnerStatus: this.balanceOwnerStatus,
      threshold: Number(this.threshold),
      orderColumn: this.sort.field || null,
      orderDirection: this.sort.direction || null,
      date: formattedDate,
    };
  }
}
