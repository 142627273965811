import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";
import { RouteRecordRaw } from "vue-router";
import { constantRoutes, asyncRoutes } from "@/router/index";

const hasPermission = (roles: string[], route: RouteRecordRaw) => {
  if (route.meta?.roles) {
    return roles.some((role) => (route.meta?.roles as string[]).includes(role));
  } else {
    return true;
  }
};

export const filterAsyncRoutes = (
  routes: RouteRecordRaw[],
  roles: string[]
) => {
  const res: RouteRecordRaw[] = [];
  routes.forEach((route) => {
    const r = { ...route };
    if (hasPermission(roles, r)) {
      if (r.children) {
        r.children = filterAsyncRoutes(r.children, roles);
      }
      res.push(r);
    }
  });
  return res;
};

export interface IPermissionState {
  routes: Array<RouteRecordRaw>;
  dynamicRoutes: RouteRecordRaw[];
}

@Module({ dynamic: true, store, name: "permission", namespaced: true })
class Permission extends VuexModule implements IPermissionState {
  public routes: Array<RouteRecordRaw> = [];
  public dynamicRoutes: RouteRecordRaw[] = [];

  @Mutation
  private SET_ROUTES(routes: Array<RouteRecordRaw>) {
    this.routes = constantRoutes.concat(routes);
    this.dynamicRoutes = routes;
  }

  @Action
  public GenerateRoutes(roles: string[]) {
    let accessedRoutes;
    if (roles.includes("admin")) {
      accessedRoutes = asyncRoutes;
    } else {
      accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
    }
    this.SET_ROUTES(accessedRoutes);
  }
}

export const PermissionModule = getModule(Permission);
