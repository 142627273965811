
import { Options, Vue } from "vue-class-component";
import { UserModule } from "@/store/modules/user";
import ConfirmModal from "@/components/confirmPopup/index.vue";

@Options({ name: "header-layout", components: { ConfirmModal } })
export default class Header extends Vue {
  isModalOpen = false;

  handleOpenModal() {
    this.isModalOpen = true;
  }

  handleCloseModal() {
    this.isModalOpen = false;
  }

  handleLogout() {
    UserModule.Logout();
    this.handleCloseModal();
    this.$router.push("/signin");
  }
}
