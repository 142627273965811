import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "sidebar position-relative",
  ref: "aside"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarElement = _resolveComponent("SidebarElement")!

  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleDashboardLinks, (route, index) => {
      return (_openBlock(), _createBlock(_component_SidebarElement, {
        key: index,
        icon: route.icon,
        path: route.path
      }, null, 8, ["icon", "path"]))
    }), 128))
  ], 512))
}