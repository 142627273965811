import swal from "sweetalert2";
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from "vuex-module-decorators";
import store from "@/store";
import {
  markAsPaidRequest,
  getAccountingBalancesRequest,
  makePayout,
  getPayoutsDates,
} from "@/api/accounting";

export interface IManualPayoutsState {
  list: any[];
  isFetching: boolean;
}

@Module({ dynamic: true, store, name: "manual-payouts", namespaced: true })
class ManualPayouts extends VuexModule implements IManualPayoutsState {
  public list: any[] = [];
  public isFetching = true;

  @Mutation
  private SET_LIST(list: any) {
    this.list = list;
  }

  @Mutation
  private SET_FETCHING(value: boolean) {
    this.isFetching = value;
  }

  @Mutation
  private MARK_AS_PAYED(id: number) {
    this.list = this.list.map((item: any) => {
      if (item.id === id) {
        item.payed = true;
      }

      return item;
    });
  }

  @Action
  public async getAccountingBalances(params: any) {
    try {
      swal.showLoading();
      this.SET_FETCHING(true);
      this.SET_LIST([]);
      const { data } = await getAccountingBalancesRequest(params);
      if (data) {
        this.SET_LIST(
          data.map((item: any, index: number) => ({
            ...item,
            id: new Date().getMilliseconds() + index,
            payed: false,
          }))
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.SET_FETCHING(false);
      swal.close();
    }
  }

  @Action
  public async markAsPaid({ data, id }: { data: any; id: number }) {
    try {
      await markAsPaidRequest(data);
      this.MARK_AS_PAYED(id);
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  public clearList() {
    this.SET_LIST([]);
  }

  @Action
  public async makePayouts({ data }: { data: any }) {
    const { userId } = data;
    const { date } = data;
    swal.showLoading();
    await makePayout({ userId, date })
      .then(() => {
        swal.close();
      })
      .catch((err) => {
        swal.fire({
          title: "Error",
          text: err.message,
          icon: "error",
        });
      });
  }

  @Action
  public async GetPayoutsDates(userId: number) {
    swal.showLoading();
    let data;

    try {
      const response = await getPayoutsDates(userId);
      data = response.data;
    } catch (e) {
      console.error(e);
      await swal.fire({
        icon: "error",
        text: "Failed to get payouts for the user",
      });
      return null;
    } finally {
      swal.close();
    }

    return data;
  }
}

export const ManualPayoutsModule = getModule(ManualPayouts);
