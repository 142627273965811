
import { ref, toRaw } from "vue";
import { Watch } from "vue-property-decorator";
import {
  PlaylistModel,
  PlaylistModule,
  RawTrackModel,
  TrackModel,
  PerformerModel,
} from "@/store/modules/playlist";
import get from "lodash/get";
import isString from "lodash/isString";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { inviteProducer } from "@/api/performers";
import VueMultiselect from "vue-multiselect";
import InviteModal from "@/components/inviteModal/index.vue";
import ArtistModal from "@/components/playlist/artistModal/index.vue";
import PlaylistFieldEditModal from "@/components/playlist/playlistFieldEditModal/index.vue";
import MultiPromoterEditModal from "@/components/playlist/multiPromoterEditModal/index.vue";
import FindTrackPopup from "@/components/playlist/findTrackModal/index.vue";
import ConfirmPopup from "@/components/confirmPopup/index.vue";

export interface IPromoter {
  id: number | null;
  name: string;
}
@Options({
  name: "playlistTable",
  components: {
    InviteModal,
    ArtistModal,
    PlaylistFieldEditModal,
    MultiPromoterEditModal,
    ConfirmPopup,
    VueMultiselect,
    FindTrackPopup,
  },
})
export default class PlaylistTable extends Vue {
  @Prop({ required: true }) public list!: any[];
  @Prop({ require: true }) private searchTracks!: any[];
  @Prop({ require: true }) public searchTracksPages!: number;
  @Prop({ required: true }) public trackId!: number | null;
  @Prop({ required: true }) private pageMultiselect!: number | null;

  typingTimer!: any;

  name: string | null = null;

  selectedTrack2Alias: TrackModel | null = null;
  selectedPlaylist: PlaylistModel | null = null;
  PlaylistVersionId: string | null = null;

  aliasQuery: string | null = null;
  aliasPage: number | null = null;
  aliasPopupOpen = false;

  sort = {
    field: "",
    direction: "",
  };

  currentType = "";
  currentArtistName = "";
  confirmPopupTitle = "";
  currentTrackId: null | number = null;
  currentPlayListId: null | number = null;
  isConfirmPopupOpen = false;
  isArtistModalOpen = false;
  isVenueEditModalOpen = false;
  isPromoterEditModalOpen = false;
  isOpenInvite = false;
  warningTwoSpacesPopupOpen = false;
  warningTwoSpacesPopupOpenRemixer = false;
  successfulSendingAnInvitation = false;
  ClipboardModal = false;
  currentArtist: {
    id: number;
    name: string;
    url: string;
    status: string;
    playlistId: number;
    trackId: number;
  } | null = null;

  currentVenue: {
    id: number;
    value: string;
    versionId: string;
  } | null = null;

  playlistPromotersCurrentInfo: {
    id: number;
    promotionCompanies: IPromoter[];
    versionId: string;
  } | null = null;

  editablePlaylist: {
    id: number;
    name: string;
    venue: string;
    versionId: string;
  } | null = null;
  isPlaylistEditConfirmPopupOpen = false;
  get = get;

  DJ = "";
  newArrTracks: any[] = [];
  arrDj: string[] = [];
  arrProduser: string[] = [];
  arrProduserId: number[] = [];
  textDescriptionInvite = "";
  textTitleInvete = "";
  nameOptionArtist = "";
  nameOptionProd = "";
  producerId = 0;
  nameTrack = "";
  emailProducer = "";
  validateEmail = false;
  validateProducer = false;
  validateDj = false;
  disabledInvite = true;
  pattern = /^\S+@[a-zA-Z_]+?\.[a-zA-Z]{2,4}$/;

  invitationData: {
    email: string;
    djName: string;
    producerName: string;
    producerId: number;
  } | null = null;
  copyInvitationText() {
    const inviteTextNode: any = this.$refs["copyText"];
    const range = document.createRange();
    range.selectNode(inviteTextNode);
    window.getSelection()?.removeAllRanges(); // clear current selection
    window.getSelection()?.addRange(range); // to select text
    document.execCommand("copy");
    window.getSelection()?.removeAllRanges();
    this.ClipboardModal = true;
  }
  closeClipboardModal() {
    this.ClipboardModal = false;
  }
  openInviteToModal(itemDj: any, itemProd: any) {
    this.nameOptionArtist = "Artist name";
    this.nameOptionProd = "DJ name";
    this.nameTrack = itemDj.name;
    this.producerId = itemProd.id;
    for (const prop in itemDj.performersObjects) {
      this.arrProduser.push(itemDj.performersObjects[prop].name);
      this.arrProduserId.push(itemDj.performersObjects[prop].id);
    }
    itemProd.user.performers.forEach((element: any) => {
      this.arrDj.push(element.name);
    });
    this.isOpenInvite = true;
  }

  get label() {
    if (
      this.emailProducer.match(this.pattern) &&
      this.arrProduser.some((arrVal) => this.nameOptionArtist === arrVal) &&
      this.arrDj.some((arrVal) => this.nameOptionProd === arrVal)
    ) {
      return (this.disabledInvite = false);
    }
    return (this.disabledInvite = true);
  }
  get performers(): PerformerModel[] {
    return PlaylistModule.performers;
  }

  async sendingInvitation() {
    const inxProd = this.arrProduser.indexOf(this.nameOptionArtist);
    const inxProdId = this.arrProduserId[inxProd];
    this.invitationData = {
      email: this.emailProducer,
      djName: this.nameOptionProd,
      producerName: this.nameOptionArtist,
      producerId: inxProdId,
    };
    const request = {
      ...this.invitationData,
      trackTitle: this.nameTrack,
    };
    try {
      await inviteProducer(request);
      this.textTitleInvete = "Invite send";
      this.textDescriptionInvite = "An email has been sent to the Producer.";
    } catch (e: any) {
      this.textDescriptionInvite = e.message;
      this.textTitleInvete = "The email has not been sent";
    }
    this.closeInviteModal();
    this.successfulSendingAnInvitation = true;
  }
  closeInviteModal() {
    this.nameOptionArtist = "Producer name";
    this.arrDj.length = this.arrProduser.length = this.arrProduserId.length = 0;
    this.isOpenInvite = false;
  }
  closeSendingInviteModal() {
    this.successfulSendingAnInvitation = false;
  }
  handleOpenPlaylistEditConfirmModal() {
    this.isPlaylistEditConfirmPopupOpen = true;
  }

  handleClosePlaylistEditConfirmModal() {
    this.editablePlaylist = null;
    this.isPlaylistEditConfirmPopupOpen = false;
  }

  async handleSavePlaylist() {
    if (this.editablePlaylist) {
      await PlaylistModule.updatePlaylist({
        id: this.editablePlaylist.id,
        data: {
          name: this.editablePlaylist.name,
          venue: this.editablePlaylist.venue,
          versionId: this.editablePlaylist.versionId,
        },
      });
      this.handleClosePlaylistEditConfirmModal();
    }
  }

  handleEditPlaylist(playlist: PlaylistModel) {
    this.editablePlaylist = {
      id: playlist.id,
      name: playlist.name,
      venue: playlist.venue,
      versionId: playlist.versionId,
    };
  }

  handleOpenEditVenuePopup(
    event: Event,
    id: number,
    value: string,
    versionId: string
  ) {
    event.stopPropagation();
    this.isVenueEditModalOpen = true;
    this.currentVenue = { id, value, versionId };
  }

  handleCloseVenueEditModal() {
    this.isVenueEditModalOpen = false;
    this.currentVenue = null;
  }

  async handleUpdateVenue(data: { value: string }) {
    if (this.currentVenue) {
      const venue = data.value === "" ? null : data.value;
      await PlaylistModule.updatePlaylist({
        id: this.currentVenue.id,
        data: {
          venue: venue,
          versionId: this.currentVenue.versionId,
        },
      });
    }
    this.handleCloseVenueEditModal();
  }

  handleOpenEditPromoterPopup(
    event: Event,
    id: number,
    promotionCompanies: IPromoter[],
    versionId: string
  ) {
    event.stopPropagation();
    this.isPromoterEditModalOpen = true;
    this.playlistPromotersCurrentInfo = { id, promotionCompanies, versionId };
  }

  handleClosePromoterEditModal() {
    this.isPromoterEditModalOpen = false;
    this.playlistPromotersCurrentInfo = null;
  }

  async handleUpdatePromoter(updatedPromoters: IPromoter[]) {
    if (
      !this.playlistPromotersCurrentInfo ||
      !this.playlistPromotersCurrentInfo.promotionCompanies
    )
      return;
    let isPromotersUpdated = false;
    const originalPromoterCount =
      this.playlistPromotersCurrentInfo.promotionCompanies.length;
    const trimmedPromoters = updatedPromoters
      .map((promoter) => ({ ...promoter, name: promoter.name.trim() }))
      .filter((promoter) => promoter.name !== "");
    const uniquePromotersMap = new Map();
    trimmedPromoters.forEach((promoter) => {
      const lowerCaseName = promoter.name.toLowerCase();
      if (!uniquePromotersMap.has(lowerCaseName)) {
        uniquePromotersMap.set(lowerCaseName, promoter);
      }
    });
    const filteredUpdatedPromoters = Array.from(uniquePromotersMap.values());
    const updates = filteredUpdatedPromoters.map(async (updatedPromoter) => {
      if (!updatedPromoter) return;
      const originalPromoter = updatedPromoter.id
        ? this.playlistPromotersCurrentInfo?.promotionCompanies.find(
            (promoter) => promoter.id === updatedPromoter.id
          )
        : null;
      if (!originalPromoter) {
        // Handle new promoters (no original found and no ID)
        if (updatedPromoter.id === null) isPromotersUpdated = true;
        return;
      }
      // Check if the name change is only in case sensitivity
      if (
        updatedPromoter.name.toLowerCase() ===
          originalPromoter.name.toLowerCase() &&
        updatedPromoter.name !== originalPromoter.name
      ) {
        isPromotersUpdated = true;
        // Update the promoter if the case of the name has changed
        return PlaylistModule.updateVenue({
          venueId: updatedPromoter.id,
          data: { name: updatedPromoter.name },
        });
      } else if (
        updatedPromoter.name.toLowerCase() !==
        originalPromoter.name.toLowerCase()
      ) {
        isPromotersUpdated = true;
      }
    });
    await Promise.all(updates);

    // Check if the number of promoters has changed, indicating additions or deletions
    if (filteredUpdatedPromoters.length !== originalPromoterCount) {
      isPromotersUpdated = true;
    }

    // Perform final playlist update if any changes have been detected
    if (isPromotersUpdated) {
      if (filteredUpdatedPromoters.length > 0) {
        // Update the playlist with all current promoter names
        await PlaylistModule.updatePlaylist({
          id: this.playlistPromotersCurrentInfo.id,
          data: {
            promotionCompanies: filteredUpdatedPromoters.map(
              (promoter) => promoter.name
            ),
            versionId: this.playlistPromotersCurrentInfo.versionId,
          },
        });
      } else {
        // Clear all promoters from the playlist if no names were entered
        await PlaylistModule.updatePlaylist({
          id: this.playlistPromotersCurrentInfo.id,
          data: {
            promotionCompanies: [],
            versionId: this.playlistPromotersCurrentInfo.versionId,
          },
        });
      }
    }

    this.handleClosePromoterEditModal();
  }

  get filteredSearchTracks(): TrackModel[] {
    return this.searchTracks?.filter(
      (track) => track.id !== this.selectedTrack2Alias?.id
    );
  }

  handleOpenArtistLink(url: string) {
    if (url) {
      window.open(url, "_blank");
    }
  }

  handleOpenUserPage(id: number) {
    const routePath = this.$router.resolve({
      name: "Users",
      query: { userId: id },
    }).href;
    window.open(routePath, "_blank");
  }

  handleOpenEditArtistPopup(
    event: Event,
    id: number,
    name: string,
    url: string,
    status: string,
    playlistId: number,
    trackId: number
  ) {
    event.stopPropagation();
    this.isArtistModalOpen = true;
    this.currentArtist = { id, name, url, status, playlistId, trackId };
  }

  handleCloseArtistModal() {
    this.isArtistModalOpen = false;
    this.currentArtist = null;
  }

  async handleUpdateArtist(data: {
    name: string;
    url: string;
    unmatchable: boolean;
  }) {
    const { unmatchable, ...others } = data;
    const searchTrackId: any = this.currentArtist;

    if (this.currentArtist) {
      await PlaylistModule.updatePerformer({
        id: this.currentArtist.id,
        playlistId: this.currentArtist.playlistId,
        trackId: this.currentArtist.trackId,
        ...others,
      });
      try {
        await PlaylistModule.changePerformerStatus({
          id: this.currentArtist.id,
          trackId: this.currentArtist.trackId,
          playlistId: this.currentArtist.playlistId,
          status: unmatchable ? "charity" : "pending",
        });
        this.newArrTracks.map((el: { id: any }) => {
          if (el.id === searchTrackId.trackId) {
            const track: any = toRaw(el);
            track.performersObjects.map(
              (artist: { id: any; url: any; status: string }) => {
                if (artist.id === searchTrackId.id) {
                  artist.status = unmatchable ? "charity" : "pending";
                }
              }
            );
            track.remixersObjects.map(
              (artist: { id: any; url: any; status: string }) => {
                if (artist.id === searchTrackId.id) {
                  artist.status = unmatchable ? "charity" : "pending";
                }
              }
            );
          }
        });
      } catch (e: any) {
        this.handleCloseArtistModal();
        return;
      }
    }

    this.newArrTracks.map((el: { id: any }) => {
      if (el.id === searchTrackId.trackId) {
        const track: any = toRaw(el);
        track.performersObjects.map(
          (artist: { id: any; url: any; status: string }) => {
            if (artist.id === searchTrackId.id) {
              artist.url = others.url;
            }
          }
        );
        track.remixersObjects.map(
          (artist: { id: any; url: any; status: string }) => {
            if (artist.id === searchTrackId.id) {
              artist.url = others.url;
            }
          }
        );
      }
    });
    this.handleCloseArtistModal();
  }

  handleAddElement(
    name: string,
    row: { type: string; playlistId: number; trackId: number }
  ) {
    this.currentType = row.type;
    this.currentArtistName = name;
    this.currentTrackId = row.trackId;
    this.currentPlayListId = row.playlistId;
    this.confirmPopupTitle = `Are you sure you want to add ${name} new ${row.type} to database`;
    this.isConfirmPopupOpen = true;
  }

  handleCloseConfirmPopup() {
    this.currentType = "";
    this.currentTrackId = null;
    this.currentPlayListId = null;
    this.currentArtistName = "";
    this.confirmPopupTitle = "";
    this.isConfirmPopupOpen = false;
  }

  async handleSuccessConfirmPopup() {
    await PlaylistModule.createPerformer({ name: this.currentArtistName });
    if (this.currentTrackId && this.currentPlayListId) {
      const data: any = {
        trackId: this.currentTrackId,
        playlistId: this.currentPlayListId,
        [this.currentType === "remixer" ? "remixer" : "performer"]:
          this.currentArtistName,
      };
      PlaylistModule[
        this.currentType === "remixer" ? "setTrackRemixer" : "setTrackPerformer"
      ](data);
    }
    this.handleCloseConfirmPopup();
  }
  async handleOpenTracks(
    playlist: PlaylistModel,
    index: number
  ): Promise<void> {
    this.newArrTracks = [];
    const { id, isOpen, status } = playlist;
    const newTrack = await PlaylistModule.setShowTracks({
      id,
      status: !isOpen,
    });
    newTrack?.rawTracks.map(
      (el: {
        trackId: number;
        id: number;
        parentId: number;
        album: any;
        artist: any;
        title: any;
        bpm: number;
        genre: any;
        hidden: boolean;
        time: any;
      }) => {
        if (el.trackId) {
          for (const obj1 of newTrack.tracks) {
            if (el.trackId === obj1.id) {
              const newItem = { ...obj1 };
              newItem.parentId = newItem.id;
              newItem.id = el.id;
              newItem.album = el.album;
              newItem.artist = el.artist;
              newItem.title = el.title;
              newItem.bpm = el.bpm;
              newItem.genre = el.genre;
              newItem.hidden = el.hidden;
              newItem.time = el.time;
              this.newArrTracks.push(newItem);
            }
          }
        }
      }
    );
    if (!isOpen && status) {
      PlaylistModule.getPlaylistStatistics(id);
    }
    const row = index + 1;
    setTimeout(() => {
      if (document.getElementById(`collapse-${index}`)) {
        if (row > 15) {
          window.scrollTo({ top: row * 10, behavior: "smooth" });
        }
        if (row > 10) {
          window.scrollTo({ top: row * 20, behavior: "smooth" });
        }
        if (row > 5) {
          window.scrollTo({ top: row * 30, behavior: "smooth" });
        } else window.scrollTo({ top: row * 40, behavior: "smooth" });
      }
    }, 200);
  }
  handleOnInputClick(
    event: MouseEvent,
    row: { playlistId: number; track: TrackModel }
  ): void {
    if (row.track.isEdit) {
      return;
    }
    PlaylistModule.setEditTrack({
      trackId: row.track.id,
      playlistId: row.playlistId,
      isEdit: !row.track.isEdit,
    });
    setTimeout(() => {
      (event.target as HTMLElement).closest("input")?.focus();
    }, 0);
  }
  copyTrackAsUntouched(track: any): void {
    PlaylistModule.setUntouchedTrack(track);
  }
  handleCopyToClipboard(playlistId: string, trackId: number): void {
    const foundPlaylist = this.list.find((x) => x.id == playlistId);
    let artist = "";
    let name = "";
    const foundTrack = foundPlaylist.rawTracks.find(
      (x: RawTrackModel) => x.trackId == trackId
    );
    if (foundTrack) {
      artist = foundTrack.artist;
      name = foundTrack.title;
    }
    // }
    artist = isString(artist) ? artist.replaceAll(/[&_#]/g, " ") : "";
    name = isString(name) ? name.replaceAll(/[&_#]/g, " ") : "";
    window.open(`https://google.com/search?q=${artist}+${name}`, "_blank");
  }

  handleSearch(name: string): void {
    clearTimeout(this.typingTimer);
    this.typingTimer = window.setTimeout(() => {
      if (this.name !== name) {
        this.name = name || null;
        PlaylistModule.fetchPerformers({
          name,
          type: "all",
          page: 1,
          take: this.pageMultiselect,
        });
      }
    }, 200);
  }

  handleSelectPerformer(
    performerName: string,
    row: { playlistId: number; trackId: number }
  ): void {
    // PlaylistModule.setTrackPerformer(payload);
    this.newArrTracks.map((el: { parentId: any; performers: any }) => {
      if (el.parentId === row.trackId) {
        el.performers.push(performerName);
      }
    });
  }
  handleSelectRemixer(
    remixerName: string,
    row: { playlistId: number; trackId: number }
  ): void {
    // PlaylistModule.setTrackRemixer(payload);
    this.newArrTracks.map((el: { parentId: any; remixers: any }) => {
      if (el.parentId === row.trackId) {
        el.remixers.push(remixerName);
      }
    });
  }

  handleRemovePerformer(
    performerName: string,
    row: { playlistId: number; trackId: number }
  ): void {
    this.newArrTracks.map((el: { performers: any; parentId: number }) => {
      if (el.parentId === row.trackId) {
        el.performers.splice(
          el.performers.findIndex((x: any) => x == performerName),
          1
        );
      }
    });
  }

  handleRemoveRemixer(
    remixer: string,
    row: { playlistId: number; trackId: number; parentId: number }
  ): void {
    this.newArrTracks.map((el: { remixers: any; parentId: number }) => {
      if (el.parentId === row.parentId) {
        el.remixers.splice(
          el.remixers.findIndex((x: any) => x == remixer),
          1
        );
      }
    });
    // PlaylistModule.removeRemixerFromTrack(payload);
  }
  handleApprovePlaylist(item: PlaylistModel): void {
    PlaylistModule.approvePlaylist({ id: item.id, versionId: item.versionId });
  }

  async handleApproveTrack(plId: number, item: TrackModel): Promise<void> {
    const editStatus = await PlaylistModule.approveTrack({
      playlistId: plId,
      id: item.parentId,
      versionId: item.versionId,
    });

    if (!editStatus) {
      return;
    }

    this.newArrTracks.map(
      (el: {
        parentId: number;
        status: boolean;
        versionId: any;
        collationStatus: string;
      }) => {
        if (el.parentId === item.parentId) {
          el.status = !el.status;
          el.collationStatus = editStatus.collationStatus;
          el.versionId = editStatus.versionId;
        }
      }
    );
  }

  handleOpenAliasPopup(track: TrackModel, playlist: PlaylistModel): void {
    this.selectedTrack2Alias = track;
    this.selectedPlaylist = playlist;
    this.aliasPopupOpen = true;
  }
  cancelEditingTrack(plId: number, trId: number) {
    this.newArrTracks.map((el: { id: number; isEdit: boolean }) => {
      if (el.id === trId) {
        el.isEdit = false;
      }
    });
    PlaylistModule.canelEditingTrack({ playlistId: plId, trackId: trId });
  }
  async handleAliasAndEditTrack(
    event: MouseEvent,
    track: TrackModel,
    playlist: PlaylistModel,
    actionType: string
  ): Promise<void> {
    if (actionType == "submit") {
      if (!track || !playlist) {
        return;
      }

      const exs = await PlaylistModule.updateTrackPlaylists({
        playlistId: playlist.id,
        track: {
          ...track,
          link: track.link || null,
        },
      });
      const user: any = { ...track };
      this.newArrTracks.map(
        (el: {
          title: any;
          performersObjects: [];
          remixersObjects: [];
          isEdit: boolean;
          versionId: any;
          id: number;
        }) => {
          if (el.title === user.title && el.id === user.id) {
            el.performersObjects = exs.performers;
            el.remixersObjects = exs.remixers;
            el.versionId = exs.versionId;
            el.isEdit = false;
          }
          return;
        }
      );
      setTimeout(() => {
        (event.target as HTMLElement).closest("input")?.focus();
      }, 0);
    } else {
      const user: any = { ...track };
      this.newArrTracks.map((el: { id: any; isEdit: boolean }) => {
        if (el.id === user.id) {
          el.isEdit = true;
        }
      });
      this.copyTrackAsUntouched(track);
      await PlaylistModule.setEditTrack({
        trackId: track.parentId,
        playlistId: playlist.id,
        isEdit: !track.isEdit,
      });
    }
  }

  async handleAliasLinkTrack(track: TrackModel): Promise<void> {
    if (!this.selectedTrack2Alias || !this.selectedPlaylist || !track) {
      return;
    }
    const Playlist = await PlaylistModule.setShowTracks({
      id: this.selectedPlaylist.id,
      status: true,
    });
    const editPlaylist = await PlaylistModule.updateTrackInPlaylistNewF({
      id: this.selectedPlaylist.id,
      payload: {
        rawTrackId: this.selectedTrack2Alias.id,
        replaceWithId: track.id,
        versionId: Playlist.versionId,
        hidden: this.selectedTrack2Alias.hidden,
      },
    });

    if (!editPlaylist) {
      return;
    }

    this.PlaylistVersionId = editPlaylist.versionId;
    const aliasId = this.selectedTrack2Alias.id;
    if (this.selectedTrack2Alias.id && editPlaylist) {
      this.newArrTracks.map(
        (el: {
          name: any;
          id: number;
          performers: [];
          isEdit: boolean;
          versionId: any;
        }) => {
          if (el.id === aliasId) {
            el.name = track.performers[0].name;
            el.isEdit = false;
          }
        }
      );
    }
    this.newArrTracks = [];
    const newTrack = await PlaylistModule.setShowTracks({
      id: this.selectedPlaylist.id,
      status: true,
    });
    newTrack.rawTracks.map(
      (el: {
        trackId: number;
        id: number;
        parentId: number;
        album: any;
        artist: any;
        title: any;
        bpm: number;
        genre: any;
        hidden: boolean;
        time: any;
      }) => {
        if (el.trackId) {
          for (const obj1 of newTrack.tracks) {
            if (el.trackId === obj1.id) {
              const newItem = { ...obj1 };
              newItem.parentId = newItem.id;
              newItem.id = el.id;
              newItem.album = el.album;
              newItem.artist = el.artist;
              newItem.title = el.title;
              newItem.bpm = el.bpm;
              newItem.genre = el.genre;
              newItem.hidden = el.hidden;
              newItem.time = el.time;
              this.newArrTracks.push(newItem);
            }
          }
        }
      }
    );
    this.handleCancelAliasPopup();
  }
  getCurrency(currencyName: string | null, amount: string | null): string {
    let currency = "$";
    let totalAmount = "0.00";
    if (currencyName) {
      if (currencyName == "EUR") {
        currency = "€";
      }
      if (currencyName == "GBP") {
        currency = "£";
      }
    }
    if (amount) {
      totalAmount = amount;
    }
    currency = currency + totalAmount;
    return currency;
  }
  async handleAliasSearchChanged(query: string, page: number): Promise<void> {
    clearTimeout(this.typingTimer);
    this.typingTimer = window.setTimeout(() => {
      if (this.aliasQuery !== query || this.aliasPage !== page) {
        this.aliasQuery = query || null;
        this.aliasPage = page;
        PlaylistModule.fetchSearchTrackList({ query, page });
      }
    }, 200);
  }
  findTrackOldData(
    playlistId: number,
    trackId: number,
    returnType: string
  ): string {
    let artist = "";
    let title = "";
    const foundPL = this.list.find((x) => x.id == playlistId);
    if (foundPL) {
      const foundT = foundPL.rawTracks.find(
        (x: RawTrackModel) => x.trackId == trackId
      );
      if (foundT) {
        if (returnType == "artist") {
          artist = foundT.artist;
        } else {
          title = foundT.title;
        }
      }
    }
    return returnType == "artist" ? artist : title;
  }

  handleCancelAliasPopup(): void {
    this.aliasPopupOpen = false;
    this.selectedTrack2Alias = null;
    this.selectedPlaylist = null;
  }

  disabledApprove(item: PlaylistModel): boolean {
    return item.tracks.length
      ? !item.tracks.every((elem: TrackModel) => this.trackApproved(elem))
      : true;
  }
  unMutchTrack(plId: number, track: TrackModel): void {
    const payload = {
      playlistId: plId,
      trackId: track.parentId,
      versionId: track.versionId,
    };
    PlaylistModule.trackUnmatching(payload);
  }

  trackApproveDisabled(item: TrackModel): boolean {
    let approved = false;
    if (item.status) {
      approved = true;
    }
    return approved;
  }

  trackApproved(item: TrackModel): boolean {
    return item.status || item.collationStatus === "collated";
  }

  getCurrentDirection(): string {
    if (this.sort.direction === "ASC") {
      return "DESC";
    }
    if (this.sort.direction === "DESC") {
      return "";
    }

    return "ASC";
  }

  handleSortChange(field: string) {
    if (field === this.sort.field) {
      this.sort.direction = this.getCurrentDirection();
    } else {
      this.sort.field = field;
      this.sort.direction = "ASC";
    }
    this.$emit("onSortChange", this.sort);
  }

  getCurrentFieldDirection(field: string): string {
    if (field === this.sort.field) {
      if (this.sort.direction === "ASC") {
        return "down";
      }
      if (this.sort.direction === "DESC") {
        return "up";
      }
      return "";
    }

    return "";
  }

  declare $refs: {
    multiselect: any;
    remixerselect: any;
    input: string;
    copyText: any;
  };
  inputMilti: any = "start";
  warningTwoSpacesPopupClose() {
    this.warningTwoSpacesPopupOpen = false;
    this.$refs.multiselect.$el.focus();
  }
  warningRemixerTwoSpacesPopupClose() {
    this.warningTwoSpacesPopupOpenRemixer = false;
    this.$refs.remixerselect.$el.focus();
  }
  showMe(event: any) {
    const str = event.target.value;
    if (/\s{2}/.test(str)) {
      event.target.closest(".artist") !== null
        ? (this.warningTwoSpacesPopupOpen = true)
        : (this.warningTwoSpacesPopupOpenRemixer = true);
    }
  }
  @Watch("pageMultiselect")
  resPage() {
    console.log(this.pageMultiselect);
  }
}
