import swal from "sweetalert2";
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from "vuex-module-decorators";
import * as Sentry from "@sentry/vue";
import { getToken, setToken, removeToken, removeUser } from "@/utils/cookies";
import store from "@/store";
import { notify } from "@kyvg/vue3-notification";
import {
  login,
  fetchUsers,
  fetchUser,
  verifyUser,
  updateUserData,
  updateUserSocials,
  deleteUserRequest,
  fetchUserStripeData,
  getUserChangeLogRequest,
  grabDataFromDiscogsRequest,
  approveAlias,
  approveVenue,
  updateUserRequest,
  inviteVipUserRequest,
  updateUserReviewStatusRequest,
  getPaymentMethod,
  fetchCurrentUser,
  logout,
} from "@/api/user";
import { updatePerformerRequest } from "@/api/performers";
import { checkCanBeGroupApprovedRequest } from "@/api/groups";
import { REVIEW_STATUSES } from "@/utils/constants";
import { socialsScrapping } from "@/api/scrapping";
import { ref, toRaw } from "vue";

export interface IUserState {
  users: UserModel[];
  userData: UserModel | null;
  page: number;
  pages: number;
  take: number;
}
export class UserCSVExportModel {
  id: number;
  asliceId: string;
  fullname: string;
  email: string;
  created: string;
  role: string;

  constructor() {
    this.id = 0;
    this.asliceId = "";
    this.fullname = "";
    this.email = "";
    this.created = "";
    this.role = "";
  }
}

export interface GroupMemberModel {
  id: number;
  name: string;
}

export interface PerformerModel {
  alias: GroupMemberModel[];
  groupMembers: any[];
  id: number;
  name: string;
}

export class PerformerModel {
  alias: GroupMemberModel[];
  groupMembers: any[];
  id: number;
  name: string;

  constructor() {
    this.alias = [];
    this.groupMembers = [];
    this.id = 0;
    this.name = "";
  }
}

export class StripeData {
  document: StripeDocument;
  selfie: StripeSelfie;
  constructor() {
    this.document = new StripeDocument();
    this.selfie = new StripeSelfie();
  }
}

export class StripeDocument {
  address: StripeDocumentAddress;
  error: any;
  files: any;
  firstName: string;
  issuedDate: StripeIssuedDate;
  issuingCountry: string;
  lastName: string;
  number: string;
  status: string;
  type: string;
  constructor() {
    this.address = new StripeDocumentAddress();
    this.error = "";
    this.files = "";
    this.firstName = "";
    this.issuedDate = new StripeIssuedDate();
    this.issuingCountry = "";
    this.lastName = "";
    this.number = "";
    this.status = "";
    this.type = "";
  }
}

export class StripeIssuedDate {
  day: number;
  month: number;
  year: number;
  constructor() {
    this.day = 0;
    this.month = 0;
    this.year = 0;
  }
}

export class StripeSelfie {
  document: StripeSelfieDocument;
  selfie: StripeSelfieFile;
  constructor() {
    this.document = new StripeSelfieDocument();
    this.selfie = new StripeSelfieFile();
  }
}

export class StripeSelfieDocument {
  created: number;
  expired: boolean;
  expires_at: number;
  file: string;
  id: string;
  livemode: false;
  metadata: any;
  object: string;
  url: string;
  constructor() {
    this.created = 0;
    this.expired = false;
    this.expires_at = 0;
    this.file = "";
    this.id = "";
    this.livemode = false;
    this.metadata = {};
    this.object = "";
    this.url = "";
  }
}

export class StripeSelfieFile {
  created: number;
  expired: boolean;
  expires_at: number;
  file: string;
  id: string;
  livemode: false;
  metadata: any;
  object: string;
  url: string;
  constructor() {
    this.created = 0;
    this.expired = false;
    this.expires_at = 0;
    this.file = "";
    this.id = "";
    this.livemode = false;
    this.metadata = {};
    this.object = "";
    this.url = "";
  }
}
export class StripeDocumentAddress {
  city: string;
  country: string;
  line1: string;
  line2: string;
  postalCode: string;
  state: string;
  constructor() {
    this.city = "";
    this.country = "";
    this.line1 = "";
    this.line2 = "";
    this.postalCode = "";
    this.state = "";
  }
}

export class UserModelData {
  id: number;
  asliceId: string;
  firstName: string;
  lastName: string;
  fullname: string;
  email: string;
  avatar: string;
  notes: string;
  created: string;
  roles: UserRole[];
  emailVerified: boolean;
  verificationStatus: string;
  publisherEmail: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  socials: UserSocial[];
  country: string;
  postalCode: string;
  paypalEmail: string;
  paypalPayerId: string;
  performers: any;
  venues: any;
  groups: PerformerModel[];
  groupMemberships: Array<any>;
  stripeCustomerId: string;
  stripePaymentMethodId: string;
  stripeAccountId: string;
  isReadyForStripePayouts: boolean;
  deleted: boolean;
  reasonForDeletion: string;
  band: string;
  tracksDisplayingPreference: string;
  playlistsDisplayingPreference: string;
  verified: boolean;
  preferredCurrency: string;
  isOpen: boolean;
  isProfileOpen: boolean;
  isPresenceOpen: boolean;
  isStripeOpen: boolean;
  isChangeLogOpen: boolean;
  isActivityOpen: boolean;
  isEmailLogOpen: boolean;
  changeLog: any[];
  stripeData: any;
  mappedGroups: any[];
  mappedPerformers: Array<string>;
  mappedVenues: Array<string>;
  changed: boolean;

  constructor() {
    this.id = 0;
    this.asliceId = "";
    this.firstName = "";
    this.lastName = "";
    this.fullname = "";
    this.email = "";
    this.avatar = "";
    this.notes = "";
    this.created = "";
    this.roles = [];
    this.emailVerified = false;
    this.verificationStatus = "";
    this.publisherEmail = "";
    this.addressLine1 = "";
    this.addressLine2 = "";
    this.city = "";
    this.state = "";
    this.socials = [
      { type: "Website", link: "" },
      { type: "Facebook", link: "" },
      { type: "Discogs", link: "" },
      { type: "Resident advisor", link: "" },
      { type: "Soundcloud", link: "" },
      { type: "Online retailer", link: "" },
      { type: "Instagram", link: "" },
    ];
    this.country = "";
    this.postalCode = "";
    this.paypalEmail = "";
    this.paypalPayerId = "";
    this.performers = [];
    this.venues = [];
    this.groups = [];
    this.stripeCustomerId = "";
    this.stripePaymentMethodId = "";
    this.stripeAccountId = "";
    this.isReadyForStripePayouts = false;
    this.deleted = false;
    this.reasonForDeletion = "";
    this.band = "";
    this.tracksDisplayingPreference = "";
    this.playlistsDisplayingPreference = "";
    this.verified = false;
    this.preferredCurrency = "";
    this.isOpen = false;
    this.isProfileOpen = true;
    this.isPresenceOpen = false;
    this.isStripeOpen = false;
    this.isChangeLogOpen = false;
    this.isActivityOpen = false;
    this.isEmailLogOpen = false;
    this.changeLog = [];
    this.stripeData = {};
    this.mappedGroups = [];
    this.groupMemberships = [];
    this.mappedPerformers = [];
    this.mappedVenues = [];
    this.changed = false;
  }
}
export interface UserRole {
  id: number;
  name: string;
}

export interface UserModel {
  id: number;
  asliceId: string;
  firstName: string;
  lastName: string;
  fullname: string;
  email: string;
  avatar: string;
  notes: string;
  created: string;
  roles: UserRole[];
  emailVerified: boolean;
  verificationStatus: string;
  publisherEmail: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  socials: UserSocial[];
  country: string;
  postalCode: string;
  paypalEmail: string;
  paypalPayerId: string;
  performers: Array<any>;
  venues: Array<any>;
  groups: PerformerModel[];
  groupMemberships: Array<any>;
  stripeCustomerId: string;
  stripePaymentMethodId: string;
  stripeAccountId: string;
  isReadyForStripePayouts: boolean;
  deleted: boolean;
  reasonForDeletion: string;
  band: string;
  tracksDisplayingPreference: string;
  playlistsDisplayingPreference: string;
  verified: boolean;
  preferredCurrency: string;
  isOpen: boolean;
  isProfileOpen: boolean;
  isPresenceOpen: boolean;
  isStripeOpen: boolean;
  isChangeLogOpen: boolean;
  isActivityOpen: boolean;
  isEmailLogOpen: boolean;
  changeLog: any[];
  stripeData: any;
  mappedGroups: any[];
  mappedPerformers: Array<string>;
  mappedVenues: Array<string>;
  primeArtistName?: string;
  primeVenueName?: string | null;
  companyName?: string;
  registrationComplete?: boolean;
  changed: boolean;
}
export interface UserPartData {
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  country: string;
  city: string;
  state: string;
  postalCode: string;
  performers: string[];
  venues: string[];
  groups: string[];
  mappedGroups: string[];
}
export interface UserSocial {
  type: string;
  link: string;
  alternatives?: UserSocial[];
}
export interface UserPartSocials {
  socials: UserSocial[];
}
export interface IUserState {
  id: number | null;
  token: string;
  user: UserModel | null;
  userData: UserModel | null;
}

@Module({ dynamic: true, store, name: "user", namespaced: true })
class User extends VuexModule implements IUserState {
  public id: number | null = null;
  public token = getToken() || "";
  public userData: UserModel = new UserModelData();
  public user: UserModel | null = null;
  public users: UserModel[] = [];
  public page = 1;
  public pages = 0;
  public take = 15;

  @Mutation
  private SET_USERS(users: any) {
    users.forEach((user: UserModel) => {
      const respUser = JSON.parse(JSON.stringify(user));
      const socials = [
        { type: "Website", link: "" },
        { type: "Bandcamp", link: "" },
        { type: "Beatport", link: "" },
        { type: "Discogs", link: "" },
        { type: "Soundcloud", link: "" },
        { type: "Tracksource", link: "" },
        { type: "Facebook", link: "" },
        { type: "Instagram", link: "" },
        { type: "Resident advisor", link: "" },
        { type: "Twitter", link: "" },
      ];
      if (respUser.socials && respUser.socials.length > 0) {
        respUser.socials.forEach((social: UserSocial) => {
          const found = socials.find((s: UserSocial) => s.type === social.type);
          if (found) {
            found.link = social.link;
          }
        });
      }
      respUser.socials = socials;
      user.socials = respUser.socials;
      user.isOpen = false;
      user.isProfileOpen = true;
      user.isPresenceOpen = false;
      user.isStripeOpen = false;
      user.isChangeLogOpen = false;
      user.isActivityOpen = false;
      user.isEmailLogOpen = false;
      user.changeLog = [];
      user.stripeData = new StripeData();
      user.mappedPerformers = respUser.performers?.map((x: any) => x.name);
      user.mappedVenues = respUser.venues?.map((x: any) => x.name);
      user.mappedGroups = respUser.groupMemberships?.map(
        (x: any) => x.group.name
      );
      user.changed = false;
    });
    this.users = users;
  }

  @Mutation
  private SET_NEW_TAKE(number: number) {
    this.take = number;
  }
  @Mutation
  private SET_PAGE(page: number) {
    this.page = page;
  }

  @Mutation
  private SET_TAKE(take: number) {
    this.take = take;
  }

  @Mutation
  private SET_PAGES(pages: number) {
    this.pages = pages;
  }

  @Mutation
  private SET_TOKEN(token: string) {
    this.token = token;
  }

  @Mutation
  private SET_USER(user: any) {
    this.user = user;
  }

  @Mutation
  private UPDATE_USER(user: UserModel) {
    const found = this.users.find((x: UserModel) => x.id === user.id);
    if (found) {
      found.verified = user.verified;
    }
  }
  @Mutation
  private UPDATE_USER_GROUP(payload: {
    userId: number;
    group: PerformerModel;
  }) {
    const foundUser = this.users.find(
      (x: UserModel) => x.id === payload.userId
    );
    if (foundUser) {
      const foundGroup = foundUser.groups.find((x) => x.id == payload.group.id);
      if (foundGroup) {
        foundGroup.groupMembers = payload.group.groupMembers.map(
          (x: any) => x.name
        );
      }
    }
  }

  @Mutation
  private UPDATE_USER_GROUP_NEW(payload: {
    userId: number;
    group: PerformerModel;
  }) {
    const foundUser = this.users.find(
      (x: UserModel) => x.id === payload.userId
    );
    if (foundUser) {
      const index = foundUser.groupMemberships.findIndex(
        (el) => el.group.id === payload.group.id
      );
      console.log("INDEX", index, foundUser.groupMemberships);
      foundUser.groupMemberships[index].group = payload.group;
      foundUser.mappedGroups = foundUser.groupMemberships.map(
        (el) => el.group.name
      );
    }
  }

  @Mutation
  private UPDATE_USER_DATA(user: UserModel) {
    this.users = this.users.map((item) => {
      if (item.id === user.id) {
        return {
          ...item,
          ...user,
          fullname: user.fullname,
          addressLine1: user.addressLine1,
          addressLine2: user.addressLine2,
          city: user.city,
          country: user.country,
          state: user.state,
          postalCode: user.postalCode,
          performers: user.performers,
          groups: [...user.groupMemberships],
          mappedPerformers: [...user.performers.map((x: any) => x.name)],
          mappedVenues: [...user.venues.map((x: any) => x.name)],
          mappedGroups: [
            ...user.groupMemberships.map((x: any) => x.group.name),
          ],
          socials: item.socials.map((social) => {
            const updatedSocial = user.socials.find(
              ({ type }) => social.type === type
            );
            if (updatedSocial) {
              return {
                ...social,
                link: updatedSocial.link,
              };
            }
            return social;
          }),
          changed: false,
        };
      }

      return item;
    });
  }

  @Mutation UPDATE_USER_PERFORMERS(performer: any) {
    const found = this.users.find((x: UserModel) => x.id === performer.userId);
    if (found) {
      const index = found.performers.findIndex((el) => performer.id === el.id);
      found.performers.splice(index, 1, performer);
      found.mappedPerformers = found.performers.map((x: any) => x.name);
    }
  }

  @Mutation UPDATE_USER_VENUES(venue: any) {
    const found = this.users.find((x: UserModel) => x.id === venue.userId);
    if (found) {
      const index = found.venues.findIndex((el) => venue.id === el.id);
      found.venues.splice(index, 1, venue);
      found.mappedVenues = found.venues.map((x: any) => x.name);
    }
  }

  @Mutation
  private SET_SHOW_USER({ id, status }: { id: number; status: boolean }) {
    const item: any = this.users.find((elem: any) => elem.id === id);
    if (!item) {
      return;
    }
    this.users.forEach((user) => {
      user.isOpen = false;
    });
    item.isOpen = status;
  }

  @Mutation
  private SET_USER_STRIPE_DATA({
    id,
    stripeData,
    photos,
  }: {
    id: number;
    stripeData: any;
    photos: Array<string>;
  }) {
    const item: any = this.users.find((elem: any) => elem.id === id);
    if (!item) {
      return;
    }
    item.stripeData = stripeData;
    item.photos = photos;
  }

  @Mutation
  private SET_CHANGE_LOG({ id, log }: { id: number; log: any[] }) {
    const user: any = this.users.find((elem: any) => elem.id === id);
    if (!user) {
      return;
    }
    user.changeLog = log;
  }

  @Mutation
  private SET_ACTIVEMENU({ id, menuTab }: { id: number; menuTab: string }) {
    const item: any = this.users.find((elem: any) => elem.id === id);
    if (!item) {
      return;
    }
    if (menuTab == "isProfileOpen") {
      item.isStripeOpen = false;
      item.isPresenceOpen = false;
      item.isProfileOpen = true;
      item.isChangeLogOpen = false;
      item.isActivityOpen = false;
      item.isEmailLogOpen = false;
    }
    if (menuTab == "isPresenceOpen") {
      item.isStripeOpen = false;
      item.isPresenceOpen = true;
      item.isProfileOpen = false;
      item.isChangeLogOpen = false;
      item.isActivityOpen = false;
      item.isEmailLogOpen = false;
      // item.socials = [
      //   { type: "Facebook", link: "" },
      //   { type: "Discogs", link: "" },
      //   { type: "Resident advisor", link: "" },
      //   { type: "Soundcloud", link: "" },
      //   { type: "Online retailer", link: "" },
      //   { type: "Instagram", link: "" }
      // ]
    }
    if (menuTab == "isStripeOpen") {
      item.isStripeOpen = true;
      item.isPresenceOpen = false;
      item.isProfileOpen = false;
      item.isChangeLogOpen = false;
      item.isActivityOpen = false;
      item.isEmailLogOpen = false;
    }
    if (menuTab == "isChangeLogOpen") {
      item.isStripeOpen = false;
      item.isPresenceOpen = false;
      item.isProfileOpen = false;
      item.isChangeLogOpen = true;
      item.isActivityOpen = false;
      item.isEmailLogOpen = false;
    }
    if (menuTab == "isActivityOpen") {
      item.isStripeOpen = false;
      item.isPresenceOpen = false;
      item.isProfileOpen = false;
      item.isChangeLogOpen = false;
      item.isActivityOpen = true;
      item.isEmailLogOpen = false;
    } else if (menuTab == "isEmailLogOpen") {
      item.isStripeOpen = false;
      item.isPresenceOpen = false;
      item.isProfileOpen = false;
      item.isChangeLogOpen = false;
      item.isActivityOpen = false;
      item.isEmailLogOpen = true;
    }
  }

  @Mutation
  private SET_APPROVED_STATUS_IN_USER_GROUP(payload: {
    userId: number;
    groupId: number;
    status: boolean;
  }) {
    const { userId, groupId, status } = payload;
    const foundUser = this.users.find((x: UserModel) => x.id === userId);
    if (foundUser) {
      foundUser.groupMemberships = foundUser.groupMemberships.map((item) => {
        if (item.groupId === groupId) {
          return {
            ...item,
            canApprove: status,
          };
        }

        return item;
      });
    }
  }

  @Mutation
  private UPDATE_USER_DISPLAYED_NAME(payload: {
    id: number;
    displayedName: string;
  }) {
    const user = this.users.find((x: UserModel) => x.id === payload.id);
    if (user) {
      user.primeArtistName = payload.displayedName;
      user.performers = user.performers.map((performer) => {
        if (performer.isPrime) {
          performer.displayedName = payload.displayedName;
        }
        return performer;
      });
    }
  }

  @Action
  public activateMenu(payload: { id: number; menuTab: string }) {
    this.SET_ACTIVEMENU(payload);
  }

  @Action
  public async Login(userInfo: { email: string; password: string }) {
    const { email, password } = userInfo;
    const {
      data: { accessToken, user },
    } = await login({ email, password });

    const allowedRoles = ["admin", "intern"];

    if (!user.roles.some((role: any) => allowedRoles.includes(role.name))) {
      await swal.fire({
        icon: "error",
        text: "You do not have access to the Admin Dashboard",
      });
      return false;
    }

    setToken(accessToken);
    this.SET_TOKEN(accessToken);
    return true;
  }

  @Action
  public ResetToken() {
    removeToken();
    removeUser();
    this.SET_TOKEN("");
    this.SET_USER(null);
  }

  @Action
  public async GetUserInfo() {
    const { data: user } = await fetchCurrentUser();
    this.SET_USER(user);
    Sentry.setUser({
      id: user.id,
      email: user.email,
      username: user.asliceId,
      ip_address: "{{auto}}",
    });
  }

  @Action
  public async Logout() {
    swal.showLoading();

    try {
      await logout();
    } catch (e) {
      await swal.fire({
        icon: "error",
        title: e?.error,
        text: e?.message,
      });
      return;
    } finally {
      swal.close();
    }

    removeToken();
    removeUser();
    location.reload();
  }

  @Action
  public async getUser(payload: { id: number; status: boolean }) {
    this.SET_SHOW_USER(payload);
  }

  @Action
  public async verifyUser(userId: number) {
    try {
      swal.showLoading();
      const response = await verifyUser(userId, { verified: true });
      this.UPDATE_USER(response.data);
      swal.close();
      return response;
    } catch (e) {
      console.log(e);
      swal.fire({
        icon: "error",
        text: "Something went wrong",
      });
    }
  }

  @Action
  public changeTake(newNumber: number) {
    this.SET_NEW_TAKE(newNumber);
  }

  @Action
  public async fetchUsers({
    page,
    take,
    query,
    orderColumn,
    reviewStatus,
    role,
    orderDirection,
  }: {
    page: number;
    take?: number | null;
    query?: string | null;
    orderColumn?: string | null;
    reviewStatus?: string | null;
    role?: string | null;
    orderDirection?: string | null;
  }) {
    try {
      swal.showLoading();
      this.SET_PAGE(page);
      if (take) {
        this.SET_TAKE(take);
      }
      const {
        data: { results, count },
      } = await fetchUsers({
        query,
        page: this.page,
        take: this.take,
        orderColumn,
        reviewStatus,
        role,
        orderDirection,
      } as any);

      this.SET_PAGES(Math.ceil(count / this.take));
      this.SET_USERS(results);
    } catch (e) {
      console.log(e);
    } finally {
      swal.close();
    }
  }

  @Action
  public async fetchUser(id: number) {
    try {
      swal.showLoading();
      this.SET_PAGE(1);
      const { data } = await fetchUser(id);

      this.SET_PAGES(1);
      this.SET_USERS([data]);
    } catch (e) {
      console.log(e);
    } finally {
      swal.close();
    }
  }

  @Action
  async setApproveGroup(payload: any) {
    try {
      const { userId, id, status } = payload;
      const response = await approveAlias(payload);
      this.UPDATE_USER_GROUP_NEW({ userId, group: response.data });
      if (payload.status === "pending") {
        this.checkCanBeGroupApproved({ userId, groupId: payload.id });
      } else {
        this.SET_APPROVED_STATUS_IN_USER_GROUP({
          userId,
          groupId: id,
          status: false,
        });
      }
    } catch (err) {
      swal.fire({
        icon: "error",
        text: err.message,
      });
    }
  }

  @Action
  async setApproveAlias(payload: any) {
    if (!payload.id) {
      const response = await this.updateUserData(payload.user);
      const performer = response?.data.performers.find(
        (item) => item.name === payload.name
      );
      const performerResult = await approveAlias({
        ...payload,
        id: performer.id,
        status: "approved",
      });
      this.UPDATE_USER_PERFORMERS(performerResult.data);
      return;
    }
    try {
      const response = await approveAlias(payload);
      this.UPDATE_USER_PERFORMERS(response.data);
    } catch (err) {
      notify({
        title: "Warn",
        text: err.message,
        type: "warn",
        duration: 10000,
        speed: 1000,
        data: {},
      });
    }
  }

  @Action
  async setApproveVenue(payload: any) {
    if (!payload.id) {
      const response = await this.updateUserData(payload.user);
      const venue = response?.data.venues.find(
        (item) => item.name === payload.name
      );
      const venueResult = await approveVenue({
        ...payload,
        id: venue.id,
        status: "approved",
      });
      this.UPDATE_USER_VENUES(venueResult.data);
      return;
    }
    try {
      const response = await approveVenue(payload);
      this.UPDATE_USER_VENUES(response.data);
    } catch (err) {
      notify({
        title: "Warn",
        text: err.message,
        type: "warn",
        duration: 10000,
        speed: 1000,
        data: {},
      });
    }
  }

  @Action
  public async updateUserData(user: UserModel) {
    try {
      swal.showLoading();
      const payload: UserPartData = {
        firstName: user.firstName,
        lastName: user.lastName,
        addressLine1: user.addressLine1,
        addressLine2: user.addressLine2,
        country: user.country,
        city: user.city,
        state: user.state,
        postalCode: user.postalCode,
        performers: user.mappedPerformers,
        venues: user.mappedVenues,
        groups: user.mappedGroups,
        mappedGroups: [],
      };
      payload.performers = payload.performers.filter((x) => x);
      payload.mappedGroups = payload.mappedGroups.filter((x) => x);
      const request = JSON.parse(JSON.stringify(payload));
      delete request.mappedGroups;
      const response = await updateUserData(user.id, request);
      this.UPDATE_USER_DATA(response.data);
      swal.close();
      notify({
        title: "Your profile has been updated.",
        text: "Successfully",
        type: "success",
        duration: 10000,
        speed: 1000,
      });
      return response;
    } catch (e) {
      console.log(e);
      notify({
        title: "Warn",
        text: "We can't save",
        type: "warn",
        duration: 10000,
        speed: 1000,
        data: {},
      });
    }
  }

  @Action
  public async updateUser({ userId, data }: { userId: number; data: any }) {
    try {
      swal.showLoading();
      const response = await updateUserRequest(userId, data);
      this.UPDATE_USER_DATA(response.data);
      swal.close();
      return response;
    } catch (e) {
      console.log(e);
      notify({
        title: "Warn",
        text: "We can't save",
        type: "warn",
        duration: 10000,
        speed: 1000,
        data: {},
      });
    }
  }

  @Action
  public async updateUserSocials(user: UserModel) {
    try {
      const socials = user.socials.filter((x) => x.link);
      const response = await updateUserSocials(user.id, socials);
      this.UPDATE_USER_DATA(response.data);
      notify({
        title: "Your profile has been updated.",
        text: "Successfully",
        type: "success",
        duration: 10000,
        speed: 1000,
      });
    } catch (e) {
      console.log(e);
      notify({
        title: "Warn",
        text: "We can't save",
        type: "warn",
        duration: 10000,
        speed: 1000,
        data: {},
      });
    }
  }
  @Action
  public async getUserStripeData(userId: number) {
    try {
      const data = await fetchUserStripeData(userId);
      const payload = {
        id: userId,
        stripeData: data.data,
        photos: data.data?.document?.files.map((item: any) => item.data),
      };
      this.SET_USER_STRIPE_DATA(payload);
    } catch (e) {
      console.log(e);
    }
  }
  @Action
  public async getUserChangeLog(userId: number) {
    try {
      const { data } = await getUserChangeLogRequest(userId);
      data.results
        ? this.SET_CHANGE_LOG({ id: userId, log: data.results })
        : this.SET_CHANGE_LOG({ id: userId, log: data });
      console.log(data.results);
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  public async grabDataFromDiscogs(userId: number) {
    try {
      swal.showLoading();
      const { data } = await grabDataFromDiscogsRequest(userId);
      this.UPDATE_USER_DATA(data);
      swal.close();
    } catch (e) {
      console.log(e);
      swal.fire({
        icon: "error",
        text: "Something went wrong",
      });
      return null;
    }
  }

  @Action
  public async updateUserReviewStatus(payload: {
    id: number;
    status: REVIEW_STATUSES;
  }) {
    try {
      const { id, status } = payload;
      const { data } = await updateUserReviewStatusRequest(id, status);
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  public async PaymentMethod(userId: number) {
    try {
      swal.showLoading();
      const { data } = await getPaymentMethod(userId);
      swal.close();
      return data;
    } catch (e) {
      swal.close();
      console.log(e);
    }
  }

  @Action
  public async inviteVipUserRequest(payload: { email: string; role: string }) {
    try {
      swal.showLoading();
      const { data } = await inviteVipUserRequest(payload);
      swal.close();
      return data;
    } catch (e: any) {
      swal.fire({
        icon: "error",
        text: e.message || "Something went wrong",
      });
      console.log(e);
      return null;
    }
  }

  @Action
  public async checkCanBeGroupApproved(payload: {
    userId: number;
    groupId: number;
  }) {
    try {
      const { userId, groupId } = payload;
      const { data } = await checkCanBeGroupApprovedRequest(groupId);
      if (data && Object.prototype.hasOwnProperty.call(data, "status")) {
        this.SET_APPROVED_STATUS_IN_USER_GROUP({
          userId,
          groupId,
          status: data.status,
        });
      }
    } catch (e: any) {
      console.error(e);
    }
  }

  @Action
  public async scrapSocials(payload: UserModel) {
    try {
      const { socials } = payload;
      const response = await socialsScrapping(
        toRaw(socials),
        payload.primeArtistName || `DVS1`
      );
      return response;
    } catch (e: any) {
      console.error(e);
    }
  }

  @Action
  public async deleteUser(payload: {
    id: number;
    data: { reasonForDeletion?: string; completeRemoval?: boolean };
  }) {
    try {
      const { id, data } = payload;
      const response = await deleteUserRequest(id, data);
      return response;
    } catch (e: any) {
      console.error(e);
    }
  }

  @Action
  public async updateUserPerformer(payload: any) {
    try {
      const response = await updatePerformerRequest(payload.id, payload.data);
      if (response.status === 200) {
        this.UPDATE_USER_DISPLAYED_NAME({
          id: payload.userId,
          displayedName: payload.data.displayedName,
        });
      }
      return response;
    } catch (e: any) {
      console.error(e);
    }
  }

  get roles(): string[] {
    return this.user?.roles.map((role) => role.name) ?? [];
  }
  get isAdmin(): boolean {
    return this.roles.includes("admin");
  }
}

export const UserModule = getModule(User);
