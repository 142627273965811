import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from "vuex-module-decorators";

import dayjs from "dayjs";
import swal from "sweetalert2";
import store from "@/store";
import {
  addPerformers2Track,
  updateTrackInPlaylist,
  updateTrackInPlaylistNewF,
  approvePlaylist,
  approveTrack,
  createPerformer,
  // createTrackAlias,
  updatePerformerRequest,
  // deletePerformersFromTrack,
  fetchPerformers,
  fetchPlaylists,
  fetchPlaylistTracks,
  fetchTracks,
  checkPlaylistToApprovingStatus,
  trackUnmatching,
  updatePlaylistRequest,
  updateVenueRequest,
  getPlaylistStatisticsRequest,
  reportForAdmin,
  reportEnhancedForAdmin,
} from "@/api/playlist";
import { approveAlias as changePerformerStatusRequest } from "@/api/user";

import { updateTrackRequest } from "@/api/tracks";
import { getPaymentsReportRequest } from "@/api/reports";
import { downloadFile } from "@/utils/files";
import { setReportCookie } from "@/utils/cookies";

export interface TrackModel {
  title: string;
  [x: string]: any;
  playCount?: any;
  id: number;
  name: string;
  album: string;
  genre: string;
  bpm: number;
  time: string;
  buyLink?: any;
  link: string | null;
  image?: any;
  status: boolean;
  collationStatus: string;
  artist: string;
  isEdit: boolean;
  performers: {
    id: number;
    name: string;
    user: any;
    url: string;
    isGroup: boolean;
    groupMemberships: any;
  }[];
  remixers: { id: number; name: string; user: any }[];
  versionId: string;
}

export class ModelTrack {
  playCount?: any;
  id: number;
  name: string;
  album: string;
  genre: string;
  bpm: number;
  time: string;
  buyLink?: any;
  link: string | null;
  image?: any;
  status: boolean;
  collationStatus: string;
  artist: string;
  isEdit: boolean;
  performers: { id: number; name: string; user: any; url: string }[];
  performersObjects: { id: number; name: string; user: any; url: string }[];
  remixers: { id: number; name: string; user: any }[];
  remixersObjects: { id: number; name: string; user: any; url: string }[];

  constructor() {
    this.playCount = 1;
    this.id = 0;
    this.name = "";
    this.album = "";
    this.genre = "";
    this.bpm = 0;
    this.time = "";
    this.buyLink = "";
    this.link = "";
    this.image = "";
    this.status = false;
    this.collationStatus = "";
    this.artist = "";
    this.isEdit = false;
    this.performers = [];
    this.performersObjects = [];
    this.remixers = [];
    this.remixersObjects = [];
  }
}
export interface RawTrackModel {
  album: string;
  artist: string;
  bpm: number;
  genre: string;
  hidden: boolean;
  id: number;
  status: string;
  time: string;
  title: string;
  trackId: number;
}

export interface VenueModel {
  id: number;
  name: string;
  displayedName: string;
  isPrimary: boolean;
  created: Date;
  updated: Date;
  status: string;
  userId: number;
  sameAddress: boolean;
  multipleAddresses: boolean;
}

export interface PlaylistModel {
  id: number;
  name: string;
  created: Date;
  updated: Date;
  datePlayed: Date;
  uuid: string;
  totalAmount: string;
  venue?: any;
  country?: any;
  status: boolean;
  isPaid: boolean;
  rawTracks: RawTrackModel[];
  tracks: TrackModel[];
  priority: string;
  versionId: string;
  isOpen?: boolean;
  statistics?: any;
  venueObject?: VenueModel;
  promotionCompany?: VenueModel;
}

export interface PerformerModel {
  id: number;
  name: string;
  url: string;
  aliases: any[];
}

export interface IPlaylistState {
  playlists: PlaylistModel[];
  performers: PerformerModel[] | string[];
  searchedTracks: TrackModel[];
  searchedTracksPages: number;
  searchedTracksPageSize: number;
  pageMultiselect: number;
  page: number;
  pages: number;
  take: number;
}

@Module({ dynamic: true, store, name: "playlist", namespaced: true })
class Playlist extends VuexModule implements IPlaylistState {
  // public playlists = [];
  public playlists: PlaylistModel[] = [];
  public unTouchedTrack: ModelTrack = new ModelTrack();
  public performers = [];
  public pageMultiselect = 50;
  public searchedTracks = [];
  public searchedTracksPages = 1;
  public searchedTracksPageSize = 10;
  public page = 1;
  public pages = 0;
  public take = 15;

  @Mutation
  private SET_UNTOUCHEDTRACK_DATA(unTouchedTrack: any) {
    this.unTouchedTrack = JSON.parse(JSON.stringify(unTouchedTrack));
  }

  @Mutation
  private SET_NEW_TAKE(number: number) {
    this.take = number;
  }

  @Mutation
  private CANCEL_EDITING_TRACK(payload: {
    playlistId: number;
    trackId: number;
  }) {
    const foundPlaylist: any = this.playlists.find(
      (elem: any) => elem.id === payload.playlistId
    );
    if (!foundPlaylist) {
      return;
    }
    const foundTrack = foundPlaylist.tracks.find(
      (x: any) => x.id == payload.trackId
    );
    if (!foundTrack) {
      return;
    }
    foundTrack.name = this.unTouchedTrack.name;
    foundTrack.performers = this.unTouchedTrack.performers;
    foundTrack.remixers = this.unTouchedTrack.remixers;
    foundTrack.status = this.unTouchedTrack.status;
    foundTrack.collationStatus = this.unTouchedTrack.collationStatus;
    foundTrack.isEdit = false;
  }

  @Mutation
  private SET_PLAYLISTS(playlists: any) {
    this.playlists = playlists;
  }

  @Mutation
  private SET_PLAYLIST_IN_LIST(playlist: PlaylistModel) {
    const playlistIndex = this.playlists.findIndex(
      (item: PlaylistModel) => item.id === playlist.id
    );
    if (playlistIndex !== -1) {
      (this.playlists[playlistIndex] as PlaylistModel) = {
        ...(this.playlists[playlistIndex] as PlaylistModel),
        ...playlist,
      };
    }
  }

  @Mutation
  private UPDATE_VENUE_DATA_IN_PLAYLIST({
    venueData,
  }: {
    venueData: VenueModel;
  }) {
    this.playlists = this.playlists.map((playlist: PlaylistModel) => {
      if (
        playlist.promotionCompany &&
        playlist.promotionCompany.id === venueData.id
      ) {
        return {
          ...playlist,
          promotionCompany: { ...venueData },
        };
      }
      return playlist;
    });
  }

  @Mutation
  private SET_PAGE(page: number) {
    this.page = page;
  }

  @Mutation
  private SET_TAKE(take: number) {
    this.take = take;
  }

  @Mutation
  private SET_PAGES(pages: number) {
    this.pages = pages;
  }

  @Mutation
  private SET_SHOW_RAWTRACKS({
    id,
    rawTracks,
  }: {
    id: number;
    rawTracks: TrackModel[];
  }) {
    const item: any = this.playlists.find((elem: any) => elem.id === id);
    if (!item) {
      return;
    }
    item.rawTracks = rawTracks;
  }

  @Mutation
  private SET_SHOW_TRACKS({
    id,
    status,
    tracks,
  }: {
    id: number;
    status: boolean;
    tracks: TrackModel[];
  }) {
    this.playlists.forEach((playlist: any) => {
      playlist.isOpen = false;
    });
    const item: any = this.playlists.find((elem: any) => elem.id === id);
    if (!item) {
      return;
    }
    item.isOpen = status;
    item.tracks = tracks;
  }

  @Mutation
  private SET_APPROVED_PLAYLIST({ id }: { id: number }) {
    const item: any = this.playlists.find((elem: any) => elem.id === id);
    if (!item) {
      return;
    }
    if (item.status === true) {
      item.status = false;
    } else {
      item.status = true;
    }
  }

  @Mutation
  private SET_APPROVED_TRACK({
    playlistId,
    track,
  }: {
    playlistId: number;
    track: TrackModel;
  }) {
    const item: any = this.playlists.find(
      (elem: any) => elem.id === playlistId
    );
    let trackOfPlaylist;
    if (!item) {
      return;
    }
    if (item.tracks && item.tracks.length > 0) {
      trackOfPlaylist = item.tracks.find((elem: any) => elem.id === track.id);
      if (!trackOfPlaylist) {
        return;
      }
      trackOfPlaylist.status = track.status;
      trackOfPlaylist.collationStatus = track.collationStatus;
      trackOfPlaylist.versionId = track.versionId;
    }
  }

  @Mutation
  private REMOVE_TRACK_REMIXERS({
    playlistId,
    trackId,
    remixer,
  }: {
    playlistId: number;
    trackId: number;
    remixer: string;
  }) {
    const playlist: any = this.playlists.find(
      (elem: any) => elem.id === playlistId
    );
    const track = playlist?.tracks.find((elem: any) => elem.id === trackId);
    if (!track) {
      return;
    }
    if (track.remixers) {
      track.remixers.splice(
        track.remixers.findIndex((x: any) => x == remixer),
        1
      );
    }
  }

  @Mutation
  private REMOVE_TRACK_PERFORMERS({
    playlistId,
    trackId,
    performer,
  }: {
    playlistId: number;
    trackId: number;
    performer: string;
  }) {
    const playlist: any = this.playlists.find(
      (elem: any) => elem.id === playlistId
    );
    const track = playlist?.tracks.find((elem: any) => elem.id === trackId);
    if (!track) {
      return;
    }
    if (track.performers) {
      track.performers.splice(
        track.performers.findIndex((x: any) => x == performer),
        1
      );
    }
  }

  @Mutation
  private SET_TRACK_REMIXERS({
    playlistId,
    trackId,
    remixer,
  }: {
    playlistId: number;
    trackId: number;
    remixer: string;
  }) {
    const playlist: any = this.playlists.find(
      (elem: any) => elem.id === playlistId
    );
    const track = playlist?.tracks.find((elem: any) => elem.id === trackId);
    if (!track) {
      return;
    }
    if (track.remixers) {
      const sameRemixer = track.remixers.find((x: any) => x == remixer);
      if (sameRemixer) {
        swal.fire({
          icon: "error",
          text: `You already have ${sameRemixer} in performer list.`,
        });
        return;
      } else {
        track.remixers.push(remixer);
      }
    }
    // const filteredPerformers = track.performers.filter(
    //   (elem: any) => elem !== performer
    // );
    // track.performers =
    //   track.performers.length === filteredPerformers.length
    //     ? [...filteredPerformers, performer]
    //     : filteredPerformers;
  }

  @Mutation
  private SET_TRACK_PERFORMERS({
    playlistId,
    trackId,
    performer,
  }: {
    playlistId: number;
    trackId: number;
    performer: string;
  }) {
    const playlist: any = this.playlists.find(
      (elem: any) => elem.id === playlistId
    );
    const track = playlist?.tracks.find((elem: any) => elem.id === trackId);
    if (!track) {
      return;
    }
    if (track.performers) {
      const samePerformer = track.performers.find((x: any) => x == performer);
      if (samePerformer) {
        swal.fire({
          icon: "error",
          text: `You already have ${samePerformer} in performer list.`,
        });
        return;
      } else {
        track.performers.push(performer);
      }
    }
    // const filteredPerformers = track.performers.filter(
    //   (elem: any) => elem !== performer
    // );
    // track.performers =
    //   track.performers.length === filteredPerformers.length
    //     ? [...filteredPerformers, performer]
    //     : filteredPerformers; pageMultiselect
  }

  @Mutation
  private SET_PERFORMERS(performers: any) {
    const perfs = performers.map((x: any) => x.name || x);
    this.performers = perfs;
  }

  @Mutation
  private SET_EDIT_TRACKS({
    playlistId,
    trackId,
    isEdit,
  }: {
    playlistId: number;
    trackId: number;
    isEdit: boolean;
  }) {
    const playlist: any = this.playlists.find(
      (elem: any) => elem.id === playlistId
    );
    const track = playlist?.tracks.find((elem: any) => elem.id === trackId);
    if (!track) {
      return;
    }
    track.isEdit = isEdit;
  }

  @Mutation
  private SET_UPDATETRACK_STATUS({
    playlistId,
    track,
  }: {
    playlistId: number;
    track: any;
  }) {
    const playlist: any = this.playlists.find(
      (elem: any) => elem.id === playlistId
    );
    const foundTrack = playlist?.tracks.find(
      (elem: any) => elem.id === track.id
    );
    if (!foundTrack) {
      return;
    }
    foundTrack.name = track.name;
    foundTrack.performers = track.performers.map((x: any) => x.name);
    foundTrack.remixers = track.remixers.map((x: any) => x.name);
    foundTrack.performersObjects = track.performers;
    foundTrack.remixersObjects = track.remixers;
    foundTrack.status = track.status;
    foundTrack.collationStatus = track.collationStatus;
    foundTrack.isEdit = false;
    foundTrack.artist =
      foundTrack.performers
        .map((performer: any) => performer.name || performer)
        .join(", ") || "";
    foundTrack.versionId = track.versionId;
  }

  @Mutation
  private SET_TRACK_STATUS({
    playlistId,
    trackId,
    isEdit,
  }: {
    playlistId: number;
    trackId: number;
    isEdit: boolean;
  }) {
    const playlist: any = this.playlists.find(
      (elem: any) => elem.id === playlistId
    );
    const track = playlist?.tracks.find((elem: any) => elem.id === trackId);
    if (!track) {
      return;
    }
    // track.status = true;
    track.isEdit = isEdit;
  }

  @Mutation
  private SET_SEARCHED_TRACKS(payload: { tracks: any; pages: number }) {
    this.searchedTracks = payload.tracks;
    this.searchedTracksPages = payload.pages;
  }

  @Mutation
  private CHANGE_COLLATION_STATUS(payload: { playlistId: number; track: any }) {
    const playlist: any = this.playlists.find(
      (elem: any) => elem.id === payload.playlistId
    );
    if (playlist) {
      const foundTrack = playlist?.tracks.find(
        (elem: any) => elem.id === payload.track.id
      );
      if (!foundTrack) {
        return;
      }
      foundTrack.collationStatus = payload.track.collationStatus;
    }
  }

  @Mutation
  private UPDATE_ARTIST_IN_TRACK({
    playlistId,
    trackId,
    artistId,
    data,
  }: {
    playlistId: number;
    trackId: number;
    artistId: number;
    data: any;
  }) {
    const playlist: any = this.playlists.find(
      (elem: any) => elem.id === playlistId
    );
    const track = playlist?.tracks.find((elem: any) => elem.id === trackId);
    if (!track) {
      return;
    }
    const performerIndex = track.performersObjects.findIndex(
      (item: any) => item.id === artistId
    );
    if (performerIndex + 1) {
      track.performersObjects[performerIndex] = {
        ...track.performersObjects[performerIndex],
        ...data,
      };
    }
    const remixerIndex = track.remixersObjects.findIndex(
      (item: any) => item.id === artistId
    );
    if (remixerIndex + 1) {
      track.remixersObjects[remixerIndex] = {
        ...track.remixersObjects[remixerIndex],
        ...data,
      };
    }
  }

  @Mutation
  private SET_PLAYLIST_STATISTICS({ id, data }: { id: number; data: any }) {
    const playlist: any = this.playlists.find((elem: any) => elem.id === id);
    if (playlist) {
      playlist.statistics = data;
    }
  }

  @Action
  public async fetchPlaylists({
    page,
    take,
    query,
    status,
    trackId,
    orderColumn,
    orderDirection,
  }: {
    page: number;
    take?: number | null;
    query?: string | null;
    trackId?: number | null;
    status?: boolean | null;
    orderColumn?: string | null;
    orderDirection?: string | null;
  }) {
    try {
      swal.showLoading();
      this.SET_PAGE(page);
      if (take) {
        this.SET_TAKE(take);
      }
      const {
        data: { results, count },
      } = await fetchPlaylists({
        query,
        page: this.page,
        take: this.take,
        status,
        isPaid: true,
        trackId,
        orderColumn,
        orderDirection,
      } as any);

      this.SET_PAGES(Math.ceil(count / this.take));
      this.SET_PLAYLISTS(
        results.map((item: any) => {
          item.tracks = [];
          item.created = dayjs(item.created).format("YYYY/MM/DD");
          item.datePlayed = dayjs(item.datePlayed).format("YYYY/MM/DD");
          item.isOpen = false;
          item.tracksCount = item.rawTracks.length;
          item.acceptedTracksStatus = item.rawTracks.every(
            ({ trackId }: any) => !!trackId
          );
          return item;
        })
      );
    } catch (e) {
      console.log(e);
    } finally {
      swal.close();
    }
  }

  @Action
  public async GetPlaylistsAsIntern({
    userId,
    page,
    take,
  }: {
    userId: number;
    page: number;
    take?: number | null;
  }) {
    swal.showLoading();
    let results, count;

    try {
      const { data } = await fetchPlaylists({
        userId,
        page,
        take,
      });
      results = data.results;
      count = data.count;
    } catch (e) {
      console.error(e);
      await swal.fire({
        icon: "error",
        text: "Failed to get playlists for the user",
      });
      return null;
    } finally {
      swal.close();
    }

    return {
      results,
      count,
    };
  }

  @Action
  public async setShowTracks(payload: { id: number; status: boolean }) {
    try {
      if (!payload.status) {
        this.SET_SHOW_TRACKS({ ...payload, tracks: [] });
        this.SET_SHOW_RAWTRACKS({ ...payload, rawTracks: [] });
        return;
      }
      const { data } = await fetchPlaylistTracks(payload.id);
      const rawTracks = data.rawTracks;
      const tracks = data.tracks.map((item: any) => {
        item.artist =
          item.performers.map((performer: any) => performer.name).join(", ") ||
          "";
        item.performersObjects = item.performers;
        item.remixersObjects = item.remixers;
        item.performers = item.performers.map((x: any) => x.name);
        item.remixers = item.remixers.map((x: any) => x.name);
        item.isEdit = false;
        return item;
      });
      this.SET_SHOW_TRACKS({ ...payload, tracks });
      this.SET_SHOW_RAWTRACKS({ ...payload, rawTracks });
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  public async fetchPerformers(payload: {
    name?: string | null;
    page?: number | null;
    take?: number | null;
    type?: string;
  }) {
    try {
      const {
        data: { results },
      } = await fetchPerformers(payload as any);
      if (results && results.length > 0) {
        this.SET_PERFORMERS(results);
      } else {
        this.SET_PERFORMERS([]);
      }
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  public changeTake(newNumber: number) {
    this.SET_NEW_TAKE(newNumber);
  }

  @Action
  public setUntouchedTrack(track: any) {
    this.SET_UNTOUCHEDTRACK_DATA(track);
  }

  @Action
  public canelEditingTrack(payload: { playlistId: number; trackId: number }) {
    this.CANCEL_EDITING_TRACK(payload);
  }

  @Action
  public setEditTrack(payload: {
    playlistId: number;
    trackId: number;
    isEdit: boolean;
  }) {
    this.SET_EDIT_TRACKS(payload);
  }

  @Action
  public async setTrackRemixer(payload: {
    playlistId: number;
    trackId: number;
    remixer: string;
  }) {
    this.SET_TRACK_REMIXERS(payload);
    // await addPerformers2Track(payload.trackId, {
    //   performers: [payload.remixer.id],
    // });
  }

  @Action
  public async setTrackPerformer(payload: {
    playlistId: number;
    trackId: number;
    performer: string;
  }) {
    this.SET_TRACK_PERFORMERS(payload);
    // await addPerformers2Track(payload.trackId, {
    //   performers: [payload.performer.id],
    // });
  }

  @Action
  public async removePerformerFromTrack(payload: {
    playlistId: number;
    trackId: number;
    performer: string;
  }) {
    this.REMOVE_TRACK_PERFORMERS(payload);
    // await deletePerformersFromTrack(payload.trackId, {
    //   performers: [payload.performer.id],
    // });
  }

  @Action
  public async removeRemixerFromTrack(payload: {
    playlistId: number;
    trackId: number;
    remixer: string;
  }) {
    this.REMOVE_TRACK_REMIXERS(payload);
    // await deletePerformersFromTrack(payload.trackId, {
    //   performers: [payload.performer.id],
    // });
  }

  @Action
  public async createPerformer(payload: { name: string }) {
    try {
      const { data } = await createPerformer(payload);
      this.SET_PERFORMERS([data]);
    } catch (e: any) {
      swal.fire({
        icon: "error",
        title: e?.error,
        text: e?.message,
      });
      return;
    }
  }

  @Action
  public async updatePerformer({
    id,
    name,
    url,
    playlistId,
    trackId,
  }: {
    id: number;
    name: string;
    url: string;
    playlistId: number;
    trackId: number;
  }) {
    try {
      await updatePerformerRequest(id, { name, url });
      this.UPDATE_ARTIST_IN_TRACK({
        playlistId,
        trackId,
        artistId: id,
        data: { name, url },
      });
    } catch (e: any) {
      swal.fire({
        icon: "error",
        title: e?.error,
        text: e?.message,
      });
      return;
    }
  }

  @Action
  public async changePerformerStatus(payload: {
    id: number;
    status: string;
    trackId: number;
    playlistId: number;
  }) {
    try {
      const { id, status, trackId, playlistId } = payload;
      const response = await changePerformerStatusRequest({ id, status });
      this.UPDATE_ARTIST_IN_TRACK({
        playlistId,
        trackId,
        artistId: id,
        data: { status },
      });
      return response;
    } catch (e: any) {
      swal.fire({
        icon: "error",
        title: e?.error,
        text: e?.message,
      });
      return;
    }
  }

  @Action
  public async updateTrack(payload: { playlistId: number; track: TrackModel }) {
    try {
      const { data } = await updateTrackRequest(payload.track.id, {
        playlistId: payload.playlistId,
        name: payload.track.name,
        buyLink: payload.track.buyLink,
        link: payload.track.link,
        performers: payload.track.performers,
        remixers: payload.track.remixers,
        versionId: payload.track.versionId,
      });
      if (data) {
        this.SET_UPDATETRACK_STATUS({
          playlistId: payload.playlistId,
          track: data as any,
        });
      }

      return data;
      // this.SET_TRACK_STATUS({
      //   playlistId: payload.playlistId,
      //   trackId: payload.track.id,
      //   isEdit: !payload.track.isEdit,
      // });
    } catch (e: any) {
      swal.fire({
        icon: "error",
        title: e?.error,
        text: e?.message,
      });
      return;
    }
  }
  @Action
  public async updateTrackPlaylists(payload: {
    playlistId: number;
    track: TrackModel;
  }) {
    try {
      const { data } = await updateTrackRequest(payload.track.parentId, {
        playlistId: payload.playlistId,
        name: payload.track.name,
        buyLink: payload.track.buyLink,
        link: payload.track.link,
        performers: payload.track.performers,
        remixers: payload.track.remixers,
        versionId: payload.track.versionId,
      });
      if (data) {
        this.SET_UPDATETRACK_STATUS({
          playlistId: payload.playlistId,
          track: data as any,
        });
      }

      return data;
      // this.SET_TRACK_STATUS({
      //   playlistId: payload.playlistId,
      //   trackId: payload.track.id,
      //   isEdit: !payload.track.isEdit,
      // });
    } catch (e: any) {
      swal.fire({
        icon: "error",
        title: e?.error,
        text: e?.message,
      });
      return;
    }
  }

  @Action
  public async trackUnmatching(payload: {
    playlistId: number;
    trackId: number;
    versionId: string;
  }) {
    try {
      swal.showLoading();
      const response = await trackUnmatching(payload.trackId, {
        versionId: payload.versionId,
      });
      if (response.status == 200 || response.status == 201) {
        const payloadForMutatuin = {
          playlistId: payload.playlistId,
          track: response.data,
        };
        this.CHANGE_COLLATION_STATUS(payloadForMutatuin);
        swal.close();
      } else {
        swal.fire({
          icon: "error",
          text: response.data.message,
        });
        return;
      }
    } catch (e: any) {
      swal.fire({
        icon: "error",
        title: e?.error,
        text: e?.message,
      });
      return;
    }
  }

  @Action
  public async approvePlaylist({
    id,
    versionId,
  }: {
    id: number;
    versionId: string;
  }) {
    try {
      swal.showLoading();
      const response = await checkPlaylistToApprovingStatus(id);
      if (!response.data.status) {
        swal.fire({
          icon: "error",
          text: response.data.message,
        });
        return;
      }
      await approvePlaylist(id, { versionId });
      this.SET_APPROVED_PLAYLIST({ id });
      swal.close();
    } catch (e: any) {
      swal.fire({
        icon: "error",
        title: e?.error,
        text: e?.message,
      });
      return;
    }
  }

  @Action
  public async approveTrack({
    playlistId,
    id,
    versionId,
  }: {
    playlistId: number;
    id: number;
    versionId: string;
  }) {
    try {
      const response = await approveTrack(id, { versionId });
      if (response.data) {
        this.SET_APPROVED_TRACK({ playlistId, track: response.data });
        return response.data;
      }
    } catch (e: any) {
      swal.fire({
        icon: "error",
        title: e?.error,
        text: e?.message,
      });
      return;
    }
  }

  @Action
  public async fetchSearchTrackList(payload: { query: string; page: number }) {
    try {
      const {
        data: { results, count },
      } = await fetchTracks({
        ...payload,
        take: this.searchedTracksPageSize,
        withoutHistory: true,
      });
      const pages = Math.max(1, Math.ceil(count / this.searchedTracksPageSize));
      this.SET_SEARCHED_TRACKS({ tracks: results, pages });
    } catch (e: any) {
      swal.fire({
        icon: "error",
        title: e?.error,
        text: e?.message,
      });
      return;
    }
  }

  // @Action
  // public async setTrackAlias({
  //   id,
  //   payload,
  // }: {
  //   id: number;
  //   payload: {
  //     trackForAliasId: number;
  //     playlistId: number;
  //     deleteTrackForAlias: boolean;
  //     versionId: string;
  //   };
  // }) {
  //   try {
  //     const { data } = await createTrackAlias(id, payload);
  //     this.SET_UPDATETRACK_STATUS({
  //       playlistId: payload.playlistId,
  //       track: data as any,
  //     });
  //     return data;
  //   } catch (e: any) {
  //     swal.fire({
  //       icon: "error",
  //       title: e?.error,
  //       text: e?.message,
  //     });
  //     return;
  //   }
  // }
  @Action
  public async updateTrackInPlaylistNewF({
    id,
    payload,
  }: {
    id: number;
    payload: {
      rawTrackId: number;
      replaceWithId: number;
      versionId: string;
      hidden: boolean;
    };
  }) {
    try {
      const { data } = await updateTrackInPlaylistNewF(id, payload);
      return data;
    } catch (e: any) {
      swal.fire({
        icon: "error",
        title: e?.error,
        text: e?.message,
      });
      return;
    }
  }

  @Action
  public async updateTrackInPlaylist({
    id,
    payload,
  }: {
    id: number;
    payload: {
      trackId: number;
      replaceWithId: number;
      versionId: string;
      hidden: boolean;
    };
  }) {
    try {
      const { data } = await updateTrackInPlaylist(id, payload);
      return data;
    } catch (e: any) {
      swal.fire({
        icon: "error",
        title: e?.error,
        text: e?.message,
      });
      return;
    }
  }

  @Action
  public async getPlaylistStatistics(payload: number) {
    try {
      const { data } = await getPlaylistStatisticsRequest(payload);
      if (data) {
        this.SET_PLAYLIST_STATISTICS({ id: payload, data });
      }
      return data;
    } catch (e: any) {
      return;
    }
  }

  @Action
  public async updatePlaylist({ id, data }: { id: number; data: any }) {
    try {
      const response = await updatePlaylistRequest(id, data);
      if (response && response.data) {
        this.SET_PLAYLIST_IN_LIST({
          ...response.data,
          created: dayjs(response.data.created).format("YYYY/MM/DD"),
          datePlayed: dayjs(response.data.datePlayed).format("YYYY/MM/DD"),
        });
      }
      return response;
    } catch (e: any) {
      return;
    }
  }

  @Action
  public async updateVenue({ venueId, data }: { venueId: number; data: any }) {
    try {
      const response = await updateVenueRequest(venueId, data);
      if (response && response.data) {
        this.UPDATE_VENUE_DATA_IN_PLAYLIST({ venueData: response.data });
      }
      return response;
    } catch (e: any) {
      return;
    }
  }

  @Action
  public async downloadReport({
    fromDate,
    toDate,
    format,
    async,
  }: {
    fromDate: string;
    toDate: string;
    format: string;
    async: boolean;
  }) {
    try {
      swal.showLoading();
      const { data } = await reportForAdmin(fromDate, toDate, format, async);

      if (async) {
        setReportCookie(data.uuid);
        swal.close();
        await swal.fire({
          icon: "success",
          title: "Generating report...",
          text: data.message,
        });
      } else {
        const blob = new Blob([data], { type: data.type });
        downloadFile(blob, `playlist-${fromDate}-${toDate}.${format}`);
        swal.close();
      }
    } catch (e: any) {
      await swal.fire({
        icon: "error",
        title: "Not Found",
        text: "No data for provided date range",
      });
      return;
    }
  }

  @Action
  public async downloadEnhancedReport({
    fromDate,
    toDate,
    format,
    zip,
    async,
  }: {
    fromDate: string;
    toDate: string;
    format: string;
    zip: boolean;
    async: boolean;
  }) {
    try {
      swal.showLoading();
      const { data } = await reportEnhancedForAdmin(
        fromDate,
        toDate,
        format,
        zip,
        async
      );
      if (async) {
        setReportCookie(data.uuid);
        swal.close();
        await swal.fire({
          icon: "success",
          title: "Generating report...",
          text: data.message,
        });
      } else {
        const blob = new Blob([data], { type: data.type });
        downloadFile(blob, `enhanced-playlist-${fromDate}-${toDate}.${format}`);
        swal.close();
      }
    } catch (e) {
      await swal.fire({
        icon: "error",
        title: "Not Found",
        text: "No data for provided date range",
      });
      return;
    }
  }
}

export const PlaylistModule = getModule(Playlist);
