<template>
  <div
    :class="['swithcer', { swithcer_enabled: modelValue }]"
    @click="handleChangeSwither"
  />
</template>

<script>
export default {
  name: "Switcher",
  props: ["modelValue"],
  methods: {
    handleChangeSwither() {
      this.$emit("update:modelValue", !this.modelValue);
      this.$emit("change", !this.modelValue);
    },
  },
};
</script>

<style scoped lang="scss">
.swithcer {
  width: 40px;
  height: 20px;
  cursor: pointer;
  border-radius: 40px;
  background: #e3e3e3;
  position: relative;
  transition: all 0.1s linear;

  &::before {
    top: 2px;
    left: 2px;
    content: "";
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    transition: all 0.1s linear;
  }

  &_enabled {
    background: #00978e;

    &::before {
      left: calc(100% - 18px);
    }
  }
}
</style>
