
import { Options, Vue } from "vue-class-component";
import EmailInfoModal from "@/components/email-info-modal/index.vue";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { Prop } from "vue-property-decorator";
import { listAdminMail } from "@/api/messages";
import { IEmailInfo } from "@/views/email-log/index.vue";
import swal from "sweetalert2";

@Options({
  name: "UserEmailLog",
  components: { VPagination, EmailInfoModal },
})
export default class EmailLog extends Vue {
  @Prop({ required: true }) public userEmail!: string;
  list: any[] = [];
  pages = 0;
  isEmailInfoModalShow = false;
  selectedEmail: IEmailInfo | null = null;
  page = 1;
  take = 15;

  formatDate(value: string) {
    if (!value) return "";
    const date = new Date(value);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  openEmailInfoModal(item: IEmailInfo) {
    this.selectedEmail = item;
    this.isEmailInfoModalShow = true;
  }

  closeEmailInfoModal() {
    this.isEmailInfoModalShow = false;
    this.selectedEmail = null;
  }

  created(): void {
    this.fetchEmails(this.queryParams);
  }

  async fetchEmails(params: {
    page: number;
    limit: number;
    type: string;
    email: string;
  }) {
    try {
      swal.showLoading();
      const { data } = await listAdminMail(params);
      if (data) {
        this.list = data.results;
        this.pages = Math.ceil(data.count / this.take);
      }
    } catch (error) {
      console.error("Error fetching emails:", error);
    } finally {
      swal.close();
    }
  }

  updateHandler(): void {
    this.fetchEmails(this.queryParams);
  }

  get queryParams(): {
    page: number;
    limit: number;
    type: string;
    email: string;
  } {
    return {
      page: this.page,
      limit: this.take,
      type: "AdminMail",
      email: this.userEmail,
    };
  }
}
