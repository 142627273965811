import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from "vuex-module-decorators";

import swal from "sweetalert2";
import store from "@/store";
import { checkPlaylistToApprovingStatus, uploadNewBuild } from "@/api/builds";

export interface BuildModel {
  id: number;
  platform: string;
  title: string;
  version: string;
  url: string;
  mimetype: string;
  size: number;
  created: string;
  updated: string;
}
export interface IBuildState {
  builds: BuildModel[];
  page: number;
  pages: number;
  take: number;
}
@Module({ dynamic: true, store, name: "builds", namespaced: true })
class Builds extends VuexModule implements IBuildState {
  public builds: BuildModel[] = [];
  public page = 1;
  public pages = 0;
  public take = 15;

  @Mutation
  private SET_BUILDS(newBuilds: any) {
    this.builds = newBuilds;
  }

  @Mutation
  private SET_PAGE(page: number) {
    this.page = page;
  }

  @Mutation
  private SET_UPLOADED_BUILD(newBuild: BuildModel) {
    const found = this.builds.find(
      (x: BuildModel) => x.platform == newBuild.platform
    );
    if (found) {
      this.builds.splice(
        this.builds.findIndex(
          (x: BuildModel) => x.platform == newBuild.platform
        ),
        1,
        newBuild
      );
    }
  }

  @Mutation
  private SET_TAKE(take: number) {
    this.take = take;
  }
  @Action
  public async fetchBuilds() {
    try {
      swal.showLoading();
      const response = await checkPlaylistToApprovingStatus();
      if (response.data) {
        this.SET_BUILDS(response.data);
      }
      console.log(response);
    } catch (e) {
      console.log(e);
    } finally {
      swal.close();
    }
  }
  @Action
  public async uploadNewBuild(payload: FormData) {
    swal.showLoading();
    try {
      const response = await uploadNewBuild(payload);
      if (response.data) {
        this.SET_UPLOADED_BUILD(response.data);
        swal.close();
      }
    } catch (e) {
      console.log(e);
    }
  }
}
export const BuildsModule = getModule(Builds);
