import { createStore } from "vuex";
import { IPermissionState } from "./modules/permission";
import { IPlaylistState } from "./modules/playlist";
import { IUserState } from "./modules/user";
import { ITracksState } from "./modules/tracks";
import { IReportsState } from "./modules/reports";
import { IGroupsState } from "./modules/groups";

export interface IRootState {
  user: IUserState;
  premission: IPermissionState;
  playlist: IPlaylistState;
  tracks: ITracksState;
  reports: IReportsState;
  groups: IGroupsState;
}

export default createStore({});
