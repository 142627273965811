
import { Prop } from "vue-property-decorator";
import request from "@/utils/request";
import isNil from "lodash/isNil";
import { Options, Vue } from "vue-class-component";
import moment from "moment";
import ObjectList from "./object-list.vue";
import InfiniteScroll from "infinite-loading-vue3";
import swal from "sweetalert2";

const STRING_FIELDS = [
  "fullname",
  "firstName",
  "lastName",
  "email",
  "notes",
  "deleted",
  "reasonForDeletion",
  "addressLine1",
  "addressLine2",
  "city",
  "state",
  "country",
  "verified",
  "postalCode",
  "paypalEmail",
  "publisherEmail",
  "stripePaymentMethodId",
  "tracksDisplayingPreference",
  "playlistsDisplayingPreference",
  "tracksPrivacyPreference",
  "defaultPayoutMethod",
  "preferredCurrency",
  "software",
];

const ARRAY_STRING_FIELDS = ["genres", "deniedTopics"];

const ARRAY_OBJECTS_FIELDS = ["socials", "societies", "performers"];

enum PROPERTIES {
  socials = "socials",
  societies = "societies",
  performers = "performers",
}

const PROPERTIES_WITH_FIELDS = {
  [PROPERTIES.socials]: "link",
  [PROPERTIES.societies]: "name",
  [PROPERTIES.performers]: "name",
};

@Options({
  name: "ChangeLog",
  components: { ObjectList, InfiniteScroll },
})
export default class UserInfo extends Vue {
  @Prop({ required: true, default: () => [] }) public changeLog!: any;
  @Prop() readonly userId!: number;
  public trendingRepos: any[] = [];
  public resultScroll: any[] = [];
  public page: number = 1 || null;
  public isLoading = false;
  public hasMore = true;
  public message = "";
  data() {
    return {
      moment,
      resultScroll: [],
    };
  }

  isStringField(fieldName: string) {
    return STRING_FIELDS.includes(fieldName);
  }

  isNumberField(fieldName: number) {
    return !isNaN(fieldName);
  }

  isArrayStringField(fieldName: string) {
    return ARRAY_STRING_FIELDS.includes(fieldName);
  }

  isArrayObjectField(fieldName: string) {
    return ARRAY_OBJECTS_FIELDS.includes(fieldName);
  }

  getFieldNameByProperty(property: PROPERTIES) {
    return PROPERTIES_WITH_FIELDS[property];
  }

  parseJSONValue(json: string, isArray?: boolean) {
    const parsedValue = JSON.parse(json);
    if (isArray) {
      return parsedValue || [];
    }
    return !isNil(parsedValue) ? parsedValue.toString() : "";
  }
  async loadDataFromServer() {
    if (!this.hasMore || this.isLoading) {
      return;
    }
    this.isLoading = true;

    try {
      swal.showLoading();
      // const result: any = await Object.keys(this.changeLog).map((key) => ({ ...this.changeLog[key] })) ;
      const { data } = await request.get(
        `/api/users/${this.userId}/changelog?page=${this.page}&take=${8}`
      );
      if (data.results.length === 0) {
        this.hasMore = false;
        swal.close();
      } else {
        this.trendingRepos = [...this.trendingRepos, ...data.results];
        this.page += 1;
      }
    } catch (err) {
      console.error("Error fetching items", err);
    } finally {
      swal.close();
      this.isLoading = false;
    }
  }
  async mounted(): Promise<void> {
    await this.loadDataFromServer();
  }
}
