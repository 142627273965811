import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-559b3cbf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-info" }
const _hoisted_2 = { class: "user_data" }
const _hoisted_3 = { class: "user_data_header" }
const _hoisted_4 = { class: "user_data_body_content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileTab = _resolveComponent("ProfileTab")!
  const _component_OnlinePresenceTab = _resolveComponent("OnlinePresenceTab")!
  const _component_StripeTab = _resolveComponent("StripeTab")!
  const _component_ChangeLogTab = _resolveComponent("ChangeLogTab")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass([
            'user_profile',
            { active: _ctx.activeItem === _ctx.MENU_ITEMS.Profile },
          ]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleChooseMenuItem(_ctx.MENU_ITEMS.Profile)))
        }, " Profile ", 2),
        _createElementVNode("div", {
          class: _normalizeClass([
            'user_presence',
            { active: _ctx.activeItem === _ctx.MENU_ITEMS.OnlinePresence },
          ]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleChooseMenuItem(_ctx.MENU_ITEMS.OnlinePresence)))
        }, " Online Presence ", 2),
        _createElementVNode("div", {
          class: _normalizeClass(['user_stripe', { active: _ctx.activeItem === _ctx.MENU_ITEMS.Stripe }]),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleChooseMenuItem(_ctx.MENU_ITEMS.Stripe)))
        }, " Stripe/Persona ", 2),
        _createElementVNode("div", {
          class: _normalizeClass([
            'user_change_log',
            { active: _ctx.activeItem === _ctx.MENU_ITEMS.UsersChangelog },
          ]),
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleChooseMenuItem(_ctx.MENU_ITEMS.UsersChangelog)))
        }, " User's Changelog ", 2)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass([
          'user_data_body',
          { 'change-log': _ctx.activeItem === _ctx.MENU_ITEMS.UsersChangelog },
        ])
      }, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.activeItem === _ctx.MENU_ITEMS.Profile)
            ? (_openBlock(), _createBlock(_component_ProfileTab, {
                key: 0,
                user: _ctx.user
              }, null, 8, ["user"]))
            : _createCommentVNode("", true),
          (_ctx.activeItem === _ctx.MENU_ITEMS.OnlinePresence)
            ? (_openBlock(), _createBlock(_component_OnlinePresenceTab, {
                key: 1,
                user: _ctx.user
              }, null, 8, ["user"]))
            : _createCommentVNode("", true),
          (_ctx.activeItem === _ctx.MENU_ITEMS.Stripe)
            ? (_openBlock(), _createBlock(_component_StripeTab, {
                key: 2,
                stripeData: _ctx.stripeData
              }, null, 8, ["stripeData"]))
            : _createCommentVNode("", true),
          (_ctx.activeItem === _ctx.MENU_ITEMS.UsersChangelog)
            ? (_openBlock(), _createBlock(_component_ChangeLogTab, {
                key: 3,
                changelog: _ctx.changelog
              }, null, 8, ["changelog"]))
            : _createCommentVNode("", true)
        ])
      ], 2)
    ])
  ]))
}