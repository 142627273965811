
import datepicker from "vue3-datepicker";
import { Watch } from "vue-property-decorator";
import { Options, Vue } from "vue-class-component";
import { ReportsModule } from "@/store/modules/reports";
import { PeriodModule } from "@/store/modules/period.module";
import { LeftAdditionalSidebarModule } from "@/store/modules/left-additional-sidebar";
import { formattedDate } from "@/filters";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

@Options({
  name: "Payments",
  components: {
    VPagination,
    datepicker,
  },
})
export default class Tracks extends Vue {
  // DATA
  private take = 30;
  page = 1;

  // COMPUTED
  get fromDateAs() {
    return PeriodModule.fromDate;
  }

  get toDateAs() {
    return PeriodModule.toDate;
  }

  get format() {
    return PeriodModule.format;
  }

  get initiateLoading() {
    return LeftAdditionalSidebarModule.initiateLoading;
  }

  get list() {
    return ReportsModule.list;
  }

  get pages(): number {
    return ReportsModule.pages;
  }

  // HOOKS
  mounted() {
    this.fetchPageData();
  }

  fetchPageData(): void {
    const { page, take, fromDateAs, toDateAs } = this;
    const fromDate = moment(fromDateAs)
      .startOf("month")
      .format("YYYY-MM-DDTHH:mm:ss.SSS");
    const toDate = moment(toDateAs)
      .endOf("month")
      .format("YYYY-MM-DDTHH:mm:ss.SSS");
    const format = "YYYY-MM-DDTHH:mm:ss.SSS";
    const formattedFromDate = moment(fromDate).format(format);
    const formattedToDate = moment(toDate).format(format);
    ReportsModule.getPaymentsReport({
      page,
      take,
      fromDate: formattedFromDate,
      toDate: formattedToDate,
    });
  }

  beforeUnmount() {
    ReportsModule.clearList();
  }

  // METHODS
  handleDownloadReport() {
    const { fromDateAs, toDateAs, format } = this;
    const formated = "YYYY-MM-DDTHH:mm:ss.SSS";
    const fromDate = moment(fromDateAs).startOf("month").format(formated);
    const toDate = moment(toDateAs).endOf("month").format(formated);

    ReportsModule.downloadPaymentsReport({ fromDate, toDate, format });
  }

  formattedDate(value: string) {
    return formattedDate(value);
  }
  dateChange() {
    ReportsModule.clearList();
    const { page, take, fromDateAs, toDateAs } = this;
    const formated = "YYYY-MM-DDTHH:mm:ss.SSS";
    const fromDate = moment(fromDateAs).startOf("month").format(formated);
    const toDate = moment(toDateAs).endOf("month").format(formated);
    ReportsModule.getPaymentsReport({ page, take, fromDate, toDate });
  }

  updateHandler(): void {
    this.fetchPageData();
  }

  // WATCH
  @Watch("fromDateAs")
  onChangeLoadStateFrom(value: boolean) {
    if (value) {
      this.dateChange();
    }
  }
  @Watch("toDateAs")
  onChangeLoadStateTo(value: boolean) {
    if (value) {
      this.dateChange();
    }
  }
  @Watch("initiateLoading")
  onChangeLoadState(value: boolean) {
    if (value) {
      this.handleDownloadReport();
    }
  }
}
