export const formattedDate = (value: string) => {
  try {
    return new Intl.DateTimeFormat("fr-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(new Date(value));
  } catch (e) {
    console.log("Error in filters.ts");
  }
};
