
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import VueMultiselect from "vue-multiselect";

@Options({
  name: "ProfileTab",
  components: {
    VueMultiselect,
  },
})
export default class ProfileTab extends Vue {
  @Prop({ required: true }) private user!: any;

  data() {
    return {
      avatarUrl: process.env.VUE_APP_S3_URL,
    };
  }
}
