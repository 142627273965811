<template>
  <div class="newsletter">
    <div class="newsletter__header">
      <h1>Newsletter Templates</h1>
      <button class="button-default" @click="handleCreate">Create</button>
    </div>
    <div class="newsletter__content">
      <div class="newsletter__table">
        <div class="newsletter__table__header row">
          <div class="col-5 newsletter__table__header__column">Name</div>
          <div class="col-3 newsletter__table__header__column">Created</div>
          <div class="col-3 newsletter__table__header__column">Updated</div>
          <div class="col-1 newsletter__table__header__column"></div>
        </div>
        <div class="newsletter__table__body">
          <div
            v-for="item in list"
            :key="item.name"
            class="newsletter__table__body__row row"
            @click="handleOpen(item.id)"
          >
            <div class="col-5 newsletter__table__body__column">
              {{ item.name }}
            </div>
            <div class="col-3 newsletter__table__body__column">
              {{ item.created }}
            </div>
            <div class="col-3 newsletter__table__body__column">
              {{ item.updated }}
            </div>
            <div class="col-1 newsletter__table__body__column">
              <button class="btn btn-light" @click.stop="handleDelete(item.id)">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import {
  deleteNewsletterTemplate,
  listNewsletterTemplates,
} from "@/api/messages";
import swal from "sweetalert2";

@Options({
  name: "Newsletter",
  components: {},
})
export default class Newsletter extends Vue {
  list = [];

  async created() {
    swal.showLoading();
    try {
      const { data } = await listNewsletterTemplates();
      this.list = data;
    } catch (e) {
      this.list = [];
      await swal.fire({
        icon: "error",
        text: "Failed to fetch newsletter templates",
      });
    }
    swal.close();
  }

  handleCreate() {
    this.$router.push({ name: "NewsletterEditor", params: { id: "new" } });
  }

  async handleDelete(id) {
    swal.showLoading();
    try {
      await deleteNewsletterTemplate(id);
    } catch (e) {
      await swal.fire({
        icon: "error",
        text: `Failed to delete the newsletter template #${id}`,
      });
    } finally {
      swal.close();
    }

    this.list = this.list.filter((item) => item.id !== id);
  }

  // called when the editor is created
  handleOpen(id) {
    this.$router.push({ name: "NewsletterEditor", params: { id } });
  }
}
</script>

<style lang="scss" scoped>
.newsletter {
  min-height: 100%;
  &__header {
    height: 70px;
    display: flex;
    padding: 0px 23px;
    align-items: center;
    background: #36c9be;
    justify-content: space-between;
    h1 {
      font-size: 36px;
      font-weight: 500;
      color: #ffffff;
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #f2f1ef;
    height: calc(100% - 70px);
  }
  &__content {
    height: 100%;
    width: 100%;
  }
  &__table {
    overflow: hidden;
    padding-bottom: 68px;
    height: calc(100% - 70px);
    &__header {
      margin: 0;
      background: #16978e;

      &__column {
        font-size: 9px;
        color: #ffffff;
        line-height: 30px;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        &:first-child {
          padding-left: 30px;
        }
      }
    }
    &__body {
      height: calc(100% - 30px);
      overflow-y: scroll;
      padding: 0 9px;
      &__row {
        cursor: pointer;
        border-bottom: 1px solid #75707b;
        &:hover {
          background: #ebebeb;
        }
      }
      &__column {
        color: #000;
        font-size: 12px;
        line-height: 42px;

        &:first-child {
          padding-left: 30px;
        }
      }
    }
  }
}
</style>
