import { UserModel, UserPartData, UserSocial } from "@/store/modules/user";
import request from "@/utils/request";
import { AxiosResponse } from "axios";
import qs from "qs";
import { IDefaultQueryParams } from "./types";
import { REVIEW_STATUSES } from "@/utils/constants";

export const login = (data: any): Promise<AxiosResponse<any>> =>
  request.post("/api/auth/login/", data);

export const logout = (): Promise<AxiosResponse<any>> =>
  request.post("/api/auth/logout");

export const fetchUsers = (
  params: IDefaultQueryParams
): Promise<AxiosResponse<any>> => {
  const queryParam = params
    ? `?${qs.stringify(params, { skipNulls: true })}`
    : "";
  return request.get(`/api/users/${queryParam}`);
};

export const fetchUser = (id: number): Promise<AxiosResponse<any>> => {
  return request.get(`/api/users/${id}`);
};
export const fetchCurrentUser = (): Promise<AxiosResponse<any>> => {
  return request.get(`/api/users/me`);
};
export const getPaymentMethod = (
  userId: number
): Promise<AxiosResponse<any>> => {
  return request.get(`/api/users/${userId}/payment-payout-methods-info`);
};

export const approveAlias = (payload: any): Promise<AxiosResponse<any>> => {
  return request.post(`/api/performers/${payload.id}/change-status`, {
    status: payload.status,
  });
};

export const approveVenue = (payload: any): Promise<AxiosResponse<any>> => {
  return request.post(`/api/venues/${payload.id}/change-status`, {
    status: payload.status,
  });
};

export const addAlias = (payload: any): Promise<AxiosResponse<any>> => {
  return request.post(`/api/performers`, {
    name: payload.name,
    userId: payload.userId,
  });
};

export const verifyUser = (
  id: number,
  payload: { verified: boolean }
): Promise<AxiosResponse<any>> => {
  return request.post(`/api/users/${id}/change-verified`, payload);
};

export const updateUserData = (
  id: number,
  user: UserPartData
): Promise<AxiosResponse<UserModel>> => {
  return request.put(`/api/users/${id}`, user);
};

export const updateUserSocials = (
  id: number,
  userSocials: UserSocial[]
): Promise<AxiosResponse<UserModel>> => {
  return request.put(`/api/users/${id}`, { socials: userSocials });
};

export const fetchUserStripeData = (
  userId: number
): Promise<AxiosResponse<any>> => {
  return request.get(`/api/users/${userId}/verification-info`);
};

export const sendUserEmail = (payload: {
  to: string;
  subject: string;
  html: string;
}): Promise<AxiosResponse<any>> => {
  return request.post(`/api/mail`, payload);
};

export const getUserChangeLogRequest = (
  data: any
): Promise<AxiosResponse<any>> => {
  return request.get(
    `/api/users/${data.id}/changelog?page=${data.page}&take=${data.take}`
  );
};

export const grabDataFromDiscogsRequest = (
  id: number
): Promise<AxiosResponse<any>> => {
  return request.post(`/api/users/${id}/assign-discogs-data`);
};

export const updateUserReviewStatusRequest = (
  id: number,
  status: REVIEW_STATUSES
): Promise<AxiosResponse<any>> => {
  return request.post(`/api/users/${id}/review-status`, { status });
};

export const inviteVipUserRequest = (data: {
  email: string;
  role: string;
}): Promise<AxiosResponse<any>> => {
  return request.post(`/api/users/vip-register`, data);
};

export const updateUserRequest = (
  id: number,
  data: any
): Promise<AxiosResponse<UserModel>> => {
  return request.put(`/api/users/${id}`, data);
};

export const deleteUserRequest = (
  id: number,
  data: { reasonForDeletion?: string; completeRemoval?: boolean }
): Promise<AxiosResponse<UserModel>> => {
  return request.delete(`/api/users/${id}`, { data });
};

export const getAccessToken = (userId: number): Promise<AxiosResponse<any>> => {
  return request.get(`/api/users/${userId}/access-token`);
};
