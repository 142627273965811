
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Options({ name: "SendEmailModal" })
export default class PlaylistTable extends Vue {
  @Prop({ required: true, default: false }) private show!: boolean;
  @Prop({ required: false, default: "" }) private defaultEmail!: string;

  form = {
    email: "",
    subject: "",
    message: "",
  };

  handleSubmit() {
    if (!this.form.message || !this.form.email) {
      return;
    }
    this.form.message = this.form.message.replace(/\n/g, "<br>");
    this.$emit("onSubmit", this.form);
    this.clearForm();
  }

  closeEmailModal(evt: Event): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.$emit("onClose");
    this.clearForm();
  }

  clearForm() {
    this.form.email = "";
    this.form.subject = "";
    this.form.message = "";
  }

  @Watch("defaultEmail")
  handeDefaultEmailChange() {
    this.form.email = this.defaultEmail;
  }
}
