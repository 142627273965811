import { AxiosResponse } from "axios";
import request from "@/utils/request";
import { BuildModel } from "@/store/modules/builds";
import { config } from "vuex-module-decorators";

export const checkPlaylistToApprovingStatus = (): Promise<
  AxiosResponse<BuildModel[]>
> => {
  return request.get(`/api/desktop-app-builds`);
};

export const uploadNewBuild = (payload: any): Promise<AxiosResponse<any>> => {
  return request.post(`/api/desktop-app-builds`, payload);
};
