
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { UserModule } from "@/store/modules/user";
import swal from "sweetalert2";
import { downloadFileByUrl } from "@/utils/files";
import { getReportCookie, removeReportCookie } from "@/utils/cookies";

@Options({
  name: "App",
})
export default class extends Vue {
  async created() {
    if (UserModule.user) {
      this.$socket.client.connect();
    }

    this.$socket.$subscribe("report", (data: any) => {
      this.onSocketReport(data);
    });
  }

  get user() {
    return UserModule.user;
  }

  @Watch("user")
  handleUserChange(user: any) {
    if (user) {
      this.$socket.client.connect();
    } else {
      if (this.$socket.connected) {
        this.$socket.client.disconnect();
      }
    }
  }

  async onSocketReport(data: any) {
    if (!data?.uuid || !getReportCookie(data.uuid)) {
      return;
    }

    removeReportCookie(data.uuid);

    if (!data?.status) {
      await swal.fire({
        icon: "error",
        title: "Error generating report",
        text:
          data?.message ?? "Error generating report. Please try again later.",
      });
      return;
    }

    downloadFileByUrl(
      process.env.VUE_APP_S3_URL + data.report.url,
      data.report.title
    );
  }
}
