
import { PlaylistModule, TrackModel } from "@/store/modules/playlist";
import { fetchPerformers, createNewTrack } from "@/api/playlist";
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import VueMultiselect from "vue-multiselect";
import swal from "sweetalert2";
import debounce from "lodash/debounce";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

@Options({
  name: "FindTrackPopup",
  components: {
    VPagination,
    VueMultiselect,
  },
})
export default class extends Vue {
  @Prop({ default: false }) private isOpen!: boolean;
  @Prop({ require: true }) public trackList!: TrackModel[];
  @Prop({ require: true }) public pages!: number;
  @Prop({ require: true }) public track!: any;
  @Prop({ required: true }) private pageMultiselect!: number | null;
  @Prop() private initSearch?: string;

  search = "";
  NewTrack = false;
  warningTwoSpacesPopupOpen = false;
  warningTwoSpacesPopupOpenRemixer = false;
  typingTimer!: any;
  name: string | null = null;
  newPerformers: string[] = [];
  newRemixers: string[] = [];
  trackTitle: any = "";
  trackLink: any = "";
  performersNames: any = [];
  pagePerform = 1;
  pageCount = 0;
  takePerform = 20;
  namePerformer = "";
  page = 1;

  async createNewTrack(): Promise<void> {
    if (!this.NewTrack) {
      this.NewTrack = !this.NewTrack;
      this.newPerformers = [];
      this.newRemixers = [];
      this.trackTitle = "";
      this.trackLink = "";
      this.trackTitle = this.search;
      this.search = "";
    } else {
      this.search = this.trackTitle;
      this.NewTrack = !this.NewTrack;
    }
  }

  async createAddNewTrack() {
    if (this.newRemixers.length == 0) {
      try {
        const { data } = await createNewTrack({
          name: this.trackTitle,
          link: this.trackLink,
          performers: this.newPerformers,
        });
        this.createNewTrack();
        this.search = this.trackTitle.trim();
        return data;
      } catch (e: any) {
        swal.fire({
          icon: "error",
          title: e?.error,
          text: e?.message,
        });
        return;
      }
    } else {
      try {
        const { data } = await createNewTrack({
          name: this.trackTitle,
          link: this.trackLink,
          performers: this.newPerformers,
          remixers: this.newRemixers,
        });
        this.createNewTrack();
        this.search = this.trackTitle.trim();
        return data;
      } catch (e: any) {
        swal.fire({
          icon: "error",
          title: e?.error,
          text: e?.message,
        });
        return;
      }
    }
  }

  closePopup(): void {
    this.$emit("onClose");
    this.clear();
  }

  performerNames(track: TrackModel): string {
    return track?.performers.map((p) => p.name).join(", ") || "";
  }

  remixers(track: TrackModel): string {
    return track?.remixers.map((p) => p.name).join(", ") || "";
  }

  handleLinkTracks(track: TrackModel): void {
    this.$emit("onLinkTrack", track);
  }

  @Watch("trackTitle")
  titleСhanges(value: string): void {
    this.trackTitle = value;
  }

  @Watch("trackLink")
  linkСhanges(value: string): void {
    this.trackLink = value;
  }

  @Watch("search")
  onTrackChanged(value: string): void {
    this.page = 1;
    this.$emit("onSearchChanged", value, this.page);
  }

  @Watch("initSearch")
  onInitSearchChanged(value: string): void {
    if (value) {
      this.search = value;
    }
  }

  switchPage(): void {
    this.$emit("onSearchChanged", this.search, this.page);
  }

  private clear() {
    this.search = "";
  }

  // Option multiselect
  declare $refs: {
    multiselect: any;
    remixerselect: any;
    input: string;
    copyText: any;
  };
  warningTwoSpacesPopupClose() {
    this.warningTwoSpacesPopupOpen = false;
    this.$refs.multiselect.$el.focus();
  }
  warningRemixerTwoSpacesPopupClose() {
    this.warningTwoSpacesPopupOpenRemixer = false;
    this.$refs.remixerselect.$el.focus();
  }
  showMe(event: any) {
    const str = event.target.value;
    if (/\s{2}/.test(str)) {
      event.target.closest(".artist") !== null
        ? (this.warningTwoSpacesPopupOpen = true)
        : (this.warningTwoSpacesPopupOpenRemixer = true);
    }
  }
  handleAddElement(newTag: string) {
    this.newPerformers.push(newTag);
  }
  handleAddRemixer(newTag: any) {
    this.newRemixers.push(newTag);
  }

  handleSearch = debounce((name: string): void => {
    if (this.name !== name) {
      this.name = name || "";
      this.fetchPerformersNames(this.name);
    }
  }, 500);

  async fetchPerformersNames(name: string) {
    const performers = await fetchPerformers({
      name: name,
      type: "all",
      page: this.pagePerform,
      take: this.takePerform,
    });
    performers.data.results.map((el: any) => {
      this.performersNames.push(el.name);
    });
  }
}
