
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Switcher from "@/components/switcher/index.vue";

@Options({
  name: "PlaylistFieldEditModal",
})
export default class PlaylistTable extends Vue {
  @Prop({ required: true }) private title!: string;
  @Prop({ required: true, default: false }) private show!: boolean;
  @Prop({ required: false, default: false }) private isRequired!: boolean;
  @Prop({ required: true }) private playlist!: {
    value: string;
  };

  form = {
    value: this.playlist.value,
  };

  handleSubmit() {
    this.$emit("onSubmit", this.form);
  }

  closeModal(evt: Event): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.$emit("onClose");
  }
}
