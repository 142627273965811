import swal from "sweetalert2";
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from "vuex-module-decorators";
import store from "@/store";
import { UserModel } from "./user";
import {
  fetchGroupRequest,
  addMemberToGroupRequest,
  changeGroupStatusRequest,
  inviteMemberToGroupRequest,
  removeMemberFromGroupRequest,
} from "@/api/groups";

export interface GroupMember {
  asliceId: string;
  discogsId: any;
  id: number;
  isGroup: boolean;
  name: string;
  status: string;
  url: string;
  user: UserModel;
  userId: number;
}

export interface GroupMembership {
  email: string | null;
  groupId: number;
  id: number;
  isAdmin: boolean;
  invited: boolean;
  member: GroupMember;
  status: string;
  user: UserModel;
}

export interface GroupModel {
  asliceId: string;
  discogsId: any;
  groupDisputes: any;
  groupMemberships: Array<GroupMembership>;
  id: number;
  isGroup: boolean;
  name: string;
  status: string;
  url: string;
  user: any;
  userId: number | null;
}

export interface IGroupsState {
  group: GroupModel | null;
}

@Module({ dynamic: true, store, name: "groups", namespaced: true })
class Groups extends VuexModule implements IGroupsState {
  group: GroupModel | null = null;

  @Mutation
  private SET_GROUP(group: GroupModel | null) {
    this.group = group;
  }

  @Action
  public async removeMemberFromGroup(payload: {
    id: number;
    membershipId: number;
  }) {
    try {
      const { id, membershipId } = payload;
      const { data } = await removeMemberFromGroupRequest(id, { membershipId });
      return data;
    } catch (e: any) {
      console.error(e);
      return null;
    }
  }

  @Action
  public async fetchGroup(payload: number) {
    try {
      const { data } = await fetchGroupRequest(payload);
      if (data) {
        this.SET_GROUP(data);
      }
      return data;
    } catch (e: any) {
      console.error(e);
      return;
    }
  }

  @Action
  public async changeGroupStatus(payload: { id: number; status: string }) {
    try {
      const response = await changeGroupStatusRequest(
        payload.id,
        payload.status
      );
      return response;
    } catch (e: any) {
      console.error(e);
      return;
    }
  }

  @Action
  public async addMemberToGroup(payload: {
    id: number;
    data: {
      email: string;
      performerName: string;
    };
  }) {
    try {
      const response = await addMemberToGroupRequest(payload.id, payload.data);
      return response;
    } catch (e: any) {
      console.error(e);
      return;
    }
  }

  @Action
  public async inviteMemberToGroup(payload: {
    id: number;
    data: {
      email: string;
      performerId: number;
    };
  }) {
    try {
      const response = await inviteMemberToGroupRequest(
        payload.id,
        payload.data
      );
      return response;
    } catch (e: any) {
      console.error(e);
      return;
    }
  }
}

export const GroupsModule = getModule(Groups);
