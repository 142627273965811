import { UserModule } from "@/store/modules/user";
import axios, { AxiosError } from "axios";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60 * 4 * 1000,
});

// Request interceptors
service.interceptors.request.use(
  (config) => {
    // Add Authorization header to every request, you can add other custom headers here
    if (UserModule.token) {
      config.headers.Authorization = `Bearer ${UserModule.token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptors
service.interceptors.response.use(undefined, (error: AxiosError) => {
  if (
    error.response &&
    error.response.data &&
    typeof error.response.data === "object"
  ) {
    console.error(error.response.data);
    return Promise.reject(error.response.data);
  }
  return Promise.reject(error);
});

export default service;
